//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

import Spinner from 'react-activity/dist/Spinner';
import { JQuery } from 'types/jquery.types';
import { AuthStore, MessagesStore, CarePatientStore } from '../../stores';
import Message from '../../utils/Message';

class NucleusMessage extends React.Component<
  {
    patientID: string;
    enableBiometrics: boolean;
  },
  {
    accountID: any;
    patientID: any;
    patientName: string;
    enableVoiceMessage?: boolean;
    enableBiometrics: any;
    patientDevices: number;
    messageModalMode: string;
    messageSaveNewBtn: string;
    messageUpdateBtn: string;
    messageEditable: string;
    messageEditableRecurring: string;
    messageModalModeShow: string;
    messageModalOptionLabel: string;
    messageModeEdit: string;
    messageModeDelete: string;
    messageModalOptionColor: string;
    messageModalNotEditable: string;
    selectFileBtnStyle?: string;
    messageModalNotEditableLabel: string;
    messageModalNotDeleteView: string;
    updateMessageMode: string;
    deleteMessageBtnStyle: string;
    editMessageBtnStyle: string;
    newMessageSingleChoice: boolean;
    newMessageMultipleSelection: boolean;
    newMessageCategoryID: any;
    newMessageTemplate: any;
    messageCategories: any;
    messageCategoryDefault: any;
    messageTemplates: any;
    messageBiometricTypes: any;
    messageTemplateDefault: any;
    messageTemplateEditable: string;
    singleChoiceVisibility: string;
    multipleOptionVisibility: string;
    linkMessageVisibility: string;
    signMessageVisibility: string;
    messageLink: boolean;
    messageLinkUrl: string;
    messageLinkOpen: boolean;
    messageLinkButton: string;
    signMessage: boolean;
    signMessageFileUrl: any;
    signMessageButton: string;
    selectedPdfFile: any;
    selectedPdfFileName: string;
    selectedPdfFilePreviewURL: string;
    messageTemplateVisibility: string;
    responseAlertCustomVisibility: string;
    responseAlertFacesVisibility: string;
    responseAlertYesNoVisibility: string;
    responseAlertYesLabel: string;
    responseAlertNoLabel: string;
    messageOptionsError: string;
    messageOptionsMaxError: string;
    messageTemplateDetailsVisibiity: string;
    messageBodyChars: number;
    messageBodyError: string;
    messageBodyLengthError: string;
    messageLinkError: string;
    messageButtonError: string;
    messageSignError: string;
    messageSignButtonError: string;
    messageO1Chars: number;
    messageO2Chars: number;
    messageO3Chars: number;
    messageO4Chars: number;
    messageO5Chars: number;
    messageO6Chars: number;
    messageTemplateError?: string;
    messageTypeError: string;
    messageBodyErrorText: string;
    messageBodyLengthErrorChars: string;
    messageDateTime: any;
    messageEndDateTime: any;
    messageDeliveryDateTimeLabel: string;
    messageDeliveryImmediate: boolean;
    messageDeliveryDateError: string;
    messageEndDateError: string;
    messageBroadcast: boolean;
    messageDeliverTo: string;
    messageDeliveryAt: string;
    canSendAllPatients: string;
    messagePersistentState: boolean;
    messagePatientsList: any;
    messageAutocompleteDataList: any;
    messageAutocompletePatientsList: any;
    messageAutocompleteGroupsList: any;
    selectedMessageGroupsIDs: any;
    selectedMessagePatientsIDs: any;
    selectPatientsVisibility: string;
    messagePatientsChipsVisible: string;
    messageRepeat: boolean;
    messageRepeatStyle: string;
    messageRepeatEveryError: string;
    messageRepeatDaysError: string;
    messageEscalationStyle: string;
    stopRecurringMessageVisibility: string;
    stopRecurringMessageStyle: string;
    repeatConfigVisibility: string;
    repeatWeekVisibility: string;
    messageRepeatEnds: boolean;
    messageRepeatEndsDateSection: string;
    confirmMessageText: string;
    confirmMessageTextLine2: string;
    confirmMessageTextLine3: string;
    sortDescending: string;
    sortAscending: string;
    selectedPatientMessageID: any;
    selectedMessageID: any;
    regularMessageMode: string;
    biometricMessageMode: string;
    selectedBiometricTypeID: string;
    selectedBiometricTypeName: string;
    selectedBiometricTypeText: string;
    selectedBiometricTypeInputText: string;
    savingMessage: boolean;
    savingMessageText: string;
  }
> {
  private allOptions: { day: string; month: string; year: string; hour12: boolean; hour: string; minute: string };
  private dateOptions: { day: string; month: string; year: string };
  private timeOptions: { hour12?: boolean; hour: string; minute: string; hourCycle?: string };
  private datetimeFormat: string;
  private controlTimezone: number;
  private messageDateTimeRef: React.RefObject<any>;
  private messageEndDateTimeRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      accountID: AuthStore.getUserAccountID(),

      patientID: this.props.patientID,

      patientName: 'Patient',

      enableBiometrics: this.props.enableBiometrics,
      patientDevices: 0,

      // Messages
      messageModalMode: 'New',
      messageSaveNewBtn: ' ',
      messageUpdateBtn: ' hide',

      messageEditable: '',
      messageEditableRecurring: '',

      messageModalModeShow: ' hide',
      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',
      messageModalNotEditable: ' hide',
      messageModalNotEditableLabel: 'Message has already been sent and cannot be edited.',
      messageModalNotDeleteView: ' hide',

      updateMessageMode: 'message',

      deleteMessageBtnStyle: 'nucleus-submit-btn',
      editMessageBtnStyle: ' nucleus-submit-btn ',

      newMessageSingleChoice: false,
      newMessageMultipleSelection: false,
      newMessageCategoryID: null,
      newMessageTemplate: null,

      messageCategories: MessagesStore.getMessageCategories(),
      messageCategoryDefault: null,
      messageTemplates: [],
      messageBiometricTypes: [],
      messageTemplateDefault: null,

      messageTemplateEditable: '',

      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',
      linkMessageVisibility: ' hide',
      signMessageVisibility: ' hide',

      messageLink: false, // Not used
      messageLinkUrl: '', // Not used
      messageLinkOpen: false,
      messageLinkButton: '', // Not used

      signMessage: false,
      signMessageFileUrl: null,
      signMessageButton: '',

      selectedPdfFile: null,
      selectedPdfFileName: '',
      selectedPdfFilePreviewURL: '',

      messageTemplateVisibility: ' hide',

      responseAlertCustomVisibility: ' hide',
      responseAlertFacesVisibility: ' hide',
      responseAlertYesNoVisibility: ' hide',

      responseAlertYesLabel: 'Yes',
      responseAlertNoLabel: 'No',

      messageOptionsError: ' hide',
      messageOptionsMaxError: ' hide',

      messageTemplateDetailsVisibiity: ' hide',

      messageBodyChars: 0,
      messageBodyError: ' hide',
      messageBodyErrorText: '',
      messageBodyLengthError: ' hide',
      messageBodyLengthErrorChars: '60',

      messageLinkError: ' hide',
      messageButtonError: ' hide',

      messageSignError: ' hide',
      messageSignButtonError: ' hide',

      messageO1Chars: 0,
      messageO2Chars: 0,
      messageO3Chars: 0,
      messageO4Chars: 0,
      messageO5Chars: 0,
      messageO6Chars: 0,

      messageTypeError: ' hide',
      //messageCategoryError : ' hide',
      messageTemplateError: ' hide',

      messageDateTime: null,
      messageEndDateTime: null,

      messageDeliveryDateTimeLabel: 'Deliver On',
      messageDeliveryImmediate: true,

      messageDeliveryDateError: ' ',
      messageEndDateError: ' ',
      messageBroadcast: true,

      messageDeliverTo: ' ',
      messageDeliveryAt: ' ',

      canSendAllPatients: '',
      messagePersistentState: false,

      messagePatientsList: {
        //'T' : 'https://st2.depositphotos.com/3101765/8372/v/450/depositphotos_83729944-stock-illustration-border-collie-animal-dog-watercolor.jpg' : '13'
      },
      messageAutocompleteDataList: {},
      messageAutocompletePatientsList: {},
      messageAutocompleteGroupsList: {},

      selectedMessageGroupsIDs: null,
      selectedMessagePatientsIDs: null,
      selectPatientsVisibility: ' hide',
      messagePatientsChipsVisible: ' hide',

      messageRepeat: false,
      messageRepeatStyle: '',
      messageRepeatEveryError: ' hide',
      messageRepeatDaysError: ' hide',

      messageEscalationStyle: '',

      stopRecurringMessageVisibility: ' hide',
      stopRecurringMessageStyle: ' nucleus-submit-btn-small ',

      repeatConfigVisibility: ' hide',
      repeatWeekVisibility: ' hide',

      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',

      confirmMessageText: '',
      confirmMessageTextLine2: '',
      confirmMessageTextLine3: '',

      sortDescending: ' ',
      sortAscending: ' hide',

      selectedPatientMessageID: null,
      selectedMessageID: null,

      regularMessageMode: ' ',
      biometricMessageMode: ' hide',

      selectedBiometricTypeID: '',
      selectedBiometricTypeName: '',
      selectedBiometricTypeText: '',
      selectedBiometricTypeInputText: '',

      savingMessage: false,
      savingMessageText: '',
    };

    console.log('NucleusMessage.js PROPS ', this.props);

    this.allOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' };
    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.messageDateTimeRef = React.createRef();
    this.messageEndDateTimeRef = React.createRef();

    this.onPatientGetDataAction = this.onPatientGetDataAction.bind(this);

    /*

        this.buttonsInfoLoaded = -1;
        */
    this.onRefreshMessageCategoriesAction = this.onRefreshMessageCategoriesAction.bind(this);
    this.onGetMessageTemplatesAction = this.onGetMessageTemplatesAction.bind(this);
    this.onGetBiometricMessageTypesAction = this.onGetBiometricMessageTypesAction.bind(this);

    this.onGetAutocompletePatientsListAction = this.onGetAutocompletePatientsListAction.bind(this);

    this.handleNewMessageModal = this.handleNewMessageModal.bind(this);
    this.handleCloseNewMessageModal = this.handleCloseNewMessageModal.bind(this);
    this.handleMessageModalMode = this.handleMessageModalMode.bind(this);

    this.setMessageDefaultCategory = this.setMessageDefaultCategory.bind(this);
    //this.handleSelectMessageCategory = this.handleSelectMessageCategory.bind(this);
    this.handleMessageText = this.handleMessageText.bind(this);

    this.handleSelectMessageType = this.handleSelectMessageType.bind(this);
    this.handleSelectBiometricMessageType = this.handleSelectBiometricMessageType.bind(this);

    this.handleSelectSingleChoiceTemplate = this.handleSelectSingleChoiceTemplate.bind(this);

    this.handleResetOptionsValidationError = this.handleResetOptionsValidationError.bind(this);

    this.handleMessagePersistentState = this.handleMessagePersistentState.bind(this);

    this.handleMessageBroadcast = this.handleMessageBroadcast.bind(this);
    this.handleMessageLinkOpen = this.handleMessageLinkOpen.bind(this);
    this.handleMessageLinkText = this.handleMessageLinkText.bind(this);
    this.handleMessageButtonText = this.handleMessageButtonText.bind(this);

    this.handleSelectedFile = this.handleSelectedFile.bind(this);
    this.handleSelectedPdfFilePreview = this.handleSelectedPdfFilePreview.bind(this);
    this.handleSelectedPdfFileView = this.handleSelectedPdfFileView.bind(this);

    this.handleMessageSignText = this.handleMessageSignText.bind(this);
    this.handleMessageSignButtonText = this.handleMessageSignButtonText.bind(this);

    this.handleDeliveryImmediate = this.handleDeliveryImmediate.bind(this);
    this.handleDeliveryScheduled = this.handleDeliveryScheduled.bind(this);

    this.handleMessageRepeat = this.handleMessageRepeat.bind(this);
    this.handleStopRecurringMessageModal = this.handleStopRecurringMessageModal.bind(this);
    this.handleCancelStopRecurringMessageModal = this.handleCancelStopRecurringMessageModal.bind(this);
    this.handleStopRecurringMessage = this.handleStopRecurringMessage.bind(this);

    this.handleTxtRepeatEvery = this.handleTxtRepeatEvery.bind(this);
    this.handleSelectMessageRecurring = this.handleSelectMessageRecurring.bind(this);
    this.handleDayCheckChange = this.handleDayCheckChange.bind(this);
    this.handleMessageEnds = this.handleMessageEnds.bind(this);
    this.handleRepeatNeverEnds = this.handleRepeatNeverEnds.bind(this);
    this.handleRepeatEndDate = this.handleRepeatEndDate.bind(this);

    this.handleResumeSaveMessage = this.handleResumeSaveMessage.bind(this);
    this.handleCancelResumeMessageModal = this.handleCancelResumeMessageModal.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);

    this.onSaveNewMessageByUserAction = this.onSaveNewMessageByUserAction.bind(this);
    this.onSaveNewMessageFileAction = this.onSaveNewMessageFileAction.bind(this);

    this.onUpdateMessageByUserAction = this.onUpdateMessageByUserAction.bind(this);

    this.onGetMessageDetailsAction = this.onGetMessageDetailsAction.bind(this);
    this.onGetPatientMessageDetailsAction = this.onGetPatientMessageDetailsAction.bind(this);

    this.onOpenNotEditableMessageAction = this.onOpenNotEditableMessageAction.bind(this);
    this.handleCloseNotEditableModal = this.handleCloseNotEditableModal.bind(this);
    this.onHandleDeleteMessageAction = this.onHandleDeleteMessageAction.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);

    this.onHandleNotDeleteMessageAction = this.onHandleNotDeleteMessageAction.bind(this);
    this.handleCloseNotDeleteModal = this.handleCloseNotDeleteModal.bind(this);

    this.handleCancelResumeUpdateMessageModal = this.handleCancelResumeUpdateMessageModal.bind(this);

    this.handleResumeUpdateMessage = this.handleResumeUpdateMessage.bind(this);
    this.handleUpdateMessage = this.handleUpdateMessage.bind(this);
    this.onUpdateScheduledMessageAction = this.onUpdateScheduledMessageAction.bind(this);

    this.handleSubmitDeletePatientMessage = this.handleSubmitDeletePatientMessage.bind(this);
    this.onDeletePatientMessageAction = this.onDeletePatientMessageAction.bind(this);

    this.onStopRecurringMessageAction = this.onStopRecurringMessageAction.bind(this);

    this.onGetMessageDetailsForDeleteAction = this.onGetMessageDetailsForDeleteAction.bind(this);
    this.handleCancelDeleteMessage = this.handleCancelDeleteMessage.bind(this);
    this.handleSubmitDeleteMessage = this.handleSubmitDeleteMessage.bind(this);
    this.onDeleteMessageAction = this.onDeleteMessageAction.bind(this);

    this.validateUrlLink = this.validateUrlLink.bind(this);

    this.controlTimezone = 0;

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.datetimeFormat = 'MM/DD/YYYY hh:mm A';
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.datetimeFormat = 'MM/DD/YYYY HH:mm';
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }
  }

  componentDidMount() {
    CarePatientStore.on('onPatientGetData', this.onPatientGetDataAction);

    MessagesStore.on('onRefreshMessageCategories', this.onRefreshMessageCategoriesAction);
    MessagesStore.on('onGetMessageTemplates', this.onGetMessageTemplatesAction);
    MessagesStore.on('onGetBiometricMessageTypes', this.onGetBiometricMessageTypesAction);

    MessagesStore.on('onGetAutocompletePatientsList', this.onGetAutocompletePatientsListAction);

    MessagesStore.on('onSaveNewMessageByUser', this.onSaveNewMessageByUserAction);
    MessagesStore.on('onSaveNewMessageFile', this.onSaveNewMessageFileAction);

    MessagesStore.on('onUpdateMessageByUser', this.onUpdateMessageByUserAction);

    MessagesStore.on('onGetMessageDetails', this.onGetMessageDetailsAction);

    MessagesStore.on('onGetPatientMessageDetails', this.onGetPatientMessageDetailsAction);

    MessagesStore.on('onOpenNotEditableMessage', this.onOpenNotEditableMessageAction);
    MessagesStore.on('onHandleDeleteMessage', this.onHandleDeleteMessageAction);
    MessagesStore.on('onHandleNotDeleteMessage', this.onHandleNotDeleteMessageAction);

    MessagesStore.on('onUpdateScheduledMessage', this.onUpdateScheduledMessageAction);

    MessagesStore.on('onDeletePatientMessage', this.onDeletePatientMessageAction);

    MessagesStore.on('onStopRecurringMessage', this.onStopRecurringMessageAction);

    MessagesStore.on('onGetMessageDetailsForDelete', this.onGetMessageDetailsForDeleteAction);

    MessagesStore.on('onDeleteMessage', this.onDeleteMessageAction);

    this.setMessageDefaultCategory('Generic');

    MessagesStore.getMessageSingleChoiceTemplates();
    MessagesStore.getMessageBiometricsTemplates({
      AccountID: AuthStore.getUserAccountID(),
    });

    ($('.modalNucleusMessage') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });

    $(document).ready(() => {
      ($('.chips') as unknown as JQuery).material_chip();

      ($('.chips-initial') as unknown as JQuery).material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.messageAutocompleteDataList,
          limit: Infinity,
        },
        selectable: false,
      });

      $('.chips').click(() => {
        this.setState({
          messagePatientsChipsVisible: ' hide',
        });
      });

      // Event for when a Patient Chip is Selected
      $('.chips').on('chip.add', () => {
        //console.log("Selected >>"+chip.tag+"<");
        //console.log("Patients available: ", this.state.messagePatientsList);
        const existingPatients = [];
        const existingData = ($('.chips-initial') as unknown as JQuery).material_chip('data');
        let patientExist = false;

        for (let i = 0; i < existingData.length; i++) {
          patientExist = this.state.messagePatientsList[existingData[i].tag];
          //console.log("Exists ", patientExist, existingData[i]);
          if (patientExist) {
            existingPatients.push(existingData[i]);
          }
        }

        ($('.chips-initial') as unknown as JQuery).material_chip({
          data: existingPatients,
          placeholder: 'Type a name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: this.state.messageAutocompleteDataList,
            limit: Infinity,
          },
        });
      });

      /*
            $('.chips').on('chip.delete', (e, chip) =>{ 
            });
            */
      $('.chips').on('chip.select', (e, chip) => {
        console.log('Aqui seleccionando', e, chip);
        return false;
      });
    });

    const currentDate = new Date();
    console.log('DATE:', currentDate);
    const currentTimezone = currentDate.getTimezoneOffset() / 60;
    console.log('TIMEZONE:', currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    this.controlTimezone = currentTimezone;
    console.log('CONTROL TIMEZONE:', this.controlTimezone);
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onPatientGetData', this.onPatientGetDataAction);

    MessagesStore.removeListener('onRefreshMessageCategories', this.onRefreshMessageCategoriesAction);

    MessagesStore.removeListener('onGetMessageTemplates', this.onGetMessageTemplatesAction);
    MessagesStore.removeListener('onGetBiometricMessageTypes', this.onGetBiometricMessageTypesAction);

    MessagesStore.removeListener('onGetAutocompletePatientsList', this.onGetAutocompletePatientsListAction);

    MessagesStore.removeListener('onSaveNewMessageByUser', this.onSaveNewMessageByUserAction);
    MessagesStore.removeListener('onSaveNewMessageFile', this.onSaveNewMessageFileAction);
    MessagesStore.removeListener('onUpdateMessageByUser', this.onUpdateMessageByUserAction);

    MessagesStore.removeListener('onGetMessageDetails', this.onGetMessageDetailsAction);
    MessagesStore.removeListener('onGetPatientMessageDetails', this.onGetPatientMessageDetailsAction);
    MessagesStore.removeListener('onOpenNotEditableMessage', this.onOpenNotEditableMessageAction);
    MessagesStore.removeListener('onHandleDeleteMessage', this.onHandleDeleteMessageAction);
    MessagesStore.removeListener('onHandleNotDeleteMessage', this.onHandleNotDeleteMessageAction);

    MessagesStore.removeListener('onUpdateScheduledMessage', this.onUpdateScheduledMessageAction);
    MessagesStore.removeListener('onDeletePatientMessage', this.onDeletePatientMessageAction);

    MessagesStore.removeListener('onStopRecurringMessage', this.onStopRecurringMessageAction);

    MessagesStore.removeListener('onGetMessageDetailsForDelete', this.onGetMessageDetailsForDeleteAction);

    MessagesStore.removeListener('onDeleteMessage', this.onDeleteMessageAction);
  }

  handleRowClicked() {
    // GeneralStore.setClientTab(1);
    // GeneralStore.setPageBeforePatient("patients");
  }

  setMessageDefaultCategory(categoryTag) {
    if (!(this.state.messageCategories.length > 0)) {
      MessagesStore.refreshMessageCategries();
    } else {
      for (let i = 0; i < this.state.messageCategories.length; i++) {
        if (this.state.messageCategories[i].Category == categoryTag) {
          this.setState({
            messageCategoryDefault: this.state.messageCategories[i].ID,
            newMessageCategoryID: this.state.messageCategories[i].ID,
          });
          console.log('DEFAULT CATEGORY', this.state.messageCategories[i].Category, this.state.messageCategories[i].ID);
          break;
        }
      }
    }
  }

  onPatientGetDataAction(response) {
    console.log('nucleusMessage:onPatientGetDataAction OMG!');

    this.setState({
      enableVoiceMessage: response.EnableVoiceMessage,
      patientName: response.FirstName + ' ' + response.LastName,
      patientDevices: response.Devices.length,
      enableBiometrics: response.EnableBiometrics,
    });
  }

  onMessageDateTimeChange = (date, value) => {
    console.log('onMessageDateTimeChange', date, value);
    this.setState({
      messageDateTime: value,
      messageDeliveryDateError: ' hide',
    });
  };

  onMessageEndDateTimeChange = (date, value) => {
    console.log('onMessageEndDateTimeChange', date, value);
    this.setState({
      messageEndDateTime: value,
      messageEndDateError: ' hide',
    });
  };

  onRefreshMessageCategoriesAction(response) {
    console.log('onRefreshMessageCategoriesAction', response);
    if (response.ok) {
      this.setState({
        messageCategories: response.Categories,
      });
      if (this.state.messageCategoryDefault == null) {
        for (let i = 0; i < response.Categories.length; i++) {
          if (response.Categories[i].Category == 'Generic') {
            this.setState({
              messageCategoryDefault: response.Categories[i].ID,
            });
            console.log('DEFAULT CATEGORY 2', response.Categories[i].Category, response.Categories[i].ID);
            break;
          }
        }
      }
    }
  }

  onGetMessageTemplatesAction(response) {
    console.log('onGetMessageTemplatesAction', response);

    if (response.ok) {
      this.setState({
        messageTemplates: response.Templates,
      });

      if (this.state.messageTemplateDefault == null) {
        for (let i = 0; i < response.Templates.length; i++) {
          if (response.Templates[i].Name == 'Custom Text') {
            this.setState({
              messageTemplateDefault: response.Templates[i].ID,
            });
            console.log('DEFAULT SINGLE CHOICE TEMPLATE', response.Templates[i].Name, response.Templates[i].ID);
            break;
          }
        }
      }
    }
  }

  onGetBiometricMessageTypesAction(response) {
    console.log('onGetBiometricMessageTypesAction', response);

    if (response.ok) {
      //@ts-ignore
      this.state.messageBiometricTypes = [];

      if (response.Types && response.Types.length > 0) {
        this.setState({
          messageBiometricTypes: response.Types,
          selectedBiometricTypeID: response.Types[0].ID,
          selectedBiometricTypeName: response.Types[0].Type,
          selectedBiometricTypeText: response.Types[0].MessageText,
          selectedBiometricTypeInputText: response.Types[0].InputTextFields,
        });
      }
      console.log('SELECTED BIOMETRIC TYPE', response.Types[0].ID, response.Types[0].Type);
    }
  }

  onGetAutocompletePatientsListAction(response) {
    console.log('onGetAutocompletePatientsListAction 2: ', response);

    if (response.ok && response.autocompleteData) {
      const messageAutocompleteData = {};
      Object.keys(response.autocompleteData).forEach(function (key) {
        messageAutocompleteData[key] = null;
      });

      console.log('onGetAutocompletePatientsListAction 2: messageAutocompleteData', messageAutocompleteData);
      this.setState({
        messageAutocompleteDataList: messageAutocompleteData,
        messagePatientsList: response.autocompleteData,
        messageAutocompletePatientsList: response.autocompletePatients,
        messageAutocompleteGroupsList: response.autocompleteGroups,
      });

      ($('.chips-initial') as unknown as JQuery).material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: messageAutocompleteData,
          limit: Infinity,
        },
      });
    }
  }

  handleNewMessageModal() {
    console.log('handleNewMessageModal Uh');
    this.setMessageDefaultCategory('Generic');
    ($('#modalNewMessage') as unknown as JQuery).modal('open');

    MessagesStore.getMessageBiometricsTemplates({
      AccountID: AuthStore.getUserAccountID(),
    });
    MessagesStore.getAutocompletePatientsGroupsList({
      UserID: AuthStore.getUserID(),
    });
    console.log('Message categories are: ', this.state.messageCategories);

    if (!(this.state.messageCategories.length > 0)) {
      MessagesStore.refreshMessageCategries();
    }

    // this.refs.selectMessageCategory is not used anymore
    // this.refs.selectMessageCategory.value = "";
    // if (this.state.messageCategoryDefault != null){
    //     this.refs.selectMessageCategory.value = this.state.messageCategoryDefault;
    // }

    //@ts-ignore
    this.refs.selectMessageType.value = '1';
    //@ts-ignore
    this.refs.selectMessageTemplate.value = '';

    if (this.state.messageTemplateDefault != null) {
      //@ts-ignore
      this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
    }

    //@ts-ignore
    this.refs.checkMessageLinkOpen.checked = false;
    //@ts-ignore
    this.refs.checkMessageAllPatients.checked = true;

    //@ts-ignore
    this.refs.checkRepeat.checked = false;

    //@ts-ignore
    this.refs.txtRepeatNumber.value = '1';
    //@ts-ignore
    this.refs.selectMessageRecurring.value = 'Day';

    const futureTime = new Date().getTime() + 5 * 60000;
    const currentTime = new Date(futureTime);

    this.setState({
      messageModalMode: 'New',
      messageSaveNewBtn: ' ',
      messageUpdateBtn: ' hide',

      messageEditable: '',
      messageEditableRecurring: '',

      messageModalModeShow: ' hide',
      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',
      messageModalNotEditable: ' hide',

      selectFileBtnStyle: ' nucleus-submit-btn-mini',
      deleteMessageBtnStyle: 'nucleus-submit-btn',
      editMessageBtnStyle: ' nucleus-submit-btn ',

      newMessageSingleChoice: false,
      newMessageMultipleSelection: false,
      //newMessageCategoryID : this.state.messageCategoryDefault || "",
      //@ts-ignore
      newMessageTemplate: this.refs.selectMessageTemplate.value,

      messageTemplateEditable: '',

      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',
      linkMessageVisibility: ' hide',
      signMessageVisibility: ' hide',

      messageLink: false,
      messageLinkUrl: '',
      messageLinkOpen: false,
      messageLinkButton: '',

      signMessage: false,
      signMessageFileUrl: null,
      signMessageButton: '',

      selectedPdfFile: null,
      selectedPdfFileName: '',
      selectedPdfFilePreviewURL: '',

      messageTemplateVisibility: 'hide',

      responseAlertCustomVisibility: ' hide',
      responseAlertFacesVisibility: ' hide',
      responseAlertYesNoVisibility: ' hide',

      messageTemplateDetailsVisibiity: ' hide',

      messageOptionsError: ' hide',
      messageOptionsMaxError: ' hide',

      messageBodyChars: 0,
      messageBodyError: ' hide',
      messageBodyLengthError: ' hide',

      messageLinkError: ' hide',
      messageButtonError: ' hide',

      messageSignError: ' hide',
      messageSignButtonError: ' hide',

      messageO1Chars: 0,
      messageO2Chars: 0,
      messageO3Chars: 0,
      messageO4Chars: 0,
      messageO5Chars: 0,
      messageO6Chars: 0,

      messageTypeError: ' hide',
      //messageCategoryError : ' hide',
      messageTemplateError: ' hide',

      messageDeliveryDateTimeLabel: 'Deliver On',
      messageDeliveryImmediate: true,

      messageBroadcast: true,

      messageDeliverTo: ' All ' + AuthStore.getPatientLabelPlural(),
      messageDeliveryAt: ' ',

      canSendAllPatients: '',
      messagePersistentState: false,

      messageDateTime: currentTime,
      messageEndDateTime: currentTime,

      messageDeliveryDateError: ' hide',
      messageEndDateError: ' hide',

      selectedMessageGroupsIDs: null,
      selectedMessagePatientsIDs: null,
      selectPatientsVisibility: ' hide',

      messageRepeat: false,
      messageRepeatStyle: 'disabled',
      messageRepeatEveryError: ' hide',
      messageRepeatDaysError: ' hide',

      messageEscalationStyle: '',

      repeatConfigVisibility: ' hide',
      repeatWeekVisibility: ' hide',

      stopRecurringMessageVisibility: ' hide',
      stopRecurringMessageStyle: ' nucleus-submit-btn-small ',

      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',

      regularMessageMode: ' ',
      biometricMessageMode: ' hide',

      updateMessageMode: 'message',
      savingMessage: false,
      savingMessageText: '',
    });

    ($('.chips-initial') as unknown as JQuery).material_chip({
      data: [],
      placeholder: 'Type a name',
      secondaryPlaceholder: 'Type another name',
      autocompleteOptions: {
        data: this.state.messageAutocompleteDataList,
        limit: Infinity,
      },
    });

    //@ts-ignore
    this.refs.checkSunday.checked = false;
    //@ts-ignore
    this.refs.checkMonday.checked = false;
    //@ts-ignore
    this.refs.checkTuesday.checked = false;
    //@ts-ignore
    this.refs.checkWednesday.checked = false;
    //@ts-ignore
    this.refs.checkThursday.checked = false;
    //@ts-ignore
    this.refs.checkFriday.checked = false;
    //@ts-ignore
    this.refs.checkSaturday.checked = false;

    //this.refs.checkEnds.checked = false;

    //@ts-ignore
    this.refs.txtSingleOption1.value = '';
    //@ts-ignore
    this.refs.txtSingleOption2.value = '';
    //@ts-ignore
    this.refs.txtSingleOption3.value = '';
    //@ts-ignore
    this.refs.txtSingleOption4.value = '';
    //@ts-ignore
    this.refs.txtSingleOption5.value = '';
    //@ts-ignore
    this.refs.txtSingleOption6.value = '';

    //@ts-ignore
    this.refs.txtMultiOption1.value = '';
    //@ts-ignore
    this.refs.txtMultiOption2.value = '';
    //@ts-ignore
    this.refs.txtMultiOption3.value = '';
    //@ts-ignore
    this.refs.txtMultiOption4.value = '';
    //@ts-ignore
    this.refs.txtMultiOption5.value = '';
    //@ts-ignore
    this.refs.txtMultiOption6.value = '';

    //@ts-ignore
    this.refs.textMessage.value = '';
    //@ts-ignore
    this.refs.txtNoResponseTimeout.value = 0;

    //@ts-ignore
    this.refs.txtExpirationTimeout.value = 1;
    //@ts-ignore
    this.refs.checkPersistentState.checked = false;

    // console.log("1>> ", currentTime.toLocaleDateString('en-US', this.allOptions));
    // console.log("2>> ", currentTime.toLocaleDateString('en-US', this.dateOptions));
    // console.log("3>> ", currentTime.toLocaleTimeString('en-US', this.timeOptions));

    //this.state.messageDateTime  = currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
    //this.refs.messageEndDateTime.value  = currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
  }

  handleCloseNewMessageModal() {
    ($('#modalNewMessage') as unknown as JQuery).modal('close');
    this.setState({
      messagePersistentState: false,
    });
  }

  handleMessageModalMode() {
    console.log('handleMessageModalMode');

    if (this.state.messageModalOptionLabel == 'Delete') {
      this.setState({
        messageModalOptionLabel: 'Cancel',
        messageModeEdit: 'hide',
        messageModeDelete: '',
        messageModalOptionColor: '',
      });
    } else {
      this.setState({
        messageModalOptionLabel: 'Delete',
        messageModeEdit: '',
        messageModeDelete: 'hide',
        messageModalOptionColor: 'txt-red',
      });
    }
  }

  handleMessageText() {
    this.setState({
      messageBodyError: ' hide',
      messageBodyLengthError: ' hide',
      messageBodyChars: this.refs.textMessage.value.length,
    });
  }

  handleSelectMessageType(e) {
    // Select the Message Type
    /* 
            Values:Type
            "1" : Plain Text
            "2" : Single Choice
            "3" : Multiple Selection 
            "4" : Browser Link (AuthStore.isLinkMessageEnabled())
            "5" : Biometrics (this.state.enableBiometrics)
            "6" : Signature
        */
    let regularMessageMode = ' ';
    let biometricMessageMode = ' hide';

    if (e.target.value == 5) {
      // Message type is "Biometrics"
      // Need to update the messageCategory
      console.warn("It's category Biometric");
      let biometricMessageText = this.state.selectedBiometricTypeText;
      biometricMessageText = biometricMessageText.replace('(mg/dL)', '');
      biometricMessageText = biometricMessageText.replace('(Lbs)', '');
      //@ts-ignore
      this.refs.textMessage.value = biometricMessageText;

      //@ts-ignore
      this.refs.selectBiometricMessageType.value = this.state.selectedBiometricTypeID;

      regularMessageMode = ' hide';
      biometricMessageMode = ' ';

      this.setMessageDefaultCategory('Biometrics');
    } else {
      console.warn("It's category No Biometric");
      //this.refs.textMessage.value = "";
      regularMessageMode = ' ';
      biometricMessageMode = ' hide';

      this.setMessageDefaultCategory('Generic');
    }

    this.setState({
      //messageCategoryError : ' hide',
      regularMessageMode: regularMessageMode,
      biometricMessageMode: biometricMessageMode,
      messageBodyChars: this.refs.textMessage.value.length,

      messageOptionsError: ' hide',
      messageOptionsMaxError: ' hide',

      //messageRepeatStyle : '',
      messageTypeError: ' hide',

      canSendAllPatients: '',
      //messageBroadcast : true,

      messagePersistentState: false,
    });

    console.log('handleSelectMessageType');
    console.log('messagesCategories', this.state.messageCategories);
    console.log('=======>');

    //@ts-ignore
    this.refs.checkPersistentState.checked = false;

    //@ts-ignore
    if (this.refs.selectMessageType.value == '1' || this.refs.selectMessageType.value == '5') {
      // PLAIN TEXT | Biometrics

      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' hide',

        messageTemplateVisibility: ' hide',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        newMessageTemplate: null,
        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',
      });
      //@ts-ignore
    } else if (this.refs.selectMessageType.value == '2') {
      // SINGLE CHOICE

      this.setState({
        newMessageSingleChoice: true,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' ',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' hide',

        messageTemplateVisibility: ' ',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' ',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        //selectedMessagePatientsIDs : null,
        //selectPatientsVisibility : ' hide',

        messageTemplateEditable: '',
        messageBodyLengthError: ' hide',

        messageO1Chars: 0,
        messageO2Chars: 0,
        messageO3Chars: 0,
        messageO4Chars: 0,
        messageO5Chars: 0,
        messageO6Chars: 0,
      });

      //@ts-ignore
      this.refs.selectMessageTemplate.value = '';

      if (this.state.messageTemplateDefault != null) {
        //@ts-ignore
        this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
      }

      //@ts-ignore
      this.refs.txtSingleOption1.value = '';
      //@ts-ignore
      this.refs.txtSingleOption2.value = '';
      //@ts-ignore
      this.refs.txtSingleOption3.value = '';
      //@ts-ignore
      this.refs.txtSingleOption4.value = '';
      //@ts-ignore
      this.refs.txtSingleOption5.value = '';
      //@ts-ignore
      this.refs.txtSingleOption6.value = '';

      //this.refs.checkMessageAllPatients.checked = true;
      //@ts-ignore
    } else if (this.refs.selectMessageType.value == '3') {
      // MULTIPLE CHOICE

      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: true,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' ',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' hide',

        messageTemplateVisibility: ' ',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' ',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        //selectedMessagePatientsIDs : null,
        //selectPatientsVisibility : ' hide',

        newMessageTemplate: null,

        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',

        messageO1Chars: 0,
        messageO2Chars: 0,
        messageO3Chars: 0,
        messageO4Chars: 0,
        messageO5Chars: 0,
        messageO6Chars: 0,
      });

      //@ts-ignore
      this.refs.selectMessageTemplate.value = '';

      if (this.state.messageTemplateDefault != null) {
        //@ts-ignore
        this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
      }

      //@ts-ignore
      this.refs.txtMultiOption1.value = '';
      //@ts-ignore
      this.refs.txtMultiOption2.value = '';
      //@ts-ignore
      this.refs.txtMultiOption3.value = '';
      //@ts-ignore
      this.refs.txtMultiOption4.value = '';
      //@ts-ignore
      this.refs.txtMultiOption5.value = '';
      //@ts-ignore
      this.refs.txtMultiOption6.value = '';

      //this.refs.checkMessageAllPatients.checked = true;
    } else if (this.refs.selectMessageType.value == '4') {
      // BROWSER LINK

      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' ',
        signMessageVisibility: ' hide',

        messageLink: true,
        messageLinkUrl: '',
        messageLinkOpen: false,
        messageLinkButton: '',

        messageTemplateVisibility: ' hide',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        //messageRepeatStyle : '',
        messageTypeError: ' hide',
        messageLinkError: ' hide',
        messageButtonError: ' hide',

        newMessageTemplate: null,
        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',
      });

      //@ts-ignore
      this.refs.checkMessageLinkOpen.checked = false;
      //@ts-ignore
      this.refs.txtLinkMessageUrl.value = '';
      try {
        //@ts-ignore
        this.refs.txtLinkMessageButton.value = '';
      } catch (error) {
        console.warn('Handled error setting this.refs.txtLinkMessageButton.value');
      }
      //@ts-ignore
    } else if (this.refs.selectMessageType.value == '6') {
      // SIGNATURE MESSAGE
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' ',

        messageTemplateVisibility: ' hide',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        newMessageTemplate: null,
        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',

        messageRepeat: false,
        messageRepeatStyle: 'disabled',
        repeatConfigVisibility: ' hide',

        signMessage: false,
        //signMessageFileUrl :null,
        signMessageButton: '',

        messageSignError: ' hide',
        messageSignButtonError: ' hide',
      });
      this.refs.checkRepeat.checked = false;
      this.refs.txtSignFileUrl.value = '';
      try {
        this.refs.txtSignMessageButton.value = '';
      } catch (error) {
        console.warn('Handled error setting this.refs.txtSignMessageButton.value');
      }
    }

    this.refs.checkOption1of2.checked = false;
    this.refs.checkOption2of2.checked = false;

    this.refs.checkOption1of5.checked = false;
    this.refs.checkOption2of5.checked = false;
    this.refs.checkOption3of5.checked = false;
    this.refs.checkOption4of5.checked = false;
    this.refs.checkOption5of5.checked = false;

    this.refs.checkOption1of6.checked = false;
    this.refs.checkOption2of6.checked = false;
    this.refs.checkOption3of6.checked = false;
    this.refs.checkOption4of6.checked = false;
    this.refs.checkOption5of6.checked = false;
    this.refs.checkOption6of6.checked = false;
  }

  handleSelectBiometricMessageType() {
    console.log('handleSelectBiometricMessageType');
    console.log(this.refs.selectBiometricMessageType.value);

    for (let i = 0; i < this.state.messageBiometricTypes.length; i++) {
      if (this.state.messageBiometricTypes[i].ID == this.refs.selectBiometricMessageType.value) {
        let biometricMessageText = this.state.messageBiometricTypes[i].MessageText;

        this.setState({
          selectedBiometricTypeID: this.state.messageBiometricTypes[i].ID,
          selectedBiometricTypeName: this.state.messageBiometricTypes[i].Type,
          selectedBiometricTypeText: this.state.messageBiometricTypes[i].MessageText,
          selectedBiometricTypeInputText: this.state.messageBiometricTypes[i].InputTextFields,

          messageBodyChars: biometricMessageText.length,
        });

        biometricMessageText = biometricMessageText.replace('(mg/dL)', '');
        biometricMessageText = biometricMessageText.replace('(Lbs)', '');
        this.refs.textMessage.value = biometricMessageText;
        console.log('SELECTED BIOMETRIC TYPE', this.state.messageBiometricTypes[i].Type);
        break;
      }
    }
  }

  handleSelectSingleChoiceTemplate() {
    console.log('handleSelectSingleChoiceTemplate SELECCIONADO>', this.refs.selectMessageTemplate.selectedOptions[0].text);

    this.setState({
      newMessageTemplate: this.refs.selectMessageTemplate.value,
      messageTemplateError: ' hide',
    });

    if (this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault) {
      this.setState({
        singleChoiceVisibility: ' ',
        messageTemplateDetailsVisibiity: ' hide',
      });
    } else {
      this.setState({
        singleChoiceVisibility: ' hide',
        messageTemplateDetailsVisibiity: '',
      });
      this.refs.txtSingleOption1.value = '';
      this.refs.txtSingleOption2.value = '';
      this.refs.txtSingleOption3.value = '';
      this.refs.txtSingleOption4.value = '';
      this.refs.txtSingleOption5.value = '';
      this.refs.txtSingleOption6.value = '';
    }

    const singleChoiceTemplate = this.refs.selectMessageTemplate.selectedOptions[0].text;

    if (singleChoiceTemplate == 'Yes / No - Text Buttons' || singleChoiceTemplate == 'Yes / No - Icon Buttons') {
      this.setState({
        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' ',

        responseAlertYesLabel: 'Yes',
        responseAlertNoLabel: 'No',
      });

      this.refs.checkOption1of2.checked = false;
      this.refs.checkOption2of2.checked = false;
    } else if (singleChoiceTemplate == 'Thumbs Up / Down') {
      this.setState({
        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' ',

        responseAlertYesLabel: 'Thumbs Up',
        responseAlertNoLabel: 'Thumbs Down',
      });

      this.refs.checkOption1of2.checked = false;
      this.refs.checkOption2of2.checked = false;
    } else if (singleChoiceTemplate == 'Feeling Emojis') {
      this.setState({
        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' ',
        responseAlertYesNoVisibility: ' hide',
      });
      this.refs.checkOption1of5.checked = false;
      this.refs.checkOption2of5.checked = false;
      this.refs.checkOption3of5.checked = false;
      this.refs.checkOption4of5.checked = false;
      this.refs.checkOption5of5.checked = false;
    } else {
      this.setState({
        responseAlertCustomVisibility: ' ',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',
      });
      this.refs.checkOption1of6.checked = false;
      this.refs.checkOption2of6.checked = false;
      this.refs.checkOption3of6.checked = false;
      this.refs.checkOption4of6.checked = false;
      this.refs.checkOption5of6.checked = false;
      this.refs.checkOption6of6.checked = false;
    }
  }

  handleMessageLinkText() {
    this.setState({
      messageLinkError: ' hide',
    });
  }

  handleMessageButtonText() {
    this.setState({
      messageButtonError: ' hide',
    });
  }

  handleMessageSignText() {
    this.setState({
      messageSignError: ' hide',
    });
  }
  handleMessageSignButtonText() {
    this.setState({
      messageSignButtonError: ' hide',
    });
  }

  handleMessageLinkOpen(e) {
    const messageLinkAutoOpen = e.target.checked;
    console.log('MessageLink open', messageLinkAutoOpen);

    this.setState({
      messageLinkOpen: messageLinkAutoOpen,
      messageButtonError: ' hide',
    });
  }

  handleMessagePersistentState(e) {
    const messagePersistent = e.target.checked;
    console.log('handleMessagePersistentState', messagePersistent);
    this.setState({
      messagePersistentState: messagePersistent,
    });
    if (this.state.messageEditable == 'disabled') {
      this.setState({
        editMessageBtnStyle: !messagePersistent ? ' nucleus-submit-btn ' : ' nucleus-submit-btn-disabled ',
      });
    }
  }

  handleResetOptionsValidationError() {
    if (this.state.messageOptionsError == ' ') {
      this.setState({
        messageOptionsError: ' hide',
      });
    }

    if (this.state.messageOptionsMaxError == ' ') {
      this.setState({
        messageOptionsMaxError: ' hide',
      });
    }

    if (this.refs.selectMessageType.value == '2') {
      this.setState({
        messageO1Chars: this.refs.txtSingleOption1.value.length,
        messageO2Chars: this.refs.txtSingleOption2.value.length,
        messageO3Chars: this.refs.txtSingleOption3.value.length,
        messageO4Chars: this.refs.txtSingleOption4.value.length,
        messageO5Chars: this.refs.txtSingleOption5.value.length,
        messageO6Chars: this.refs.txtSingleOption6.value.length,
      });
    }

    if (this.refs.selectMessageType.value == '3') {
      this.setState({
        messageO1Chars: this.refs.txtMultiOption1.value.length,
        messageO2Chars: this.refs.txtMultiOption2.value.length,
        messageO3Chars: this.refs.txtMultiOption3.value.length,
        messageO4Chars: this.refs.txtMultiOption4.value.length,
        messageO5Chars: this.refs.txtMultiOption5.value.length,
        messageO6Chars: this.refs.txtMultiOption6.value.length,
      });
    }
  }

  handleMessageBroadcast(e) {
    console.log('handleMessageBroadcast');
    const setToAllPatients = e.target.checked;
    console.log('handleMessageBroadcast', setToAllPatients);

    this.setState({
      messageBroadcast: setToAllPatients,
    });

    if (setToAllPatients) {
      this.setState({
        selectedMessageGroupsIDs: null,
        selectedMessagePatientsIDs: null,
        selectPatientsVisibility: ' hide',
      });
    } else {
      this.setState({
        selectedMessageGroupsIDs: [],
        selectedMessagePatientsIDs: [],
        selectPatientsVisibility: ' ',
        messagePatientsChipsVisible: 'hide',
      });
      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.messageAutocompleteDataList,
          limit: Infinity,
        },
      });
    }
  }

  handleDeliveryImmediate() {
    if (this.state.updateMessageMode == 'messageNotEditable') {
      return;
    }

    // TODO: Cancel the Repeat Option
    this.refs.checkRepeat.checked = false;
    const repeat = false;

    this.setState({
      messageDeliveryImmediate: true,
      messageRepeat: repeat,
      repeatConfigVisibility: repeat ? '' : ' hide',
      messageDeliveryDateTimeLabel: repeat ? 'First Delivery On' : 'Deliver On',
      repeatWeekVisibility: ' hide',

      messageRepeatStyle: 'disabled',
    });
  }

  handleDeliveryScheduled() {
    if (this.state.updateMessageMode == 'messageNotEditable') {
      return;
    }

    if (this.state.messageDeliveryImmediate) {
      console.log('Acomodate la heur.');
      let currentTime = new Date();
      currentTime = currentTime.getTime() + 5 * 60000;
      currentTime = new Date(currentTime);
      // console.log("1>> ", currentTime.toLocaleDateString('en-US', this.allOptions));
      // console.log("2>> ", currentTime.toLocaleDateString('en-US', this.dateOptions));
      // console.log("3>> ", currentTime.toLocaleTimeString('en-US', this.timeOptions));
      this.state.messageDateTime = currentTime; //.value  = currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
    }

    this.setState({
      messageDeliveryImmediate: false,
      messageRepeatStyle: this.refs.selectMessageType.value == '6' ? 'disabled' : '',
    });
  }

  handleSelectedFile(e) {
    console.log('SelectedFile');

    if (this.state.signMessageFileUrl != null) {
      console.log('Previous PdfFile url', this.state.signMessageFileUrl);
      const oldFileName = this.state.signMessageFileName;
      let oldFileBucket = this.state.signMessageFileUrl;
      oldFileBucket = oldFileBucket.replace('https://s3-us-west-2.amazonaws.com/nucleuscare-data/signatures/', '');
      oldFileBucket = oldFileBucket.replace(`/${oldFileName}`, '');
      console.log('oldFileName', oldFileName);
      console.log('oldFileBucket', oldFileBucket);

      this.setState({
        signMessagePreviousFileName: oldFileName,
        signMessagePreviousFileBucket: oldFileBucket,
      });
    }

    //let files = e.target.files;
    const pdfFile = e.target.files[0];
    console.log('pdfFile:', pdfFile);
    if (pdfFile) {
      const fileName = pdfFile.name.replaceAll(' ', '_');
      this.refs.txtSignFileUrl.value = fileName;

      const reader = new FileReader();
      const url = reader.readAsDataURL(pdfFile);
      const urlFile = URL.createObjectURL(pdfFile);
      reader.onloadend = function (e) {
        console.log('reader.onloaded', e);
        this.setState({
          selectedPdfFile: pdfFile,
          selectedPdfFileName: fileName,
          selectedPdfFilePreviewURL: urlFile,
          signMessageFileUrl: null,
        });
      }.bind(this);
      //console.log("Value image:image:",[reader.result]);
      console.log('url', url); // Would see a path?
      console.log('urlFile', urlFile); // Would see a path?
    }
  }

  handleSelectedPdfFilePreview() {
    //window.open(this.state.selectedPdfFile, '_blank');
    window.open(this.state.selectedPdfFilePreviewURL, '_blank');
  }

  handleSelectedPdfFileView() {
    window.open(this.state.signMessageFileUrl, '_blank');
  }

  handleResumeSaveMessage() {
    console.log('handleSaveMessage RESUME SAVE!!');

    if (this.state.newMessageCategoryID == null) {
      this.setState({
        messageCategoryError: ' ',
      });
      console.log('Msg-> 1');
      return;
    }

    if (!(this.refs.textMessage.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
        messageBodyErrorText:
          this.refs.selectMessageType.value == '4' ? 'Please add a short message that will show, or read aloud, before the link will open.' : 'Please insert the message text.',
      });
      console.log('Msg-> 2');
      return;
    }

    console.log('Mensaje!', this.refs.textMessage.value.length);
    if (this.refs.selectMessageType.value == '1' && this.refs.textMessage.value.length > 60) {
      this.refs.textMessage.focus();
      this.setState({
        messageBodyLengthError: ' ',
        messageBodyLengthErrorChars: '60',
      });
      console.log('Msg-> 2.5');
      return;
    } else if ((this.refs.selectMessageType.value == '2' || this.refs.selectMessageType.value == '3') && this.refs.textMessage.value.length > 60) {
      this.refs.textMessage.focus();
      this.setState({
        messageBodyLengthError: ' ',
        messageBodyLengthErrorChars: '60',
      });
      console.log('Msg-> 2.6');
      return;
    }

    if (this.refs.selectMessageType.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      console.log('Msg-> 3');
      return;
    }

    if (this.state.messageDateTime && this.state.messageDateTime.toString().length == 0) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      console.log('Msg-> 4');
      return;
    }
    /*
        if ( this.state.messageDeliveryImmediate && new Date(this.refs.messageDateTime.value) < new Date()){
            this.setState({
                messageDeliveryDateError : ' '
            });
            //$('#modalResumeSaveMessage').modal('close');
            console.log("Msg-> 4");
            return;
        }
        */
    if (this.refs.selectMessageType.value == '2' && this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault) {
      let optionsIncluded = false;
      if (this.refs.txtSingleOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Msg-> 5');
        return;
      }

      let maxTextLimit = false;
      if (this.refs.txtSingleOption1.value.length > 0 && this.refs.txtSingleOption1.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption2.value.length > 0 && this.refs.txtSingleOption2.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption3.value.length > 0 && this.refs.txtSingleOption3.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption4.value.length > 0 && this.refs.txtSingleOption4.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption5.value.length > 0 && this.refs.txtSingleOption5.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption6.value.length > 0 && this.refs.txtSingleOption6.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Msg-> 5.5');
        return;
      }
    }
    if (this.refs.selectMessageType.value == '3') {
      let optionsIncluded = false;
      if (this.refs.txtMultiOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Msg-> 6');
        return;
      }

      let maxTextLimit = false;
      if (this.refs.txtMultiOption1.value.length > 0 && this.refs.txtMultiOption1.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption2.value.length > 0 && this.refs.txtMultiOption2.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption3.value.length > 0 && this.refs.txtMultiOption3.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption4.value.length > 0 && this.refs.txtMultiOption4.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption5.value.length > 0 && this.refs.txtMultiOption5.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption6.value.length > 0 && this.refs.txtMultiOption6.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Msg-> 6.5');
        return;
      }
    }

    if (this.refs.selectMessageType.value == '4') {
      let errorEmptyLink = false;
      if (this.refs.txtLinkMessageUrl.value == '') {
        this.setState({
          messageLinkError: ' ',
        });
        console.log('Msg-> 7.5');
        errorEmptyLink = true;
      }
      if (this.refs.txtLinkMessageButton && this.refs.txtLinkMessageButton.value == '') {
        this.setState({
          messageButtonError: ' ',
        });
        console.log('Msg-> 7.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }

    if (this.refs.selectMessageType.value == '6') {
      let errorEmptyLink = false;
      if (this.refs.txtSignFileUrl.value == '') {
        this.setState({
          messageSignError: ' ',
        });
        console.log('Msg-> 8.5');
        errorEmptyLink = true;
      }
      if (this.refs.txtSignMessageButton && this.refs.txtSignMessageButton.value == '') {
        this.setState({
          messageSignButtonError: ' ',
        });
        console.log('Msg-> 8.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }

    console.warn('Patients');
    let namesArray = ' All ' + AuthStore.getPatientLabelPlural();
    console.log('namesArray', namesArray);
    console.log('this.state.selectedMessagePatientsIDs', this.state.selectedMessagePatientsIDs);
    console.log('this.state.selectedMessageGroupsIDs', this.state.selectedMessageGroupsIDs);

    if (this.state.selectedMessagePatientsIDs != null || this.state.selectedMessageGroupsIDs != null) {
      const chipsObjectValue = $('.chips-initial').material_chip('data');
      namesArray = '';
      chipsObjectValue.forEach(value => {
        //console.log("Selected", value);
        //console.log("SelectedFrom", this.state.messagePatientsList);
        if (namesArray.length > 0) {
          namesArray = namesArray + ', ';
        }
        namesArray = namesArray + value.tag;
        //console.log("PatientID: ", patientID);
      });
    }
    console.log('Checking Patients namesArray', namesArray);
    console.log('Checking Patients this.state.messageBroadcast', this.state.messageBroadcast);
    this.setState({
      messageDeliverTo: namesArray,
    });

    if (!this.state.messageBroadcast && namesArray == '') {
      this.setState({
        messagePatientsChipsVisible: '', // Show error "Select at least one Patient"
      });
      console.log('Msg-> 6.9');
      return;
    }

    if (this.state.messageRepeat && !(this.refs.txtRepeatNumber.value.length > 0)) {
      this.setState({
        messageRepeatEveryError: ' ',
      });
      console.log('Msg-> 7');
      return;
    }

    if (this.refs.selectMessageRecurring.value == 'Week') {
      if (
        !this.refs.checkSunday.checked &&
        !this.refs.checkMonday.checked &&
        !this.refs.checkTuesday.checked &&
        !this.refs.checkWednesday.checked &&
        !this.refs.checkThursday.checked &&
        !this.refs.checkFriday.checked &&
        !this.refs.checkSaturday.checked
      ) {
        this.setState({
          messageRepeatDaysError: ' ',
        });
        console.log('Msg-> 7.5');
        return;
      }
    }

    if (this.state.messageRepeatEnds && new Date(this.state.messageEndDateTime) < new Date(this.state.messageDateTime)) {
      this.setState({
        messageEndDateError: ' ',
      });
      console.log('Msg-> 8');
      return;
    }

    if (this.state.messageRepeat) {
      let repeatString = ' that will repeat every ' + this.refs.txtRepeatNumber.value + ' ' + this.refs.selectMessageRecurring.value;

      if (this.refs.txtRepeatNumber.value > 1) {
        repeatString += 's';
      }

      if (this.refs.selectMessageRecurring.value == 'Week') {
        const daysArray = [];
        if (this.refs.checkSunday.checked) {
          daysArray.push('Sunday');
        }
        if (this.refs.checkMonday.checked) {
          daysArray.push('Monday');
        }
        if (this.refs.checkTuesday.checked) {
          daysArray.push('Tuesday');
        }
        if (this.refs.checkWednesday.checked) {
          daysArray.push('Wednesday');
        }
        if (this.refs.checkThursday.checked) {
          daysArray.push('Thursday');
        }
        if (this.refs.checkFriday.checked) {
          daysArray.push('Friday');
        }
        if (this.refs.checkSaturday.checked) {
          daysArray.push('Saturday');
        }
        const days = daysArray.join(', ');
        repeatString = repeatString + ' on ' + days;
      }

      let endRepeatString = '.';
      if (this.state.messageRepeatEnds) {
        endRepeatString = ', and the last delivery will be ' + this.state.messageEndDateTime + '.';
      }

      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The first delivery will be now' + endRepeatString,
          confirmMessageTextLine3: 'Once sent you will not be able to edit the message.',
        });
      } else {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The first delivery will be ' + this.state.messageDateTime + endRepeatString,
          confirmMessageTextLine3: '',
        });
      }
    } else {
      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: ' to be delivered now.',
          confirmMessageTextLine2: 'Once sent you will not be able to edit the message.',
          confirmMessageTextLine3: '',
        });
      } else {
        this.setState({
          confirmMessageText: ' to be delivered ' + this.state.messageDateTime + '.',
          confirmMessageTextLine2: '',
          confirmMessageTextLine3: '',
        });
      }
    }

    if (this.refs.txtNoResponseTimeout.value == '' || this.refs.txtNoResponseTimeout.value < 0) {
      this.refs.txtNoResponseTimeout.value = 0;
    }

    if (this.refs.selectMessageType.value == '4') {
      const linkUrl = this.validateUrlLink(this.refs.txtLinkMessageUrl.value);
      this.refs.txtLinkMessageUrl.value = linkUrl;
    }

    if (this.refs.selectMessageType.value == '6') {
      const fileUrl = this.refs.txtSignFileUrl.value;
      this.refs.txtSignFileUrl.value = fileUrl;
    }

    $('#modalResumeSaveMessage').modal('open');
  }

  handleCancelResumeMessageModal() {
    $('#modalResumeSaveMessage').modal('close');
  }

  handleSaveMessage() {
    console.log('handleSaveMessage SAVE!!');
    /*
        this.setState({
            messageDeliverTo : namesArray,
            messageDeliveryAt : this.refs.messageDateTime.value
        });

        $('#modalResumeSaveMessage').modal('open');
        */

    let messageExpirationTime = 0;
    if (!this.state.messagePersistentState) {
      if (this.refs.txtExpirationTimeout.value == '' || this.refs.txtExpirationTimeout.value < 0) {
        this.refs.txtExpirationTimeout.value = 1;
      }
      messageExpirationTime = this.refs.txtExpirationTimeout.value;
    }

    let patientsIDsArray = null;
    let groupsIDsArray = null;
    if (this.props.patientID != null) {
      this.setState({
        selectedMessagePatientsIDs: this.props.patientID,
      });
      console.warn('Patients 1');
    } else {
      console.warn('Patients 2');
      console.log('selectedMessagePatientsIDs', this.state.selectedMessagePatientsIDs);
      console.log('selectedMessageGroupsIDs', this.state.selectedMessageGroupsIDs);
      if (this.state.selectedMessagePatientsIDs != null || this.state.selectedMessageGroupsIDs != null) {
        const chipsObjectValue = $('.chips-initial').material_chip('data');
        //console.log("handleSaveMessage patients Chips",chipsObjectValue);
        //console.log("handleSaveMessage patients Chips data", this.state.messagePatientsList);
        patientsIDsArray = '';
        groupsIDsArray = '';
        console.log('handleSaveMessage=====>>', chipsObjectValue);
        chipsObjectValue.forEach((value, key) => {
          console.log('handleSaveMessage checking!==>>', value);
          console.log('handleSaveMessage checking!==>>>', key);
          let patientID = null;
          patientID = this.state.messagePatientsList[value.tag];

          if (this.state.messageAutocompletePatientsList[value.tag]) {
            if (patientsIDsArray.length > 0) {
              patientsIDsArray = patientsIDsArray + ',';
            }
            patientsIDsArray = patientsIDsArray + patientID;
          }
          if (this.state.messageAutocompleteGroupsList[value.tag]) {
            if (groupsIDsArray.length > 0) {
              groupsIDsArray = groupsIDsArray + ',';
            }
            groupsIDsArray = groupsIDsArray + patientID;
          }
        });
        console.log('Patients IDS', patientsIDsArray);
        console.log('Groups IDS', groupsIDsArray);
        this.setState({
          selectedMessagePatientsIDs: patientsIDsArray,
          selectedMessageGroupsIDs: groupsIDsArray,
        });
      }
    }

    let messageOptionsArray = null;
    let messageTemplateID = '';
    if (this.refs.selectMessageType.value == '2' && this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault) {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption1.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption2.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption3.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption4.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption5.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption6.value);
    } else if (this.refs.selectMessageType.value == '2' && this.refs.selectMessageTemplate.value != this.state.messageTemplateDefault) {
      messageTemplateID = this.refs.selectMessageTemplate.value;
    } else if (this.refs.selectMessageType.value == '3') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption1.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption2.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption3.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption4.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption5.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption6.value);
    }
    console.log('Les Options sont', messageOptionsArray);

    let responseAlertsArray = null;
    if (this.state.responseAlertCustomVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption1of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption2of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption3of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption4of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption5of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption6of6.checked;
    } else if (this.state.responseAlertFacesVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption1of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption2of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption3of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption4of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption5of5.checked;
    } else if (this.state.responseAlertYesNoVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption1of2.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption2of2.checked;
    }

    // if (this.state.messageRepeatStyle == "disabled"){
    //     this.setState({
    //         messageRepeat :false
    //     });
    // }
    console.log('Message Repeat: ', this.state.messageRepeat, 'Repeat Every: ', this.refs.txtRepeatNumber.value, ' ', this.refs.selectMessageRecurring.value);

    const deliveryDateTime = new Date(this.state.messageDateTime);
    //deliveryDateTime.setHours(deliveryDateTime.getHours() + this.controlTimezone);
    //this.refs.messageDateTime.value  = deliveryDateTime.toLocaleDateString('en-US', this.dateOptions) + " " + deliveryDateTime.toLocaleTimeString('en-US', this.timeOptions);
    let deliveryUTCTime =
      deliveryDateTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + deliveryDateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

    let sendNow = false;
    if (this.state.messageDeliveryImmediate) {
      sendNow = true;
      let currentTime = new Date();
      currentTime = currentTime.getTime() + 1 * 60000;
      currentTime = new Date(currentTime);
      //currentTime.setHours(currentTime.getHours() + this.controlTimezone);
      this.state.messageDateTime = currentTime; // currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
      deliveryUTCTime =
        currentTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }

    let endRepeatDate = '';
    if (this.state.messageRepeatEnds) {
      endRepeatDate = this.state.messageEndDateTime;
    }

    let biometricType = null;
    if (this.refs.selectMessageType.value == '5') {
      // MessageType "5" : Biometrics
      biometricType = this.state.selectedBiometricTypeID;
    }

    const isLinkMessage = this.refs.selectMessageType.value == '4';
    const isSignMessage = this.refs.selectMessageType.value == '6';

    let linkMessageButtonText = '';
    try {
      linkMessageButtonText = this.refs.txtLinkMessageButton.value;
      if (this.refs.selectMessageType.value == '6') {
        linkMessageButtonText = this.refs.txtSignMessageButton.value;
      }
    } catch (error) {
      console.warn('Handled error setting this.refs.txtLinkMessageButton.value');
    }

    console.log('Repeat Ends :', this.state.messageRepeatEnds);
    console.log('Repeat Ends ON:', endRepeatDate);

    const linkUrl = this.refs.txtLinkMessageUrl.value;

    if (this.props.patientID != null) {
      patientsIDsArray = this.props.patientID;
    }

    console.log('SAVE ---------------------------------------');
    const newMessageData = {
      AccountID: AuthStore.getUserAccountID(),
      UserID: AuthStore.getUserID(),
      Message: this.refs.textMessage.value,
      SendToAll: this.state.messageBroadcast,
      DeliveryTime: deliveryUTCTime,
      MessageCategoryID: this.state.newMessageCategoryID, //this.refs.selectMessageCategory.value,
      IsPersistent: this.state.messagePersistentState,
      TtLHours: Number(messageExpirationTime),
      IsRecurring: this.state.messageRepeat,
      NoResponseTimeout: Number(this.refs.txtNoResponseTimeout.value),
      RepeatEvery: Number(this.refs.txtRepeatNumber.value),
      RepeatType: this.refs.selectMessageRecurring.value,
      RepeatOnSunday: this.refs.checkSunday.checked,
      RepeatOnMonday: this.refs.checkMonday.checked,
      RepeatOnTuesday: this.refs.checkTuesday.checked,
      RepeatOnWednesday: this.refs.checkWednesday.checked,
      RepeatOnThursday: this.refs.checkThursday.checked,
      RepeatOnFriday: this.refs.checkFriday.checked,
      RepeatOnSaturday: this.refs.checkSaturday.checked,
      EndDate: endRepeatDate,
      IsSingleOptionMessage: this.state.newMessageSingleChoice,
      IsMultipleOptionMessage: this.state.newMessageMultipleSelection,
      MessageOptionsTexts: messageOptionsArray,
      PatientsIDs: patientsIDsArray,
      GroupsIDs: groupsIDsArray,
      MessageOptionsTemplateID: messageTemplateID,
      IsLinkMessage: isLinkMessage,
      LinkMessageUrl: linkUrl,
      LinkMessageShouldOpen: this.state.messageLinkOpen,
      LinkMessageButton: linkMessageButtonText,
      IsSignatureMessage: isSignMessage,
      PdfFileUrl: isSignMessage ? null : null,
      BiometricTypeID: biometricType,
      MessageOptionsAlerts: responseAlertsArray,
      SendNow: sendNow,
    };
    console.log('newMessageData', newMessageData);
    console.log('this.controlTimezone', this.controlTimezone);

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn-disabled ',
      savingMessage: true,
      savingMessageText: 'Saving message...',
    });

    MessagesStore.saveNewMessageByUser(newMessageData);
  }

  onSaveNewMessageByUserAction(response) {
    console.log('onSaveNewMessageByUserAction', response);

    if (response && response.ok) {
      if (this.state.savingMessage && response.ID && this.state.selectedPdfFile != null) {
        MessagesStore.UploadSignatureMessagePdfFile({
          MessageID: response.ID,
          PdfFileName: this.state.selectedPdfFileName,
          File: this.state.selectedPdfFile,
        });
        this.setState({
          savingMessageText: 'Saving message file...',
          selectedPdfFile: null,
        });
      } else {
        $('#modalResumeSaveMessage').modal('close');
        this.setState({
          editMessageBtnStyle: ' nucleus-submit-btn ',
          savingMessage: false,
          savingMessageText: 'New message created.',
        });

        if (this.props.patientID) {
          CarePatientStore.getScheduledPatientMessages({
            PatientID: this.props.patientID,
          });
        } else {
          //TODO: REFRESH MESSAGES
          console.log('TODO: REFRESH MESSAGES');
          MessagesStore.refreshMessagesCenter();
        }
        Message.show('Message saved');
        this.handleCloseNewMessageModal();
      }
    } else {
      $('#modalResumeSaveMessage').modal('close');
      this.setState({
        editMessageBtnStyle: ' nucleus-submit-btn ',
        savingMessage: false,
      });
      Message.show('Error saving message');
    }
  }

  onSaveNewMessageFileAction(response) {
    console.log('onSaveNewMessageFileAction', response);

    if (response && response.ok) {
      $('#modalResumeSaveMessage').modal('close');
      this.setState({
        editMessageBtnStyle: ' nucleus-submit-btn ',
        savingMessage: false,
        savingMessageText: 'New message created.',
      });

      if (this.props.patientID) {
        CarePatientStore.getScheduledPatientMessages({
          PatientID: this.props.patientID,
        });
      } else {
        MessagesStore.refreshMessagesCenter();
      }
      Message.show('Message saved');
      this.handleCloseNewMessageModal();
    }

    if (this.state.messageModalMode == 'Edit') {
      $('#modalResumeUpdateMessage').modal('close');
      this.handleCloseNewMessageModal();
    }
  }

  handleMessageRepeat(e) {
    const repeat = e.target.checked;

    this.setState({
      messageRepeat: repeat,
      repeatConfigVisibility: repeat ? '' : ' hide',
      messageDeliveryDateTimeLabel: repeat ? 'First Delivery On' : 'Deliver On',
    });

    if (!repeat) {
      this.setState({
        repeatWeekVisibility: ' hide',
      });
    } else {
      this.refs.checkSunday.checked = false;
      this.refs.checkMonday.checked = false;
      this.refs.checkTuesday.checked = false;
      this.refs.checkWednesday.checked = false;
      this.refs.checkThursday.checked = false;
      this.refs.checkFriday.checked = false;
      this.refs.checkSaturday.checked = false;

      if (this.refs.selectMessageRecurring.value == 'Week') {
        this.setState({
          repeatWeekVisibility: ' ',
        });
      }
    }
  }

  handleStopRecurringMessageModal() {
    $('#modalStopRecurringMessage').modal('open');
  }

  handleCancelStopRecurringMessageModal() {
    $('#modalStopRecurringMessage').modal('close');
  }

  handleStopRecurringMessage() {
    MessagesStore.stopRecurringMessage({
      MessageID: this.state.selectedMessageID,
      stop: true,
    });
    $('#modalStopRecurringMessage').modal('close');
  }

  onStopRecurringMessageAction(response) {
    console.log('onStopRecurringMessageAction', response);

    if (response.ok) {
      Message.show('Recurring message stopped');
      this.handleCloseNewMessageModal();
    } else {
      Message.show('Error stopping recurring message');
    }
  }

  handleTxtRepeatEvery() {
    this.setState({
      messageRepeatEveryError: ' hide',
    });
  }

  handleSelectMessageRecurring() {
    if (this.refs.selectMessageRecurring.value == 'Week') {
      this.setState({
        repeatWeekVisibility: '',
      });
    } else {
      this.setState({
        repeatWeekVisibility: ' hide',
      });
      this.refs.checkSunday.checked = false;
      this.refs.checkMonday.checked = false;
      this.refs.checkTuesday.checked = false;
      this.refs.checkWednesday.checked = false;
      this.refs.checkThursday.checked = false;
      this.refs.checkFriday.checked = false;
      this.refs.checkSaturday.checked = false;
    }
  }

  handleDayCheckChange() {
    if (this.state.messageRepeatDaysError == ' ') {
      this.setState({
        messageRepeatDaysError: ' hide',
      });
    }
  }

  handleMessageEnds(e) {
    const repeatEnds = e.target.checked;

    this.setState({
      messageRepeatEnds: repeatEnds,
      messageRepeatEndsDateSection: repeatEnds ? '' : ' hide',
    });
  }

  handleRepeatNeverEnds() {
    if (this.state.updateMessageMode == 'messageNotEditable') {
      return;
    }

    this.setState({
      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',
    });
  }

  handleRepeatEndDate() {
    if (this.state.updateMessageMode == 'messageNotEditable') {
      return;
    }

    this.setState({
      messageRepeatEnds: true,
      messageRepeatEndsDateSection: ' ',
      messageEndDateError: ' hide',
    });
  }

  // TRIGGER UPDATE MESSAGE * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  onGetMessageDetailsAction(response) {
    console.log('onGetMessageDetailsAction');
    console.log(response);

    if (response.ok) {
      //Message.show("Got Message Info.");

      if (this.state.messageAutocompleteDataList == {}) {
        MessagesStore.getAutocompletePatientsGroupsList({
          UserID: AuthStore.getUserID(),
        });
      }

      $('#modalNewMessage').modal('open');

      let messageEditable = true;
      let messageRecurringEditable = true;

      const deliveryTime = new Date(response.data.DeliveryTime.replace('Z', ''));

      console.log('The controlTimezone', this.controlTimezone);
      if (response.data.ImmediateDelivery) {
        console.warn('Message is sendNow');
        deliveryTime.setHours(deliveryTime.getHours() + this.controlTimezone * -1);
      }

      const currentTime = new Date();
      //currentTime.setHours(currentTime.getHours() + (this.controlTimezone));
      console.log('DELIVERY ', deliveryTime);
      console.log('NOW UTC ', currentTime);
      //let oneDay=1000*60*60*24;
      //let daysBeforeDelivery = ( Math.abs((new Date(response.data.DeliveryTime)))- (new Date().getTime())) / oneDay;
      //let minsBeforeDelivery = daysBeforeDelivery * 1440;
      const diff = Math.abs(deliveryTime - currentTime);
      const minsBeforeDelivery = Math.floor(diff / 1000 / 60);

      let deliveryPassed = false;
      const messageModalNotEditableLabel = 'You cannot edit this message because the message has been sent';

      let isRecurring = response.data.IsRecurring;

      console.log('DAYS', diff, 'MINS', minsBeforeDelivery);
      if (deliveryTime > currentTime && minsBeforeDelivery > 5) {
        messageEditable = true;
        console.log('----------> 1');
      } else {
        deliveryPassed = true;
        console.log('----------> 1.5');
      }

      console.log('deliveryPassed', deliveryPassed);
      console.log('response.data.messagesAlreadyDelivered', response.data.messagesAlreadyDelivered);
      console.log('response.data.IsRecurring', response.data.IsRecurring);
      if (deliveryPassed && response.data.messagesAlreadyDelivered > 0 && !response.data.IsRecurring) {
        messageEditable = false;
        console.log('----------> 2  No Editable At All');
      }

      if (response.data.Editable != undefined && !response.data.Editable) {
        messageEditable = false;
        console.log('----------> 2  No Editable At All. Message from classes');
      }

      if ((deliveryPassed || response.data.messagesAlreadyDelivered > 0) && response.data.IsRecurring) {
        messageRecurringEditable = false;
        console.log('----------> 3 No Editable Recurring Msg');
        //messageModalNotEditableLabel = 'Message has already been sent and cannot be edited/deleted.';
      }

      // if (response.data.IsRecurring){
      //     messageModalNotEditableLabel = 'This Message has recurring deliveries, so it cannot be modified.';
      // }

      if (response.data.IsRecurring && response.data.IsRecurringStopped) {
        isRecurring = false;
        //messageModalNotEditableLabel = 'This Message is a Recurring Stopped Message, so it cannot be modified.';
      }

      if (!messageEditable) {
        messageRecurringEditable = false;
      }

      let regularMessageMode = ' ';
      let biometricMessageMode = ' hide';

      if (response.data.BiometricTypeID && response.data.BiometricTypeID.length > 0) {
        console.log('Biometrics Message');

        regularMessageMode = ' hide';
        biometricMessageMode = ' ';

        for (let i = 0; i < this.state.messageBiometricTypes.length; i++) {
          if (this.state.messageBiometricTypes[i].ID == response.data.BiometricTypeID) {
            this.refs.selectBiometricMessageType.value = response.data.BiometricTypeID;
            this.setState({
              selectedBiometricTypeID: this.state.messageBiometricTypes[i].ID,
              selectedBiometricTypeName: this.state.messageBiometricTypes[i].Type,
              selectedBiometricTypeText: this.state.messageBiometricTypes[i].MessageText,
              selectedBiometricTypeInputText: this.state.messageBiometricTypes[i].InputTextFields,
            });
            console.log('SELECTED BIOMETRIC TYPE (UPDATING)', this.state.messageBiometricTypes[i].Type);
            break;
          }
        }
      }

      this.setState({
        selectedPatientMessageID: null,
        selectedMessageID: response.data.ID,

        updateMessageMode: messageRecurringEditable ? 'message' : 'messageNotEditable',

        messageModalMode: 'Edit',
        messageSaveNewBtn: ' hide',
        messageUpdateBtn: ' ',

        messageModalModeShow: ' hide ', // The DELETE Option is going to be another Modal.
        messageModalNotEditable: messageEditable ? ' hide' : '',
        messageModalNotEditableLabel: messageModalNotEditableLabel,
        messageModalOptionLabel: 'Delete',
        messageModeEdit: '',
        messageModeDelete: 'hide',
        messageModalOptionColor: 'txt-red',

        selectFileBtnStyle: messageEditable ? ' nucleus-submit-btn-mini' : ' nucleus-submit-btn-mini-disabled',
        deleteMessageBtnStyle: ' nucleus-submit-btn',

        editMessageBtnStyle: messageRecurringEditable || (isRecurring && !response.data.IsRecurringStopped) ? ' nucleus-submit-btn ' : ' nucleus-submit-btn-disabled ',
        messageEditable: messageRecurringEditable ? '' : 'disabled',

        messageEditableRecurring: messageRecurringEditable ? '' : 'disabled',
        messageTemplateEditable: messageRecurringEditable ? '' : 'disabled',

        messageBroadcast: response.data.SendToAll,
        messagePatientsChipsVisible: ' hide',

        messagePersistentState: response.data.IsPersistent,

        selectedPdfFile: null,
        selectedPdfFileName: '',
        selectedPdfFilePreviewURL: '',

        messageOptionsError: ' hide',
        messageOptionsMaxError: ' hide',
        messageMessageCategoryID: response.data.CategoryID,
        messageCategoryError: ' hide',

        messageDeliveryDateError: ' hide',
        messageEndDateError: ' hide',

        messageRepeat: isRecurring,

        messageDeliveryDateTimeLabel: response.data.IsRecurring && !response.data.IsRecurringStopped ? 'Next Delivery On' : 'Deliver On',

        repeatConfigVisibility: isRecurring ? '' : ' hide',

        repeatWeekVisibility: isRecurring && response.data.RepeatType == 'Week' ? '' : ' hide',

        //messageRepeat : false,
        messageRepeatStyle: messageRecurringEditable || (isRecurring && !response.data.IsRecurringStopped) ? '' : 'disabled',
        messageRepeatEveryError: ' hide',
        messageRepeatDaysError: ' hide',

        messageEscalationStyle: messageRecurringEditable || (isRecurring && !response.data.IsRecurringStopped) ? '' : 'disabled',

        stopRecurringMessageVisibility: isRecurring ? ' hide' : ' hide',
        stopRecurringMessageStyle: response.data.IsRecurringStopped ? ' nucleus-submit-btn-small-disabled ' : ' nucleus-submit-btn-small ',

        messageRepeatEnds: response.data.EndDate != null,
        messageRepeatEndsDateSection: response.data.EndDate != null ? '' : ' hide',

        messageDeliveryImmediate: false,
        //messageDeliveryDateTimeLabel : 'Deliver On',
        //messageDeliveryImmediate : true,

        regularMessageMode: regularMessageMode,
        biometricMessageMode: biometricMessageMode,

        messageBodyChars: response.data.Message.length,

        messageO1Chars: 0,
        messageO2Chars: 0,
        messageO3Chars: 0,
        messageO4Chars: 0,
        messageO5Chars: 0,
        messageO6Chars: 0,
      });

      //this.refs.selectMessageCategory.value = response.data.CategoryID; category is Deprecated
      this.refs.checkMessageAllPatients.checked = response.data.SendToAll;

      this.refs.textMessage.value = response.data.Message;
      this.refs.checkPersistentState.checked = response.data.IsPersistent;
      if (!response.data.IsPersistent) {
        this.refs.txtExpirationTimeout.value = response.data.TTLHours;
      }
      this.refs.txtNoResponseTimeout.value = response.data.NoResponseTimeout;

      const existingPatients = [];
      if (!response.data.SendToAll) {
        for (let i = 0; i < response.data.patients.length; i++) {
          const patientChip = {};
          patientChip['tag'] = response.data.patients[i].Name;
          existingPatients.push(patientChip);
        }
      }

      $('.chips-initial').material_chip({
        data: existingPatients,
        placeholder: 'Type a name',
        secondaryPlaceholder: messageRecurringEditable ? 'Type another name' : '',
        autocompleteOptions: {
          data: this.state.messageAutocompleteDataList,
          limit: 2,
        },
      });

      if (response.data.IsSignatureMessage) {
        // SIGNATURE MESSAGE
        this.refs.selectMessageType.value = '6';

        console.log("Com'on ", isRecurring, messageEditable, messageRecurringEditable);

        let filename = '';
        if (response.data.PdfFileUrl) {
          filename = response.data.PdfFileUrl.substring(response.data.PdfFileUrl.lastIndexOf('/') + 1);
        }

        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' ',

          responseAlertCustomVisibility: ' hide',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' hide',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          messageRepeatStyle: messageEditable ? 'disabled' : 'disabled',
          messageTypeError: ' hide',

          messageLinkOpen: response.data.LinkMessageShouldOpen,
          messageLinkError: ' hide',
          messageButtonError: ' hide',

          signMessage: true,
          signMessageFileUrl: response.data.PdfFileUrl,
          signMessageFileName: filename,
          signMessageButton: response.data.LinkMessageButton,
          signMessagePreviousFileName: null,
          signMessagePreviousFileBucket: null,

          messageSignError: ' hide',
          messageSignButtonError: ' hide',

          canSendAllPatients: messageRecurringEditable ? '' : 'disabled',
          messageBroadcast: response.data.SendToAll,

          selectedMessagePatientsIDs: [],
          selectPatientsVisibility: response.data.SendToAll ? ' hide' : ' ',

          newMessageTemplate: null,
          //messageTemplateEditable : ' disabled'
        });

        this.refs.txtSignFileUrl.value = filename;
        this.refs.txtSignMessageButton.value = response.data.LinkMessageButton;

        if (this.refs.txtExpirationTimeout.value == '' || this.refs.txtExpirationTimeout.value < 0) {
          this.refs.txtExpirationTimeout.value = 1;
        }
      } else if (response.data.IsLinkMessage) {
        // LINK
        this.refs.selectMessageType.value = '4';

        console.log("Com'on ", isRecurring, messageEditable, messageRecurringEditable);
        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' ',
          signMessageVisibility: ' hide',

          responseAlertCustomVisibility: ' hide',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' hide',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          //messageRepeatStyle : messageEditable ? '' : 'disabled',
          messageTypeError: ' hide',

          messageLinkOpen: response.data.LinkMessageShouldOpen,
          messageLinkError: ' hide',
          messageButtonError: ' hide',

          canSendAllPatients: messageRecurringEditable ? '' : 'disabled',
          messageBroadcast: response.data.SendToAll,

          selectedMessagePatientsIDs: [],
          selectPatientsVisibility: response.data.SendToAll ? ' hide' : ' ',

          newMessageTemplate: null,
          //messageTemplateEditable : ' disabled'
        });

        this.refs.checkMessageLinkOpen.checked = response.data.LinkMessageShouldOpen;
        this.refs.txtLinkMessageUrl.value = response.data.LinkMessageUrl;
        if (!response.data.LinkMessageShouldOpen) {
          this.refs.txtLinkMessageButton.value = response.data.LinkMessageButton;
        }
      } else if (response.data.IsSingleOptionMessage) {
        // SINGLE CHOICE
        this.refs.selectMessageType.value = '2';

        this.setState({
          newMessageSingleChoice: true,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' ',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' hide',

          messageTemplateVisibility: ' ',

          responseAlertCustomVisibility: ' ',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          //messageRepeatStyle : '',
          messageTypeError: ' hide',

          canSendAllPatients: messageRecurringEditable ? '' : 'disabled',
          messageBroadcast: response.data.SendToAll,

          selectedMessagePatientsIDs: [],
          selectPatientsVisibility: response.data.SendToAll ? ' hide' : ' ',

          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',
          //messageTemplateEditable : '',
          messageTemplateError: ' hide',
        });

        this.refs.selectMessageTemplate.value = '';
        console.log('Template 1', response.data.MessageTemplateID);

        if (response.data.MessageTemplateID != null) {
          this.refs.selectMessageTemplate.value = response.data.MessageTemplateID;
          console.log('Template 2', response.data.MessageTemplateID);
        } else {
          this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
        }

        console.log('Template 3', this.state.messageTemplateDefault);
        if (this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault) {
          console.log('Template 4', response.data.MessageTemplateID);
          this.setState({
            singleChoiceVisibility: ' ',
            messageTemplateDetailsVisibiity: ' hide',
            newMessageTemplate: this.refs.selectMessageTemplate.value,
          });
          this.refs.txtSingleOption1.value = response.data.options[0] ? response.data.options[0].OptionText : '';
          this.refs.txtSingleOption2.value = response.data.options[1] ? response.data.options[1].OptionText : '';
          this.refs.txtSingleOption3.value = response.data.options[2] ? response.data.options[2].OptionText : '';
          this.refs.txtSingleOption4.value = response.data.options[3] ? response.data.options[3].OptionText : '';
          this.refs.txtSingleOption5.value = response.data.options[4] ? response.data.options[4].OptionText : '';
          this.refs.txtSingleOption6.value = response.data.options[5] ? response.data.options[5].OptionText : '';
        } else {
          this.setState({
            singleChoiceVisibility: ' hide',
            messageTemplateDetailsVisibiity: '',
            newMessageTemplate: this.refs.selectMessageTemplate.value,
          });

          this.refs.txtSingleOption1.value = '';
          this.refs.txtSingleOption2.value = '';
          this.refs.txtSingleOption3.value = '';
          this.refs.txtSingleOption4.value = '';
          this.refs.txtSingleOption5.value = '';
          this.refs.txtSingleOption6.value = '';
        }
        console.log('SELECTED>', this.refs.selectMessageTemplate.selectedOptions[0].text);

        this.setState({
          messageO1Chars: this.refs.txtSingleOption1.value.length,
          messageO2Chars: this.refs.txtSingleOption2.value.length,
          messageO3Chars: this.refs.txtSingleOption3.value.length,
          messageO4Chars: this.refs.txtSingleOption4.value.length,
          messageO5Chars: this.refs.txtSingleOption5.value.length,
          messageO6Chars: this.refs.txtSingleOption6.value.length,
        });
        //this.refs.checkMessageAllPatients.checked = true;
      } else if (response.data.IsMultipleOptionMessage) {
        // MULTIPLE CHOICE
        this.refs.selectMessageType.value = '3';

        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: true,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' ',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' hide',

          responseAlertCustomVisibility: ' ',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' ',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          //messageRepeatStyle : '',
          messageTypeError: ' hide',

          canSendAllPatients: messageRecurringEditable ? '' : 'disabled',
          messageBroadcast: response.data.SendToAll,

          selectedMessagePatientsIDs: [],
          selectPatientsVisibility: response.data.SendToAll ? ' hide' : ' ',

          newMessageTemplate: null,

          //messageTemplateEditable : ' disabled',
        });

        this.refs.selectMessageTemplate.value = '';

        if (this.state.messageTemplateDefault != null) {
          this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
        }

        this.refs.txtMultiOption1.value = response.data.options[0].OptionText;
        this.refs.txtMultiOption2.value = response.data.options[1].OptionText;
        this.refs.txtMultiOption3.value = response.data.options[2].OptionText;
        this.refs.txtMultiOption4.value = response.data.options[3]?.OptionText;
        this.refs.txtMultiOption5.value = response.data.options[4]?.OptionText;
        this.refs.txtMultiOption6.value = response.data.options[5]?.OptionText;

        this.setState({
          messageO1Chars: this.refs.txtMultiOption1.value.length,
          messageO2Chars: this.refs.txtMultiOption2.value.length,
          messageO3Chars: this.refs.txtMultiOption3.value.length,
          messageO4Chars: this.refs.txtMultiOption4.value.length,
          messageO5Chars: this.refs.txtMultiOption5.value.length,
          messageO6Chars: this.refs.txtMultiOption6.value.length,
        });
        //this.refs.checkMessageAllPatients.checked = true;
      } else {
        // PLAIN TEXT
        this.refs.selectMessageType.value = '1';

        console.log("Com'on ", isRecurring, messageEditable, messageRecurringEditable);
        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' hide',

          responseAlertCustomVisibility: ' hide',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' hide',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          //messageRepeatStyle : messageEditable ? '' : 'disabled',
          messageTypeError: ' hide',

          canSendAllPatients: messageRecurringEditable ? '' : 'disabled',
          messageBroadcast: response.data.SendToAll,

          selectedMessagePatientsIDs: [],
          selectPatientsVisibility: response.data.SendToAll ? ' hide' : ' ',

          newMessageTemplate: null,
          //messageTemplateEditable : ' disabled'
        });
      }

      const singleChoiceTemplate = this.refs.selectMessageTemplate.selectedOptions[0].text;

      if (this.refs.selectMessageType.value == '2' || this.refs.selectMessageType.value == '3') {
        if (singleChoiceTemplate == 'Yes / No - Text Buttons' || singleChoiceTemplate == 'Yes / No - Icon Buttons') {
          this.setState({
            responseAlertCustomVisibility: ' hide',
            responseAlertFacesVisibility: ' hide',
            responseAlertYesNoVisibility: ' ',
            responseAlertYesLabel: 'Yes',
            responseAlertNoLabel: 'No',
          });

          this.refs.checkOption1of2.checked = response.data.options[0].ShouldAlert;
          this.refs.checkOption2of2.checked = response.data.options[1].ShouldAlert;
        } else if (singleChoiceTemplate == 'Thumbs Up / Down') {
          this.setState({
            responseAlertCustomVisibility: ' hide',
            responseAlertFacesVisibility: ' hide',
            responseAlertYesNoVisibility: ' ',
            responseAlertYesLabel: 'Thumbs Up',
            responseAlertNoLabel: 'Thumbs Down',
          });

          this.refs.checkOption1of2.checked = response.data.options[0].ShouldAlert;
          this.refs.checkOption2of2.checked = response.data.options[1].ShouldAlert;
        } else if (singleChoiceTemplate == 'Feeling Emojis') {
          this.setState({
            responseAlertCustomVisibility: ' hide',
            responseAlertFacesVisibility: ' ',
            responseAlertYesNoVisibility: ' hide',
          });
          this.refs.checkOption1of5.checked = response.data.options[0].ShouldAlert;
          this.refs.checkOption2of5.checked = response.data.options[1].ShouldAlert;
          this.refs.checkOption3of5.checked = response.data.options[2].ShouldAlert;
          this.refs.checkOption4of5.checked = response.data.options[3].ShouldAlert;
          this.refs.checkOption5of5.checked = response.data.options[4].ShouldAlert;
        } else {
          this.setState({
            responseAlertCustomVisibility: ' ',
            responseAlertFacesVisibility: ' hide',
            responseAlertYesNoVisibility: ' hide',
          });
          this.refs.checkOption1of6.checked = response.data.options[0].ShouldAlert;
          this.refs.checkOption2of6.checked = response.data.options[1].ShouldAlert;
          this.refs.checkOption3of6.checked = response.data.options[2].ShouldAlert;
          this.refs.checkOption4of6.checked = response.data.options[3]?.ShouldAlert;
          this.refs.checkOption5of6.checked = response.data.options[4]?.ShouldAlert;
          this.refs.checkOption6of6.checked = response.data.options[5]?.ShouldAlert;
        }
      }

      this.state.messageDateTime = deliveryTime; // delivery.toLocaleDateString('en-US', this.dateOptions) + " " + delivery.toLocaleTimeString('en-US', this.timeOptions);

      this.refs.checkRepeat.checked = isRecurring;

      // if (isRecurring || response.data.IsRecurringStopped || !messageEditable){

      //     this.setState({
      //         messageTemplateEditable : ' disabled',
      //         messageEditableRecurring : ' disabled'
      //     });
      // }

      this.refs.txtRepeatNumber.value = response.data.RepeatEvery;
      this.refs.selectMessageRecurring.value = response.data.RepeatType;

      this.refs.checkSunday.checked = response.data.RepeatOnSunday;
      this.refs.checkMonday.checked = response.data.RepeatOnMonday;
      this.refs.checkTuesday.checked = response.data.RepeatOnTuesday;
      this.refs.checkWednesday.checked = response.data.RepeatOnWednesday;
      this.refs.checkThursday.checked = response.data.RepeatOnThursday;
      this.refs.checkFriday.checked = response.data.RepeatOnFriday;
      this.refs.checkSaturday.checked = response.data.RepeatOnSaturday;

      let endRepeatDate = new Date();
      if (response.data.EndDate == null) {
        this.setState({
          messageRepeatEnds: false,
          messageRepeatEndsDateSection: ' hide',
        });
      } else {
        this.setState({
          messageRepeatEnds: true,
          messageRepeatEndsDateSection: ' ',
          messageEndDateError: ' hide',
        });
        endRepeatDate = new Date(response.data.EndDate);
      }
      this.state.messageEndDateTime = endRepeatDate; // endRepeatDate.toLocaleDateString('en-US', this.dateOptions) + " " + endRepeatDate.toLocaleTimeString('en-US', this.timeOptions);

      if (messageRecurringEditable) {
        $('#txtPatients :input').prop('disabled', false);
        $('#txtPatients').find('i').css('visibility', 'visible');
      } else {
        $('#txtPatients :input').prop('disabled', true);
        $('#txtPatients').find('i').css('visibility', 'hidden');
      }

      if (this.state.messageDeliveryDateTimeLabel == 'Next Delivery On' && deliveryPassed) {
        this.setState({
          messageDeliveryDateTimeLabel: 'First Delivery On',
        });
      }
    } else {
      Message.show('Error getting message details.');
    }

    console.log('Good!', this.state.messageEditable + this.state.messageTemplateEditable);
  }

  onGetPatientMessageDetailsAction() {
    console.log('onGetPatientMessageDetailsAction');
    /*
        console.log(response);

        if (response.ok){
            //Message.show("Got Message Info.");

            $('#modalNewMessage').modal('open');

            let regularMessageMode = ' ';
            let biometricMessageMode = ' hide';

            if (response.data.BiometricTypeID && response.data.BiometricTypeID.length > 0){
                console.log("Biometrics Message");

                regularMessageMode = ' hide';
                biometricMessageMode = ' ';
                
                for(var i = 0; i < this.state.messageBiometricTypes.length; i++){
    
                    if ( this.state.messageBiometricTypes[i].ID == response.data.BiometricTypeID ){
                        this.refs.selectBiometricMessageType.value = response.data.BiometricTypeID;
                        this.setState({
                            selectedBiometricTypeID : this.state.messageBiometricTypes[i].ID,
                            selectedBiometricTypeName : this.state.messageBiometricTypes[i].Type,
                            selectedBiometricTypeText : this.state.messageBiometricTypes[i].MessageText,
                            selectedBiometricTypeInputText : this.state.messageBiometricTypes[i].InputTextFields
                        });
                        console.log("SELECTED BIOMETRIC TYPE (UPDATING)", this.state.messageBiometricTypes[i].Type);
                        break;
                    }
                }
            }

            this.setState({
                selectedPatientMessageID : response.data.ID,
                selectedMessageID : response.data.MessageID,

                updateMessageMode : 'patientMessage',

                messageModalMode : "Edit",
                messageSaveNewBtn : ' hide',
                messageUpdateBtn : ' ',

                messageModalModeShow :  ' hide ', // The DELETE Option is going to be another Modal.
                messageModalNotEditable :' hide' ,
                messageModalOptionLabel : "Delete",
                messageModeEdit : '',
                messageModeDelete : 'hide',
                messageModalOptionColor : 'txt-red',

                deleteMessageBtnStyle : ' nucleus-submit-btn',

                editMessageBtnStyle : true ? ' nucleus-submit-btn ' : ' nucleus-submit-btn-disabled ',

                messageEditable : true ? '' : 'disabled',
                messageEditableRecurring : '',

                messageBroadcast : response.data.SendToAll,


                messageOptionsError : ' hide',
                messageOptionsMaxError : ' hide',
                newMessageCategoryID : response.data.CategoryID,
                messageCategoryError : ' hide',

                messageDeliveryDateError : ' hide', 
                messageEndDateError : ' hide',

                messageRepeat : response.data.IsRecurring,

                messageDeliveryDateTimeLabel: response.data.IsRecurring ? 'Next Delivery On' : 'Deliver On',

                repeatConfigVisibility : response.data.IsRecurring ? '' : ' hide',

                repeatWeekVisibility : (response.data.IsRecurring && response.data.RepeatType == "Week") ? '' : ' hide',

                //messageRepeat : false,
                messageRepeatStyle : '',
                messageRepeatEveryError : ' hide',
                messageRepeatDaysError : ' hide', 

                stopRecurringMessageVisibility : response.data.IsRecurring ? ' hide' : ' hide',
                stopRecurringMessageStyle : response.data.IsRecurringStopped ? ' nucleus-submit-btn-small-disabled ' : ' nucleus-submit-btn-small ',

                messageRepeatEnds : (response.data.EndDate != ""),
                messageRepeatEndsDateSection : (response.data.EndDate != "") ? '' : ' hide',

                messageDeliveryImmediate : false,

                regularMessageMode : regularMessageMode,
                biometricMessageMode : biometricMessageMode

            });

            this.refs.selectMessageCategory.value = response.data.CategoryID;
            this.refs.checkMessageAllPatients.checked = response.data.SendToAll;

            this.refs.textMessage.value = response.data.Message;
            this.refs.txtNoResponseTimeout.value = response.data.NoResponseTimeout;


            let existingPatients = [];
            if (!response.data.SendToAll){

                for (var i = 0; i < response.data.patients.length; i++){
                    let patientChip =  {};
                    patientChip["tag"] = response.data.patients[i].Name;
                    existingPatients.push(patientChip);
                }
            }

            $('.chips-initial').material_chip({
                data: existingPatients,
                placeholder: 'Type a name',
                secondaryPlaceholder: MessageEditable ? 'Type another name' : '',
                autocompleteOptions: {
                  data: this.state.messageAutocompletePatientsList,
                  limit: 2
                }
            });

            // /////
            ///// //
            let delivery = new Date(response.data.DeliveryDateTime);    

            if (response.data.IsSingleOptionMessage){

                this.refs.selectMessageType.value = "2";

                this.setState({
                    newMessageSingleChoice : true,
                    newMessageMultipleSelection : false,
                    singleChoiceVisibility : ' ',
                    multipleOptionVisibility : ' hide',

                    messageTemplateVisibility : ' ', 

                    responseAlertCustomVisibility : ' ',
                    responseAlertFacesVisibility : ' hide',
                    responseAlertYesNoVisibility : ' hide',

                    messageRepeatStyle : '',
                    messageTypeError : ' hide',

                    canSendAllPatients : response.data.IsRecurring ? '' : 'disabled',
                    messageBroadcast : true,

                    selectedMessagePatientsIDs : null,
                    selectPatientsVisibility : ' hide',

                    messageOptionsError : ' hide',
                    messageOptionsMaxError : ' hide', 
                    messageTemplateEditable : '',
                    messageTemplateError : ' hide'
                });

                this.refs.selectMessageTemplate.value = "";
                console.log("Template 1", response.data.MessageTemplateID);

                if (response.data.MessageTemplateID != null){
                    this.refs.selectMessageTemplate.value = response.data.MessageTemplateID;
                    console.log("Template 2", response.data.MessageTemplateID);
                } else {
                    this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
                }

                console.log("Template 3", this.state.messageTemplateDefault);
                if (this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault){
                    console.log("Template 4", response.data.MessageTemplateID);
                    this.setState({
                        singleChoiceVisibility : ' ', 
                        messageTemplateDetailsVisibiity : ' hide',
                        newMessageTemplate : this.refs.selectMessageTemplate.value,
                    });
                    this.refs.txtSingleOption1.value = response.data.options[0].OptionText;
                    this.refs.txtSingleOption2.value = response.data.options[1].OptionText;
                    this.refs.txtSingleOption3.value = response.data.options[2].OptionText;
                    this.refs.txtSingleOption4.value = response.data.options[3].OptionText;
                    this.refs.txtSingleOption5.value = response.data.options[4].OptionText;
                    this.refs.txtSingleOption6.value = response.data.options[5].OptionText;
                } else {
                    this.setState({
                        singleChoiceVisibility : ' hide', 
                        messageTemplateDetailsVisibiity : '',
                        newMessageTemplate : this.refs.selectMessageTemplate.value,
                    });

                    this.refs.txtSingleOption1.value = "";
                    this.refs.txtSingleOption2.value = "";
                    this.refs.txtSingleOption3.value = "";
                    this.refs.txtSingleOption4.value = "";
                    this.refs.txtSingleOption5.value = "";
                    this.refs.txtSingleOption6.value = "";
                }
                console.log("SELECTED>", this.refs.selectMessageTemplate.selectedOptions[0].text);

                //this.refs.checkMessageAllPatients.checked = true;

            } else if (response.data.IsMultipleOptionMessage){

                this.refs.selectMessageType.value = "3";

                this.setState({
                    newMessageSingleChoice : false,
                    newMessageMultipleSelection : true,
                    singleChoiceVisibility : ' hide',
                    multipleOptionVisibility : ' ',

                    responseAlertCustomVisibility : ' ',
                    responseAlertFacesVisibility : ' hide',
                    responseAlertYesNoVisibility : ' hide',

                    messageTemplateVisibility : ' ', 
                    messageTemplateDetailsVisibiity : ' hide', 
                    messageOptionsError : ' hide',
                    messageOptionsMaxError : ' hide', 

                    messageRepeatStyle : '',
                    messageTypeError : ' hide',

                    canSendAllPatients : response.data.IsRecurring ? '' : 'disabled',
                    messageBroadcast : true,

                    selectedMessagePatientsIDs : null,
                    selectPatientsVisibility : ' hide',

                    newMessageTemplate : null,

                    messageTemplateEditable : ' disabled',
                });

                this.refs.selectMessageTemplate.value = "";

                if (this.state.messageTemplateDefault != null){
                    this.refs.selectMessageTemplate.value = this.state.messageTemplateDefault;
                }

                this.refs.txtMultiOption1.value = response.data.options[0].OptionText;
                this.refs.txtMultiOption2.value = response.data.options[1].OptionText;
                this.refs.txtMultiOption3.value = response.data.options[2].OptionText;
                this.refs.txtMultiOption4.value = response.data.options[3].OptionText;
                this.refs.txtMultiOption5.value = response.data.options[4].OptionText;
                this.refs.txtMultiOption6.value = response.data.options[5].OptionText;

                //this.refs.checkMessageAllPatients.checked = true;

            } else {

                this.refs.selectMessageType.value = "1";

                this.setState({
                    newMessageSingleChoice : false,
                    newMessageMultipleSelection : false,
                    singleChoiceVisibility : ' hide',
                    multipleOptionVisibility : ' hide',

                    responseAlertCustomVisibility : ' hide',
                    responseAlertFacesVisibility : ' hide',
                    responseAlertYesNoVisibility : ' hide',

                    messageTemplateVisibility : ' hide',
                    messageTemplateDetailsVisibiity : ' hide', 
                    messageOptionsError : ' hide',
                    messageOptionsMaxError : ' hide', 

                    messageRepeatStyle : true ? '' : 'disabled',
                    messageTypeError : ' hide',

                    canSendAllPatients : response.data.IsRecurring ? '' : 'disabled',

                    selectedMessagePatientsIDs : [],
                    selectPatientsVisibility : response.data.SendToAll ? ' hide' : ' ',

                    newMessageTemplate : null,
                    messageTemplateEditable : ' disabled'
                });
            }


            let singleChoiceTemplate = this.refs.selectMessageTemplate.selectedOptions[0].text ;
            
            if (this.refs.selectMessageType.value != "1"){
                if (singleChoiceTemplate == 'Yes / No - Text Buttons' || singleChoiceTemplate == 'Yes / No - Icon Buttons'){
                    this.setState({
                        responseAlertCustomVisibility : ' hide',
                        responseAlertFacesVisibility : ' hide',
                        responseAlertYesNoVisibility : ' ',
                        responseAlertYesLabel : 'Yes',
                        responseAlertNoLabel : 'No',
                    });


                    this.refs.checkOption1of2.checked = response.data.options[0].ShouldAlert;
                    this.refs.checkOption2of2.checked = response.data.options[1].ShouldAlert;
                } 
                else if (singleChoiceTemplate == 'Thumbs Up / Down'){
                    this.setState({
                        responseAlertCustomVisibility : ' hide',
                        responseAlertFacesVisibility : ' hide',
                        responseAlertYesNoVisibility : ' ',
                        responseAlertYesLabel : 'Thumbs Up',
                        responseAlertNoLabel : 'Thumbs Down',
                    });

                    this.refs.checkOption1of2.checked = response.data.options[0].ShouldAlert;
                    this.refs.checkOption2of2.checked = response.data.options[1].ShouldAlert;
                } 
                else if ( singleChoiceTemplate == 'Feeling Emojis'){
                    this.setState({
                        responseAlertCustomVisibility : ' hide',
                        responseAlertFacesVisibility : ' ',
                        responseAlertYesNoVisibility : ' hide',
                    });
                    this.refs.checkOption1of5.checked = response.data.options[0].ShouldAlert;
                    this.refs.checkOption2of5.checked = response.data.options[1].ShouldAlert;
                    this.refs.checkOption3of5.checked = response.data.options[2].ShouldAlert;
                    this.refs.checkOption4of5.checked = response.data.options[3].ShouldAlert;
                    this.refs.checkOption5of5.checked = response.data.options[4].ShouldAlert;

                } 
                else {
                    this.setState({
                        responseAlertCustomVisibility : ' ',
                        responseAlertFacesVisibility : ' hide',
                        responseAlertYesNoVisibility : ' hide',
                    });
                    this.refs.checkOption1of6.checked = response.data.options[0].ShouldAlert;
                    this.refs.checkOption2of6.checked = response.data.options[1].ShouldAlert;
                    this.refs.checkOption3of6.checked = response.data.options[2].ShouldAlert;
                    this.refs.checkOption4of6.checked = response.data.options[3].ShouldAlert;
                    this.refs.checkOption5of6.checked = response.data.options[4].ShouldAlert;
                    this.refs.checkOption6of6.checked = response.data.options[5].ShouldAlert;
                }
            }

            this.refs.messageDateTime.value  = delivery.toLocaleDateString('en-US', this.dateOptions) + " " + delivery.toLocaleTimeString('en-US', this.timeOptions);
            
            this.refs.checkRepeat.checked = response.data.IsRecurring;

            if (response.data.IsRecurring || response.data.IsRecurringStopped || !messageEditable){

                this.setState({
                    messageTemplateEditable : ' disabled',
                    messageEditableRecurring : ' disabled'
                });
            }

            this.refs.txtRepeatNumber.value = response.data.RepeatEvery;
            this.refs.selectMessageRecurring.value = response.data.RepeatType ;

            this.refs.checkSunday.checked = response.data.RepeatOnSunday;
            this.refs.checkMonday.checked = response.data.RepeatOnMonday;
            this.refs.checkTuesday.checked = response.data.RepeatOnTuesday;
            this.refs.checkWednesday.checked = response.data.RepeatOnWednesday;
            this.refs.checkThursday.checked = response.data.RepeatOnThursday;
            this.refs.checkFriday.checked = response.data.RepeatOnFriday;
            this.refs.checkSaturday.checked = response.data.RepeatOnSaturday;

            let endRepeatDate = new Date();
            if (response.data.EndDate == ""){
                this.setState({
                    messageRepeatEnds : false,
                    messageRepeatEndsDateSection : ' hide'
                });
            } else {
                this.setState({
                    messageRepeatEnds : true,
                    messageRepeatEndsDateSection : ' ',
                    messageEndDateError : ' hide'
                });
                endRepeatDate = new Date(response.data.EndDate);
            }
            this.refs.messageEndDateTime.value  = endRepeatDate.toLocaleDateString('en-US', this.dateOptions) + " " + endRepeatDate.toLocaleTimeString('en-US', this.timeOptions);

        } 
        else{
            Message.show("Error getting message details.");
        }
        */
  }

  onGetMessageDetailsForDeleteAction(response) {
    console.log('onGetMessageDetailsForDeleteAction', response);

    if (response.ok) {
      let messageEditable = true;
      let messageRecurringEditable = true;

      //console.log("FECHA EN UTC ", new Date(response.data.DeliveryTime));
      const delivery = new Date(response.data.DeliveryTime);
      //delivery.setHours(delivery.getHours() + (this.controlTimezone * -1));

      const currentTime = new Date();
      //currentTime.setHours(currentTime.getHours() + (this.controlTimezone));

      //console.log("AHORA EN UTC ", currentTime);

      //let oneDay=1000*60*60*24;
      //let daysBeforeDelivery = ( ((new Date(response.data.DeliveryTime)).getTime())- (new Date().getTime())) / oneDay;
      //let minsBeforeDelivery = daysBeforeDelivery * 1440;
      const diff = Math.abs(new Date(response.data.DeliveryTime) - currentTime);
      const minsBeforeDelivery = Math.floor(diff / 1000 / 60);

      let deliveryPassed = false;

      //console.log("DAYS", diff, "MINS", minsBeforeDelivery);
      if (new Date(response.data.DeliveryTime) > currentTime && minsBeforeDelivery > 5) {
        messageEditable = true;
        console.log('----------> 1');
      } else {
        deliveryPassed = true;
        console.log('----------> 1.5');
      }

      if ((deliveryPassed || response.data.messagesAlreadyDelivered > 0) && !response.data.IsRecurring) {
        messageEditable = false;
        console.log('----------> 2  No Editable At All');
      }

      if ((deliveryPassed || response.data.messagesAlreadyDelivered > 0) && response.data.IsRecurring) {
        messageRecurringEditable = false;
        console.log('----------> 3 No Editable Recurring Msg');
        //messageModalNotEditableLabel = 'Message has already been sent and cannot be edited/deleted.';
      }

      // if (response.data.IsRecurring){
      //     messageModalNotEditableLabel = 'This Message has recurring deliveries, so it cannot be modified.';
      // }

      if (response.data.IsRecurring && response.data.IsRecurringStopped) {
        isRecurring = false;
        //messageModalNotEditableLabel = 'This Message is a Recurring Stopped Message, so it cannot be modified.';
      }

      if (!messageEditable) {
        messageRecurringEditable = false;
      }

      if (!messageRecurringEditable) {
        this.setState({
          deleteMessageBtnStyle: ' nucleus-submit-btn-disabled',
          messageModalNotDeleteView: '',
        });
      } else {
        let oldFileName = null;
        let oldFileBucket = null;
        if (response.data.PdfFileUrl != null && response.data.PdfFileUrl != '') {
          console.log('Previous PdfFile url', response.data.PdfFileUrl);
          oldFileName = response.data.PdfFileUrl.substring(response.data.PdfFileUrl.lastIndexOf('/') + 1);
          oldFileBucket = response.data.PdfFileUrl;
          oldFileBucket = oldFileBucket.replace('https://s3-us-west-2.amazonaws.com/nucleuscare-data/signatures/', '');
          oldFileBucket = oldFileBucket.replace(`/${oldFileName}`, '');
          console.log('oldFileName', oldFileName);
          console.log('oldFileBucket', oldFileBucket);
        }

        this.setState({
          deleteMessageBtnStyle: ' nucleus-submit-btn',
          messageModalNotDeleteView: ' hide',

          selectedMessageID: response.data.ID,

          signMessagePreviousFileName: oldFileName,
          signMessagePreviousFileBucket: oldFileBucket,
        });
      }
      $('#modalDeleteMessage').modal('open');
    } else {
      Message.show('Error getting message details.');
    }
  }

  handleCancelDeleteMessage() {
    $('#modalDeleteMessage').modal('close');
  }

  handleSubmitDeleteMessage() {
    console.log('handleSubmitDeleteMessage', this.state.selectedMessageID);

    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn-disabled',
    });

    MessagesStore.deleteMessage({
      MessageID: this.state.selectedMessageID,
    });
  }

  onDeleteMessageAction(response) {
    console.log('onDeleteMessageAction', response);

    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn ',
    });

    if (response.ok) {
      if (this.props.patientID) {
        CarePatientStore.getScheduledPatientMessages({
          PatientID: this.props.patientID,
        });
      } else {
        //TODO: REFRESH MESSAGES FOR ACCOUNT
        console.log('TODO: REFRESH MESSAGES');
        MessagesStore.refreshMessagesCenter();
        $('#modalDeleteMessage').modal('close');
      }
      Message.show('Message deleted');

      if (this.state.signMessagePreviousFileName != null) {
        MessagesStore.DeleteSignatureMessagePdfFile({
          PdfFileName: this.state.signMessagePreviousFileName,
          SignaturesBucketName: this.state.signMessagePreviousFileBucket,
        });
      }
    } else {
      Message.show('Error deleting message');
    }
  }

  onOpenNotEditableMessageAction() {
    $('#modalNotEditableMessage').modal('open');
  }

  handleCloseNotEditableModal() {
    $('#modalNotEditableMessage').modal('close');
  }

  onHandleDeleteMessageAction({ PatientMessageID }) {
    console.log('OPEN DELETE MESSAGE', PatientMessageID);

    this.setState({
      selectedPatientMessageID: PatientMessageID,
    });
    $('#modalDeletePatientMessage').modal('open');
  }

  handleCancelDelete() {
    $('#modalDeletePatientMessage').modal('close');
  }

  onHandleNotDeleteMessageAction() {
    $('#modalNotDeleteMessage').modal('open');
  }

  handleCloseNotDeleteModal() {
    $('#modalNotDeleteMessage').modal('close');
  }

  handleResumeUpdateMessage() {
    console.log('handleResumeUpdateMessage RESUME UPDATE!!');

    // if (this.state.newMessageCategoryID == null){
    //     this.setState({
    //         messageCategoryError : ' '
    //     });
    //     console.log("Msg-> 1");
    //     return;
    // }

    if (!(this.refs.textMessage.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
      });
      console.log('Msg-> 2');
      return;
    }

    console.log('Mensaje!', this.refs.textMessage.value.length);
    if (this.refs.selectMessageType.value == '1' && this.refs.textMessage.value.length > 60) {
      this.refs.textMessage.focus();

      this.setState({
        messageBodyLengthError: ' ',
        messageBodyLengthErrorChars: '60',
      });
      console.log('Msg-> 2.5');
      return;
    } else if ((this.refs.selectMessageType.value == '2' || this.refs.selectMessageType.value == '3') && this.refs.textMessage.value.length > 60) {
      this.refs.textMessage.focus();
      this.setState({
        messageBodyLengthError: ' ',
        messageBodyLengthErrorChars: '60',
      });
      console.log('Msg-> 2.6');
      return;
    }

    if (this.refs.selectMessageType.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      console.log('Msg-> 3');
      return;
    }

    if (this.state.messageDateTime && this.state.messageDateTime.toString().length == 0) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      console.log('Msg-> 4');
      return;
    }
    /*
        if (this.state.messageDeliveryImmediate &&  new Date(this.refs.messageDateTime.value) < new Date()){
            this.setState({
                messageDeliveryDateError : ' '
            });
            console.log("Msg-> 4");
            return;
        }
        */

    if (this.refs.selectMessageType.value == '2' && this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault) {
      let optionsIncluded = false;
      if (this.refs.txtSingleOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Msg-> 5');
        return;
      }

      let maxTextLimit = false;
      if (this.refs.txtSingleOption1.value.length > 0 && this.refs.txtSingleOption1.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption2.value.length > 0 && this.refs.txtSingleOption2.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption3.value.length > 0 && this.refs.txtSingleOption3.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption4.value.length > 0 && this.refs.txtSingleOption4.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption5.value.length > 0 && this.refs.txtSingleOption5.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtSingleOption6.value.length > 0 && this.refs.txtSingleOption6.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Msg-> 5.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }
    if (this.refs.selectMessageType.value == '3') {
      let optionsIncluded = false;
      if (this.refs.txtMultiOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Msg-> 6');
        return;
      }

      let maxTextLimit = false;
      if (this.refs.txtMultiOption1.value.length > 0 && this.refs.txtMultiOption1.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption2.value.length > 0 && this.refs.txtMultiOption2.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption3.value.length > 0 && this.refs.txtMultiOption3.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption4.value.length > 0 && this.refs.txtMultiOption4.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption5.value.length > 0 && this.refs.txtMultiOption5.value.length > 15) {
        maxTextLimit = true;
      } else if (this.refs.txtMultiOption6.value.length > 0 && this.refs.txtMultiOption6.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Msg-> 6.5');
        return;
      }
    }

    if (this.refs.selectMessageType.value == '4') {
      let errorEmptyLink = false;
      if (this.refs.txtLinkMessageUrl.value == '') {
        this.setState({
          messageLinkError: ' ',
        });
        console.log('Msg-> 7.5');
        errorEmptyLink = true;
      }
      if (this.refs.txtLinkMessageButton && this.refs.txtLinkMessageButton.value == '') {
        this.setState({
          messageButtonError: ' ',
        });
        console.log('Msg-> 7.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }

    if (this.refs.selectMessageType.value == '6') {
      let errorEmptyLink = false;
      if (this.refs.txtSignFileUrl.value == '') {
        this.setState({
          messageSignError: ' ',
        });
        console.log('Msg-> 8.5');
        errorEmptyLink = true;
      }
      if (this.refs.txtSignMessageButton && this.refs.txtSignMessageButton.value == '') {
        this.setState({
          messageSignButtonError: ' ',
        });
        console.log('Msg-> 8.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }

    let namesArray = ' All ' + AuthStore.getPatientLabelPlural();
    if (this.state.selectedMessagePatientsIDs != null || this.state.selectedMessageGroupsIDs != null) {
      const chipsObjectValue = $('.chips-initial').material_chip('data');
      namesArray = '';
      chipsObjectValue.forEach((value, key) => {
        if (namesArray.length > 0) {
          namesArray = namesArray + ', ';
        }
        namesArray = namesArray + value.tag;
      });
    }

    this.setState({
      messageDeliverTo: namesArray,
    });

    if (!this.state.messageBroadcast && namesArray == '') {
      this.setState({
        messagePatientsChipsVisible: '',
      });
      console.log('Msg-> 6.9');
      return;
    }
    //

    if (this.state.messageRepeat && !(this.refs.txtRepeatNumber.value.length > 0)) {
      this.setState({
        messageRepeatEveryError: ' ',
      });
      console.log('Msg-> 7');
      return;
    }

    if (this.refs.selectMessageRecurring.value == 'Week') {
      if (
        !this.refs.checkSunday.checked &&
        !this.refs.checkMonday.checked &&
        !this.refs.checkTuesday.checked &&
        !this.refs.checkWednesday.checked &&
        !this.refs.checkThursday.checked &&
        !this.refs.checkFriday.checked &&
        !this.refs.checkSaturday.checked
      ) {
        this.setState({
          messageRepeatDaysError: ' ',
        });
        console.log('Msg-> 7.5');
        return;
      }
    }

    if (this.state.messageRepeatEnds && new Date(this.state.messageEndDateTime) < new Date(this.state.messageDateTime)) {
      this.setState({
        messageEndDateError: ' ',
      });
      console.log('Msg-> 8');
      return;
    }
    if (this.state.messageRepeat) {
      let repeatString = ' that will repeat every ' + this.refs.txtRepeatNumber.value + ' ' + this.refs.selectMessageRecurring.value;

      if (this.refs.txtRepeatNumber.value > 1) {
        repeatString += 's';
      }

      if (this.refs.selectMessageRecurring.value == 'Week') {
        const daysArray = [];
        if (this.refs.checkSunday.checked) {
          daysArray.push('Sunday');
        }
        if (this.refs.checkMonday.checked) {
          daysArray.push('Monday');
        }
        if (this.refs.checkTuesday.checked) {
          daysArray.push('Tuesday');
        }
        if (this.refs.checkWednesday.checked) {
          daysArray.push('Wednesday');
        }
        if (this.refs.checkThursday.checked) {
          daysArray.push('Thursday');
        }
        if (this.refs.checkFriday.checked) {
          daysArray.push('Friday');
        }
        if (this.refs.checkSaturday.checked) {
          daysArray.push('Saturday');
        }
        const days = daysArray.join(', ');
        repeatString = repeatString + ' on ' + days;
      }

      let endRepeatString = '.';
      if (this.state.messageRepeatEnds) {
        endRepeatString = ', and the last delivery will be ' + this.state.messageEndDateTime + '.';
      }

      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The next delivery will be now' + endRepeatString,
          confirmMessageTextLine3: 'Once sent you will not be able to edit the message.',
        });
      } else {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The next delivery will be ' + this.state.messageDateTime + endRepeatString,
          confirmMessageTextLine3: '',
        });
      }
    } else {
      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: ' to be delivered now.',
          confirmMessageTextLine2: 'Once sent you will not be able to edit the message.',
          confirmMessageTextLine3: '',
        });
      } else {
        this.setState({
          confirmMessageText: ' to be delivered ' + this.state.messageDateTime + '.',
          confirmMessageTextLine2: '',
          confirmMessageTextLine3: '',
        });
      }
    }

    if (this.refs.txtNoResponseTimeout.value == '' || this.refs.txtNoResponseTimeout.value < 0) {
      this.refs.txtNoResponseTimeout.value = 0;
    }
    console.log('messagePersistentState', this.state.messagePersistentState);
    if (!this.state.messagePersistentState && this.refs.txtExpirationTimeout) {
      if (this.refs.txtExpirationTimeout.value == '' || this.refs.txtExpirationTimeout.value < 0) {
        this.refs.txtExpirationTimeout.value = 1;
      }
    }

    if (this.refs.selectMessageType.value == '4') {
      const linkUrl = this.validateUrlLink(this.refs.txtLinkMessageUrl.value);
      this.refs.txtLinkMessageUrl.value = linkUrl;
    }
    if (this.refs.selectMessageType.value == '6') {
      const linkUrl = this.refs.txtSignFileUrl.value;
      this.refs.txtSignFileUrl.value = linkUrl;
    }

    $('#modalResumeUpdateMessage').modal('open');

    /*
        this.setState({
            messageDeliverTo : namesArray,
            messageDeliveryAt : this.refs.messageDateTime.value
        });

        $('#modalResumeSaveMessage').modal('open');
        */
  }

  handleCancelResumeUpdateMessageModal() {
    $('#modalResumeUpdateMessage').modal('close');
  }

  handleUpdateMessage() {
    console.log('handleUpdateMessage UPDATE!!');
    /*
        this.setState({
            messageDeliverTo : namesArray,
            messageDeliveryAt : this.refs.messageDateTime.value
        });

        $('#modalResumeSaveMessage').modal('open');
        */

    let patientsIDsArray = null;
    let groupsIDsArray = null;
    if (this.props.patientID != null) {
      this.setState({
        selectedMessagePatientsIDs: this.props.patientID,
      });
    } else {
      if (this.state.selectedMessagePatientsIDs != null || this.state.selectedMessageGroupsIDs != null) {
        const chipsObjectValue = $('.chips-initial').material_chip('data');
        console.log('handleSaveMessage patients Chips', chipsObjectValue);
        patientsIDsArray = '';
        groupsIDsArray = '';
        chipsObjectValue.forEach((value, key) => {
          let patientID = null;
          patientID = this.state.messagePatientsList[value.tag];

          if (this.state.messageAutocompletePatientsList[value.tag]) {
            if (patientsIDsArray.length > 0) {
              patientsIDsArray = patientsIDsArray + ',';
            }
            patientsIDsArray = patientsIDsArray + patientID;
          }
          if (this.state.messageAutocompleteGroupsList[value.tag]) {
            if (groupsIDsArray.length > 0) {
              groupsIDsArray = groupsIDsArray + ',';
            }
            groupsIDsArray = groupsIDsArray + patientID;
          }
        });
        console.log('Patients IDS', patientsIDsArray);
        console.log('Groups IDS', groupsIDsArray);
        this.setState({
          selectedMessagePatientsIDs: patientsIDsArray,
          selectedMessageGroupsIDs: groupsIDsArray,
        });
      } else {
        console.log('All patients', patientsIDsArray);
      }
    }

    let messageOptionsArray = null;
    let messageTemplateID = '';
    if (this.refs.selectMessageType.value == '2' && this.refs.selectMessageTemplate.value == this.state.messageTemplateDefault) {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption1.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption2.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption3.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption4.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption5.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtSingleOption6.value);
    } else if (this.refs.selectMessageType.value == '2' && this.refs.selectMessageTemplate.value != this.state.messageTemplateDefault) {
      messageTemplateID = this.refs.selectMessageTemplate.value;
    } else if (this.refs.selectMessageType.value == '3') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption1.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption2.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption3.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption4.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption5.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.refs.txtMultiOption6.value);
    }
    console.log('Les Options sont', messageOptionsArray);

    let responseAlertsArray = null;
    if (this.state.responseAlertCustomVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption1of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption2of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption3of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption4of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption5of6.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption6of6.checked;
    } else if (this.state.responseAlertFacesVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption1of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption2of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption3of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption4of5.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption5of5.checked;
    } else if (this.state.responseAlertYesNoVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption1of2.checked + ',';
      responseAlertsArray = responseAlertsArray + this.refs.checkOption2of2.checked;
    }

    const deliveryDateTime = new Date(this.state.messageDateTime);
    //deliveryDateTime.setHours(deliveryDateTime.getHours() + this.controlTimezone);
    //this.refs.messageDateTime.value  = deliveryDateTime.toLocaleDateString('en-US', this.dateOptions) + " " + deliveryDateTime.toLocaleTimeString('en-US', this.timeOptions);
    let deliveryUTCTime =
      deliveryDateTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + deliveryDateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

    let sendNow = false;
    if (this.state.messageDeliveryImmediate) {
      sendNow = true;
      let currentTime = new Date();
      currentTime = currentTime.getTime() + 1 * 60000;
      currentTime = new Date(currentTime);
      //currentTime.setHours(currentTime.getHours() + this.controlTimezone);
      this.state.messageDateTime = currentTime; // currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
      deliveryUTCTime =
        currentTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }

    let endRepeatDate = '';
    if (this.state.messageRepeatEnds) {
      endRepeatDate = this.state.messageEndDateTime;
    }

    console.log('Repeat Ends :', this.state.messageRepeatEnds);
    console.log('Repeat Ends ON:', endRepeatDate);

    //$('#modalResumeUpdateMessage').modal('close');

    let biometricType = null;
    if (this.refs.selectMessageType.value == '5') {
      // MessageType "5" : Biometrics
      biometricType = this.state.selectedBiometricTypeID;
    }

    if (this.props.patientID != null) {
      patientsIDsArray = this.props.patientID;
    }

    const isLinkMessage = this.refs.selectMessageType.value == '4';
    const isSignMessage = this.refs.selectMessageType.value == '6';
    let linkMessageButtonText = '';
    try {
      linkMessageButtonText = this.refs.txtLinkMessageButton.value;
      if (this.refs.selectMessageType.value == '6') {
        linkMessageButtonText = this.refs.txtSignMessageButton.value;
      }
    } catch (error) {
      console.warn('Handled error setting this.refs.txtLinkMessageButton.value');
    }

    const linkUrl = this.refs.txtLinkMessageUrl.value;
    const fileUrl = this.refs.txtSignFileUrl.value;

    console.log('UPDATE...');
    console.log('PatientMessageID :', this.state.selectedMessageID);
    console.log('Message :', this.refs.textMessage.value);
    console.log('SendToAll :', this.state.messageBroadcast);
    console.log('DeliveryTime :', deliveryUTCTime);
    //console.log("MessageCategoryID :", this.refs.selectMessageCategory.value );
    console.log('NoResponseTimeout: ', this.refs.txtNoResponseTimeout.value);
    console.log('IsRecurring :', this.state.messageRepeat);
    console.log('RepeatEvery :', this.refs.txtRepeatNumber.value);
    console.log('RepeatType :', this.refs.selectMessageRecurring.value);
    console.log('RepeatOnSunday :', this.refs.checkSunday.checked);
    console.log('RepeatOnMonday :', this.refs.checkMonday.checked);
    console.log('RepeatOnTuesday :', this.refs.checkTuesday.checked);
    console.log('RepeatOnWednesday :', this.refs.checkWednesday.checked);
    console.log('RepeatOnThursday :', this.refs.checkThursday.checked);
    console.log('RepeatOnFriday: ', this.refs.checkFriday.checked);
    console.log('RepeatOnSaturday: ', this.refs.checkSaturday.checked);
    console.log('EndDate :', endRepeatDate);
    console.log('IsSingleOptionMessage :', this.state.newMessageSingleChoice);
    console.log('IsMultipleOptionMessage :', this.state.newMessageMultipleSelection);
    console.log('MessageOptionsTexts :', messageOptionsArray);
    console.log('PatientsIDs :', patientsIDsArray);
    console.log('GroupsIDs :', groupsIDsArray);
    console.log('MessageOptionsTemplateID :', messageTemplateID);

    console.log('IsLinkMessage :', isLinkMessage);
    console.log('LinkMessageUrl:', linkUrl);
    console.log('LinkMessageOpen:', this.state.messageLinkOpen);
    console.log('LinkMessageButton:', linkMessageButtonText);

    console.log('sendNow :', sendNow);
    console.log('BiometricTypeID', biometricType);
    const editMessage = this.state.canSendAllPatients != 'disabled';
    console.log('MessageEditable', editMessage);
    const messagePersistent = this.state.messagePersistentState;
    console.log('this.controlTimezone', this.controlTimezone);

    let messageExpirationTime = 0;
    if (!this.state.messagePersistentState) {
      if (this.refs.txtExpirationTimeout.value == '' || this.refs.txtExpirationTimeout.value < 0) {
        this.refs.txtExpirationTimeout.value = 1;
      }
      messageExpirationTime = this.refs.txtExpirationTimeout.value;
    }

    const updateMessageData = {
      AccountID: AuthStore.getUserAccountID(),
      MessageID: this.state.selectedMessageID,
      Message: this.refs.textMessage.value,
      SendToAll: this.state.messageBroadcast,
      DeliveryTime: deliveryUTCTime,
      MessageCategoryID: this.state.messageMessageCategoryID, //this.state.newMessageCategoryID,
      IsPersistent: this.state.messagePersistentState,
      TtLHours: Number(messageExpirationTime),
      IsRecurring: this.state.messageRepeat,
      RepeatEvery: Number(this.refs.txtRepeatNumber.value),
      RepeatType: this.refs.selectMessageRecurring.value,
      RepeatOnSunday: this.refs.checkSunday.checked,
      RepeatOnMonday: this.refs.checkMonday.checked,
      RepeatOnTuesday: this.refs.checkTuesday.checked,
      RepeatOnWednesday: this.refs.checkWednesday.checked,
      RepeatOnThursday: this.refs.checkThursday.checked,
      RepeatOnFriday: this.refs.checkFriday.checked,
      RepeatOnSaturday: this.refs.checkSaturday.checked,
      EndDate: endRepeatDate,
      IsSingleOptionMessage: this.state.newMessageSingleChoice,
      IsMultipleOptionMessage: this.state.newMessageMultipleSelection,
      MessageOptionsTexts: messageOptionsArray,
      PatientsIDs: patientsIDsArray,
      GroupsIDs: groupsIDsArray,
      NoResponseTimeout: Number(this.refs.txtNoResponseTimeout.value),
      MessageOptionsTemplateID: messageTemplateID,
      BiometricTypeID: biometricType,
      MessageOptionsAlerts: responseAlertsArray,
      IsLinkMessage: isLinkMessage,
      LinkMessageUrl: linkUrl,
      LinkMessageShouldOpen: this.state.messageLinkOpen,
      LinkMessageButton: linkMessageButtonText,
      IsSignatureMessage: isSignMessage,
      SendNow: sendNow,
      EditMessage: editMessage,
    };
    console.log('UPDATE MESSAGE DATA', updateMessageData);

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn-disabled ',
      savingMessage: true,
      savingMessageText: 'Updating message...',
    });

    MessagesStore.updateMessageByUser(updateMessageData);
  }

  onUpdateMessageByUserAction(response) {
    console.log('onUpdateMessageByUserAction', response);

    if (response.ok) {
      if (this.state.savingMessage && response.ID && this.state.selectedPdfFile != null) {
        MessagesStore.UploadSignatureMessagePdfFile({
          MessageID: response.ID,
          PdfFileName: this.state.selectedPdfFileName,
          File: this.state.selectedPdfFile,
        });

        if (this.state.signMessagePreviousFileName != null) {
          MessagesStore.DeleteSignatureMessagePdfFile({
            PdfFileName: this.state.signMessagePreviousFileName,
            SignaturesBucketName: this.state.signMessagePreviousFileBucket,
          });
        }

        this.setState({
          savingMessageText: 'Saving message file...',
          selectedPdfFile: null,
        });
      } else {
        $('#modalResumeUpdateMessage').modal('close');
        this.setState({
          editMessageBtnStyle: ' nucleus-submit-btn ',
          savingMessage: false,
          savingMessageText: 'Message updated.',
        });
        if (this.props.patientID) {
          CarePatientStore.getScheduledPatientMessages({
            PatientID: this.props.patientID,
          });
        } else {
          //TODO: REFRESH MESSAGES
          console.log('TODO: REFRESH MESSAGES');
          MessagesStore.refreshMessagesCenter();
        }

        Message.show('Message updated');
        this.handleCloseNewMessageModal();
      }
    } else {
      $('#modalResumeUpdateMessage').modal('close');
      this.setState({
        editMessageBtnStyle: ' nucleus-submit-btn ',
        savingMessage: false,
      });
      Message.show('Error updating message');
    }
  }

  onUpdateScheduledMessageAction(response) {
    console.log('onUpdateScheduledMessageAction', response);
    alert('Message Updated'); // TODO!

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn ',
    });

    if (response.ok) {
      if (this.state.savingMessage && response.ID && this.state.selectedPdfFile != null) {
        MessagesStore.UploadSignatureMessagePdfFile({
          MessageID: response.ID,
          PdfFileName: this.state.selectedPdfFileName,
          File: this.state.selectedPdfFile,
        });
        this.setState({
          savingMessageText: 'Saving message file...',
          selectedPdfFile: null,
        });
      } else {
        if (this.props.patientID) {
          CarePatientStore.getScheduledPatientMessages({
            PatientID: this.props.patientID,
          });
        } else {
          //TODO: REFRESH MESSAGES FOR ACCOUNT
          console.log('TODO: REFRESH MESSAGES');
          MessagesStore.refreshMessagesCenter();
        }

        Message.show('Message updated');
        $('#modalNewMessage').modal('close');
      }
    } else {
      Message.show('Error updating message');
    }
  }

  // Listen to TODO

  handleSubmitDeletePatientMessage() {
    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn-disabled',
    });

    MessagesStore.deletePatientMessage({
      PatientMessageID: this.state.selectedPatientMessageID,
    });
  }

  onDeletePatientMessageAction(response) {
    console.log('onDeletePatientMessageAction', response);

    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn ',
    });

    if (response.ok) {
      if (this.props.patientID) {
        CarePatientStore.getScheduledPatientMessages({
          PatientID: this.props.patientID,
        });
      } else {
        //TODO: REFRESH MESSAGES FOR ACCOUNT
        console.log('TODO: REFRESH MESSAGES');
        MessagesStore.refreshMessagesCenter();
      }
      Message.show('Message deleted');
      $('#modalDeletePatientMessage').modal('close');
    } else {
      Message.show('Error updating message');
    }
  }

  validateUrlLink(url) {
    let linkUrl = url;

    if (linkUrl.indexOf('https://') == 0) {
      //console.log("Todo bien!!");
    } else if (linkUrl.indexOf('http://') == 0) {
      linkUrl = linkUrl.replace('http://', 'https://');
    } else if (linkUrl.search('www') > -1) {
      const v = linkUrl.search('www');
      linkUrl = linkUrl.substring(v + 3);
      linkUrl = 'https://www' + linkUrl;
    } else {
      linkUrl = 'https://www.' + linkUrl;
    }
    return linkUrl;
  }

  render() {
    const voiceMessageEnabledValue = this.state.enableVoiceMessage ? 'checked' : '';

    // DEPRECATED -> Jan 2023, Messages Categories not needed anymore; CARE-10241 Signature Messages
    // let messageCategoriesList = [];
    // if (this.state.messageCategories != null && this.state.messageCategories.length){
    //     this.state.messageCategories.map((category, key) => {
    //         if (category.Category == 'Family'){
    //             return;
    //         }
    //         if (category.Category != 'Biometrics' || this.state.enableBiometrics){
    //             messageCategoriesList.push(<option key={category.ID} value={category.ID} >{category.Category}</option>);
    //         }
    //     });
    // }

    const messageTemplatesList = [];
    if (this.state.messageTemplates != null && this.state.messageTemplates.length) {
      this.state.messageTemplates.map((template, key) => {
        messageTemplatesList.push(
          <option key={template.ID} value={template.ID}>
            {template.Name}
          </option>,
        );
      });
    }

    const messageBiometricTypesList = [];
    if (this.state.messageBiometricTypes != null && this.state.messageBiometricTypes.length) {
      this.state.messageBiometricTypes.map((biometricType, key) => {
        messageBiometricTypesList.push(
          <option key={biometricType.ID} value={biometricType.ID}>
            {biometricType.Type}
          </option>,
        );
      });
    }

    let disabledFont = '';
    if (this.state.messageEditableRecurring == 'disabled') {
      disabledFont = ' placeholder-gray';
    }
    const singleChoiceTemplate = this.state.newMessageTemplate ? this.refs.selectMessageTemplate.selectedOptions[0].text : '';
    let buttonsVisibility = ' hide';
    let iconsVisibility = ' hide';
    let thumbsVisibility = ' hide';
    let emojisVisibility = ' hide';

    if (singleChoiceTemplate == 'Yes / No - Text Buttons') {
      buttonsVisibility = ' ';
    } else if (singleChoiceTemplate == 'Yes / No - Icon Buttons') {
      iconsVisibility = ' ';
    } else if (singleChoiceTemplate == 'Thumbs Up / Down') {
      thumbsVisibility = ' ';
    } else if (singleChoiceTemplate == 'Feeling Emojis') {
      emojisVisibility = ' ';
    }

    let deliveryNowImg = '/img/radio_button_on.png';
    let deliveryScheduledImg = '/img/radio_button_off.png';

    if (!this.state.messageDeliveryImmediate) {
      deliveryScheduledImg = '/img/radio_button_on.png';
      deliveryNowImg = '/img/radio_button_off.png';
    }

    const deliveryDateTime = this.state.messageDeliveryImmediate ? ' hide' : '';

    let repeatNeverEndsImg = '/img/radio_button_on.png';
    let repeatEndDateImg = '/img/radio_button_off.png';

    if (this.state.messageRepeatEnds) {
      repeatNeverEndsImg = '/img/radio_button_off.png';
      repeatEndDateImg = '/img/radio_button_on.png';
    }

    //console.log("NucleusMessage RENDER", this.props.patientID);
    let patientMessagesEnabled = '';

    if (this.props.patientID != null && this.state.patientDevices < 1) {
      patientMessagesEnabled = ' hide';
    }

    return (
      <div className="row no-margin ">
        <div className="row padding-15">
          <div className="fixed-action-btn">
            <a
              class={'btn-floating btn-large waves-effect waves-light green tooltipped ' + patientMessagesEnabled}
              data-position="left"
              data-delay="50"
              data-tooltip="New Message"
              onClick={this.handleNewMessageModal}
            >
              <i className="material-icons">message</i>
            </a>
            &nbsp;
          </div>
          <br />
          <br />
        </div>

        <div id="modalNewMessage" className="modal modalNucleusMessage modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseNewMessageModal} />
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;{this.state.messageModalMode} Message</h3>
            <a class={'nucleus-font nucleus-font-small nucleus-link ' + this.state.messageModalModeShow} onClick={this.handleMessageModalMode}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class={this.state.messageModalOptionColor}> {this.state.messageModalOptionLabel}</span>
            </a>
            <a class={'nucleus-font nucleus-font-small ' + this.state.messageModalNotEditable}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="txt-red">{this.state.messageModalNotEditableLabel}</span>
            </a>
            <br />
            <br />
            <div class={' modallScrollable ' + this.state.messageModeEdit}>
              <div className="row">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Message Type</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <select ref="selectMessageType" className="browser-default nucleus-select" disabled={this.state.messageEditableRecurring} onChange={this.handleSelectMessageType}>
                    <option value="1" defaultValue>
                      Plain Text
                    </option>
                    <option value="2">Single Choice</option>
                    <option value="3">Multiple Selection</option>
                    {AuthStore.isLinkMessageEnabled() && <option value="4">Browser Link</option>}
                    {this.state.enableBiometrics && <option value="5">Biometrics</option>}
                    <option value="6">Signature</option>
                  </select>
                  <span class={'nucleus-form-error center-align ' + this.state.messageTypeError}>Please select a Type.</span>
                </div>
                <div className="col s2"></div>
              </div>

              {/* 
                        // DEPRECATED -> Jan 2023, Messages Categories not needed anymore; CARE-10241 Signature Messages
                        <div className="row">
                            <div className="col s4">
                                <span className="nucleus-labels"><b>Category</b></span>
                            </div>
                            <div className="col s6 no-margin">
                                <select 
                                    ref="selectMessageCategory" 
                                    defaultValue="" 
                                    className="browser-default nucleus-select" 
                                    disabled={this.state.messageEditableRecurring} 
                                    onChange={this.handleSelectMessageCategory}
                                >
                                    <option value="" disabled > Select Category </option>
                                    { messageCategoriesList }
                                </select>
                                <span class={ "nucleus-form-error center-align " + this.state.messageCategoryError }>Please select a Category.</span>
                            </div>
                            <div className="col s2">
                            </div>
                        </div> 
                        */}

              <div class={'row no-margin ' + this.state.regularMessageMode}>
                <span className="nucleus-labels">
                  <b>&nbsp;&nbsp;&nbsp;Message:</b>
                </span>
                <div className="input-field col s12 ">
                  <textarea
                    ref="textMessage"
                    class={'nucleus-textarea-medium no-margin-bottom ' + disabledFont}
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleMessageText}
                    maxLength="60"
                    placeholder="Type the message here..."
                  ></textarea>
                  <span class={'nucleus-font nucleus-font-14'}> {this.state.messageBodyChars}/60</span> &nbsp;&nbsp;&nbsp;
                  <span class={'nucleus-form-error center-align  ' + this.state.messageBodyError}>{this.state.messageBodyErrorText}</span>
                  <span class={'nucleus-form-error center-align  ' + this.state.messageBodyLengthError}>
                    Message body is too long, please keep it max {this.state.messageBodyLengthErrorChars} characters.
                  </span>
                  <br />
                  <div className="row no-margin"></div>
                </div>

                {this.refs.selectMessageType && this.refs.selectMessageType.value != '1' && <div className="nucleus-news-separator">&nbsp;</div>}

                <div class={'row ' + this.state.messageTemplateVisibility}>
                  <div className="col s4">
                    <span className="nucleus-labels">
                      <b>Response Display Type</b>
                    </span>
                  </div>
                  <div className="col s6 no-margin">
                    <select
                      ref="selectMessageTemplate"
                      defaultValue=""
                      className="browser-default nucleus-select"
                      disabled={this.state.messageEditableRecurring + this.state.messageTemplateEditable}
                      onChange={this.handleSelectSingleChoiceTemplate}
                    >
                      <option value="" disabled>
                        {' '}
                        Select Template{' '}
                      </option>
                      {messageTemplatesList}
                    </select>
                    <span class={'nucleus-form-error center-align ' + this.state.messageTemplateError}>Please select a Template.</span>
                  </div>
                  <div className="col s2"></div>
                </div>

                <div class={'row small-margin-bottom ' + this.state.linkMessageVisibility}>
                  <div className="row no-margin a-bit-lower">
                    <div className="col s2">
                      <span className="nucleus-labels">
                        <b>Link</b>
                      </span>
                    </div>
                    <div className="col s8 no-margin">
                      <input
                        ref="txtLinkMessageUrl"
                        type={'url'}
                        className="validate nucleus-textfield-100"
                        placeholder="https://www..."
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleMessageLinkText}
                      />
                      <span class={'nucleus-form-error center-align ' + this.state.messageLinkError}>Please insert the link.</span>
                    </div>
                    <div className="col s2"></div>
                  </div>
                  <div className="row no-margin a-bit-lower">
                    <div className="col s9">
                      <span className="nucleus-labels">
                        <b>Open link on browser on message arrival</b>
                      </span>
                    </div>
                    <div className="col s2 no-margin">
                      <div className="switch no-margin ">
                        <label className="no-margin">
                          <input type="checkbox" ref="checkMessageLinkOpen" defaultChecked="" onChange={this.handleMessageLinkOpen} />
                          <span className="lever nucleus-check"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col s1"></div>
                  </div>
                  {!this.state.messageLinkOpen && (
                    <div className="row no-margin a-bit-lower">
                      <div className="col s3">
                        <span className="nucleus-labels">
                          <b>Button Text</b>
                        </span>
                      </div>
                      <div className="col s7 no-margin">
                        <input
                          ref="txtLinkMessageButton"
                          type={'text'}
                          className="validate nucleus-textfield-100"
                          placeholder="Press here"
                          disabled={this.state.messageEditableRecurring}
                          onChange={this.handleMessageButtonText}
                        />
                        <span class={'nucleus-form-error center-align ' + this.state.messageButtonError}>Please insert the button text.</span>
                      </div>
                      <div className="col s2"></div>
                    </div>
                  )}
                  {this.state.messageLinkOpen && (
                    <div className="row no-margin a-bit-lower">
                      <div className="col s12"></div>
                    </div>
                  )}
                </div>

                <div class={'row small-margin-bottom ' + this.state.signMessageVisibility}>
                  <div className="row no-margin a-bit-lower">
                    <div className="col s3">
                      <span className="nucleus-labels">
                        <b>Pdf file</b>
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        class={'txt-white nucleus-font-small ' + this.state.selectFileBtnStyle}
                        onClick={() => {
                          document.getElementById('selectFile').click();
                        }}
                      >
                        Select
                      </a>
                    </div>
                    <div className="col s7 no-margin">
                      <input
                        id="selectFile"
                        ref="selectFile"
                        type="file"
                        data-multiple-caption={'Select'}
                        multiple={false}
                        onChange={this.handleSelectedFile}
                        accept={'application/pdf'}
                        style={{ display: 'none' }}
                      ></input>

                      <input
                        ref="txtSignFileUrl"
                        type={'url'}
                        className="validate nucleus-textfield-100"
                        placeholder="Pdf file name"
                        disabled={true}
                        onChange={this.handleMessageSignText}
                      />
                      <span class={'nucleus-form-error center-align ' + this.state.messageSignError}>Please select pdf file.</span>
                    </div>
                    <div className="col s2">
                      {this.state.selectedPdfFile != null && (
                        <a class={'txt-white nucleus-font-small ' + ' nucleus-submit-btn-mini'} onClick={this.handleSelectedPdfFilePreview}>
                          View
                        </a>
                      )}
                      {this.state.signMessageFileUrl != null && (
                        <a class={'txt-white nucleus-font-small ' + ' nucleus-submit-btn-mini'} onClick={this.handleSelectedPdfFileView}>
                          View
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="row no-margin a-bit-lower">
                    <div className="col s3">
                      <span className="nucleus-labels">
                        <b>Button Text</b>
                      </span>
                    </div>
                    <div className="col s7 no-margin">
                      <input
                        ref="txtSignMessageButton"
                        type={'text'}
                        className="validate nucleus-textfield-100"
                        placeholder="Press to read and sign"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleMessageSignButtonText}
                      />
                      <span class={'nucleus-form-error center-align ' + this.state.messageSignButtonError}>Please insert the button text.</span>
                    </div>
                    <div className="col s2"></div>
                  </div>
                </div>

                <div class={'row no-margin ' + this.state.singleChoiceVisibility}>
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>Single Choice Options</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">
                      The message sent to clients will include the options you fill out below. The client will select one option. (Examples: Yes/No, Food Choice, Activity Choice).
                      <br />
                    </span>
                    <span className="nucleus-font nucleus-font-14">Enter the appropriate text below. Leave blank if there are less options.</span>
                    <br />
                  </div>
                  <div className="row a-bit-lower">
                    <div className="col s6">
                      <span className="nucleus-labels a-bit-right">
                        <b>1</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtSingleOption1"
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 1 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO1Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>2</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtSingleOption2"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 2 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO2Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>3</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtSingleOption3"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 3 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO3Chars}/15</span>
                      <br />
                      <span class={'nucleus-form-error center-align ' + this.state.messageOptionsError}>Enter at least one option text please.</span>
                      <span class={'nucleus-form-error center-align ' + this.state.messageOptionsMaxError}>Option Text Max length is 15 chars.</span>
                    </div>
                    <div className="col s6">
                      <span className="nucleus-labels">
                        <b>4</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtSingleOption4"
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 4 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO4Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>5</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtSingleOption5"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 5 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO5Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>6</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtSingleOption6"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 6 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO6Chars}/15</span>
                    </div>
                  </div>
                </div>

                <div class={'row no-margin ' + this.state.multipleOptionVisibility}>
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>Multiple Options</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">
                      The message sent to clients will include the options you fill out below. The client will select multiple options. (Examples: Food Choice, Activity Choice).
                      <br />
                    </span>
                    <span className="nucleus-font nucleus-font-14">Enter the appropriate text below. Leave blank if there are less options.</span>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="row top-separated">
                    <div className="col s6">
                      <span className="nucleus-labels a-bit-right">
                        <b>1</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtMultiOption1"
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 1 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO1Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>2</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtMultiOption2"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 2 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO2Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>3</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtMultiOption3"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 3 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO3Chars}/15</span>
                      <br />
                      <span class={'nucleus-form-error center-align ' + this.state.messageOptionsError}>Enter at least one option text please.</span>
                      <span class={'nucleus-form-error center-align ' + this.state.messageOptionsMaxError}>Option Text Max length is 15 chars.</span>
                    </div>
                    <div className="col s6">
                      <span className="nucleus-labels">
                        <b>4</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtMultiOption4"
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 4 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO4Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>5</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtMultiOption5"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 5 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO5Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>6</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref="txtMultiOption6"
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 6 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span class={'nucleus-font nucleus-font-14'}> {this.state.messageO6Chars}/15</span>
                    </div>
                  </div>
                </div>

                <div class={'row no-margin ' + this.state.messageTemplateDetailsVisibiity}>
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>The message screen will display the message text, and the following option to choose from.</b>
                    </span>
                    <br />
                  </div>
                  <div class={'row top-separated ' + buttonsVisibility}>
                    <div className="col s3"></div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/btn_yes.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Yes</b>
                      </span>
                    </div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/btn_no.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>No</b>
                      </span>
                    </div>
                    <div className="col s3"></div>
                  </div>

                  <div class={'row top-separated ' + thumbsVisibility}>
                    <div className="col s3"></div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/thumb_up.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Thumbs Up</b>
                      </span>
                    </div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/thumb_down.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Thumbs Down</b>
                      </span>
                    </div>
                    <div className="col s3"></div>
                  </div>

                  <div class={'row top-separated ' + iconsVisibility}>
                    <div className="col s3"></div>
                    <div className="input-field col s3 center-align">
                      &nbsp;
                      <img src="/img/accept_btn.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Yes</b>
                      </span>
                    </div>
                    <div className="input-field col s3 center-align">
                      &nbsp;
                      <img src="/img/decline_btn.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>No</b>
                      </span>
                    </div>
                    <div className="col s3"></div>
                  </div>

                  <div class={'row top-separated ' + emojisVisibility}>
                    <div className="input-field col s1">
                      <span className="nucleus-labels">&nbsp;</span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face1.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Great</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face2.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>Good</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face3.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>So So</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face4.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>Bad</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face5.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>Awful</b>
                      </span>
                    </div>
                    <div className="input-field col s1">
                      <span className="nucleus-labels">&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class={'row no-margin ' + this.state.biometricMessageMode}>
                <div className="row">
                  <div className="col s4">
                    <span className="nucleus-labels">
                      <b>Biometric Message Type</b>
                    </span>
                  </div>
                  <div className="col s6 no-margin">
                    <select
                      ref="selectBiometricMessageType"
                      className="browser-default nucleus-select"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleSelectBiometricMessageType}
                    >
                      {messageBiometricTypesList}
                    </select>
                    <span class={'nucleus-form-error center-align ' + this.state.messageTypeError}>Please select a Type.</span>
                  </div>
                  <div className="col s2"></div>
                </div>

                <div className="row">
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>The client will see the following message text:</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">&nbsp;{this.state.selectedBiometricTypeText}</span>
                    <br />
                    <span className="nucleus-labels">
                      <b>The client will be able to enter the following input field/s:</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">&nbsp;{this.state.selectedBiometricTypeInputText}</span>
                    <br />
                    <span className="nucleus-font nucleus-font-14">
                      <b>
                        Note: In order to receive alerts on the patients biometric you will need to configure each patient thresholds in the Patient screen under the Biometrics
                        Alerts Settings tab.
                        <br />
                      </b>
                    </span>
                  </div>
                </div>
              </div>

              <div class={'row no-margin ' + this.state.responseAlertCustomVisibility}>
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b>&nbsp;&nbsp;&nbsp;Response Alert</b>
                  </span>{' '}
                  <br />
                  <span className="nucleus-font nucleus-font-14">
                    &nbsp;&nbsp;&nbsp;Check the value(s) that will trigger an alert email if selected by {AuthStore.getPatientLabel().toLowerCase()}
                  </span>
                </div>
                <div className="row no-margin">
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>1</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>2</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>3</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>4</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>5</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>6</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
                <div className="row no-margin">
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref="checkOption1of6" id="checkOption1of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption1of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref="checkOption2of6" id="checkOption2of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption2of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref="checkOption3of6" id="checkOption3of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption3of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref="checkOption4of6" id="checkOption4of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption4of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref="checkOption5of6" id="checkOption5of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption5of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref="checkOption6of6" id="checkOption6of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption6of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>

              <div class={'row no-margin ' + this.state.responseAlertFacesVisibility}>
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b>&nbsp;&nbsp;&nbsp;Response Alert</b>
                  </span>{' '}
                  <br />
                  <span className="nucleus-font nucleus-font-14">
                    &nbsp;&nbsp;&nbsp;Check the value(s) that will trigger an alert email if selected by {AuthStore.getPatientLabel().toLowerCase()}
                  </span>
                </div>

                <div className="row no-margin">
                  <div className="col s1 center-align"></div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Great</b>&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Good</b> &nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>So So</b>&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Bad</b> &nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Awful</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s1 center-align"></div>
                </div>
                <div className="row no-margin">
                  <div className="col s1 center-align"></div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref="checkOption1of5" id="checkOption1of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption1of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref="checkOption2of5" id="checkOption2of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption2of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref="checkOption3of5" id="checkOption3of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption3of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref="checkOption4of5" id="checkOption4of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption4of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref="checkOption5of5" id="checkOption5of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption5of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s1 center-align"></div>
                </div>
              </div>

              <div class={'row no-margin ' + this.state.responseAlertYesNoVisibility}>
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b>&nbsp;&nbsp;&nbsp;Response Alert</b>
                  </span>{' '}
                  <br />
                  <span className="nucleus-font nucleus-font-14">
                    &nbsp;&nbsp;&nbsp;Check the value(s) that will trigger an alert email if selected by {AuthStore.getPatientLabel().toLowerCase()}
                  </span>
                </div>

                <div className="row no-margin">
                  <div className="col s3 center-align"></div>
                  <div className="col s3 center-align">
                    <span className="nucleus-labels">
                      <b>{this.state.responseAlertYesLabel}</b>
                    </span>
                  </div>
                  <div className="col s3 center-align">
                    <span className="nucleus-labels">
                      <b>{this.state.responseAlertNoLabel}</b>{' '}
                    </span>
                  </div>
                  <div className="col s3 center-align"></div>
                </div>
                <div className="row no-margin">
                  <div className="col s3 center-align"></div>
                  <div className="col s3 center-align">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input ref="checkOption1of2" id="checkOption1of2" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption1of2" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s3 center-align">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input ref="checkOption2of2" id="checkOption2of2" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption2of2" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s3 center-align"></div>
                </div>
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>

              <div className="row small-margin-bottom">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Persistent</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <div className="switch no-margin ">
                    <label className="no-margin">
                      <input
                        type="checkbox"
                        ref="checkPersistentState"
                        value={this.state.messagePersistentState}
                        //disabled={this.state.messagePersistentState}
                        defaultChecked=""
                        onChange={this.handleMessagePersistentState}
                      />
                      <span className="lever nucleus-check"></span>
                    </label>
                  </div>
                </div>
                <div className="col s2"></div>
              </div>
              {!this.state.messagePersistentState && (
                <div className="row no-margin">
                  <div className="col s7">
                    <span className="nucleus-labels">
                      <b>Expiration Time (Hours)</b>
                    </span>
                  </div>
                  <div className="col s5 no-margin">
                    <input
                      ref="txtExpirationTimeout"
                      type="number"
                      className="validate nucleus-textfield textbox2 "
                      min="1"
                      step="1"
                      defaultValue="1"
                      placeholder="00"
                      disabled={this.state.messageEditableRecurring}
                    />
                  </div>
                </div>
              )}

              <div className="nucleus-news-separator">&nbsp;</div>

              <div className="row small-margin-bottom">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Send to All {AuthStore.getPatientLabelPlural()}</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <div className="switch no-margin ">
                    <label className="no-margin">
                      <input
                        type="checkbox"
                        ref="checkMessageAllPatients"
                        disabled={this.state.canSendAllPatients}
                        defaultChecked="checked"
                        onChange={this.handleMessageBroadcast}
                      />
                      <span className="lever nucleus-check"></span>
                    </label>
                  </div>
                </div>
                <div className="col s2"></div>
              </div>
              <div class={'row no-margin ' + this.state.selectPatientsVisibility}>
                <div className="input-field col s12 no-margin-top">
                  <span className="nucleus-font nucleus-font-14">
                    Start typing the {AuthStore.getPatientLabel()}’s/Group name and we will display all options. Simply select from the list and you will see the{' '}
                    {AuthStore.getPatientLabel()}'s/Group name in the section below.
                  </span>

                  <div ref="txtPatients" id="txtPatients" className="chips chips-initial no-margin nucleus-chips" disabled={this.state.messageEditableRecurring}></div>

                  <span class={'nucleus-form-error center-align ' + this.state.messagePatientsChipsVisible}>Please select at least one {AuthStore.getPatientLabel()}.</span>
                </div>
                <br />
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>
              <div className="row no-margin">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Send now</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <img src={deliveryNowImg} className="nucleus-icon-active" width="24" onClick={this.handleDeliveryImmediate} />
                </div>
                <div className="col s2"></div>
              </div>

              <div className="row no-margin">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Scheduled Delivery</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <img src={deliveryScheduledImg} className="nucleus-icon-active" width="24" onClick={this.handleDeliveryScheduled} />
                </div>
                <div className="col s2"></div>
              </div>

              <div class={'row ' + deliveryDateTime}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>{this.state.messageDeliveryDateTimeLabel}</b>
                  </span>
                </div>
                <div className="col s8 no-margin left-align">
                  {/* <input 
                                    id="messageDateTime" 
                                    ref="messageDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker2"  
                                    disabled={this.state.messageEditableRecurring} /> */}
                  <div style={{ width: 210, display: 'inline-flex' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        ref={this.messageDateTimeRef}
                        variant="dialog"
                        className="browser-default"
                        value={this.state.messageDateTime}
                        onChange={this.onMessageDateTimeChange}
                        onError={console.log}
                        onClose={() => {}}
                        //minDate={new Date("2018-01-01T00:00")}
                        format={this.datetimeFormat}
                        disabled={this.state.messageEditableRecurring}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <br />
                  <span class={'nucleus-form-error center-align ' + this.state.messageDeliveryDateError}>
                    Delivery Datetime cannot be earlier than the current date and time or Empty.
                  </span>
                </div>
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>

              <div className="row no-margin">
                <div className="col s7">
                  <span className="nucleus-labels">
                    <b>Escalation Timeout Alert (Minutes)</b>
                  </span>
                </div>
                <div className="col s5 no-margin">
                  <input
                    ref="txtNoResponseTimeout"
                    type="number"
                    className="validate nucleus-textfield textbox2 "
                    min="0"
                    step="1"
                    defaultValue="00"
                    placeholder="0"
                    disabled={this.state.messageEditableRecurring}
                  />
                </div>
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>

              <div className="row">
                {this.refs.selectMessageType && this.refs.selectMessageType.value == '6' && (
                  <div className="col s12">
                    <span className="nucleus-font nucleus-font-14">
                      <b>
                        Repeat is disabled for signature messages.
                        <br />
                      </b>
                    </span>
                  </div>
                )}
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Repeat</b>
                  </span>
                </div>
                <div className="col s8 no-margin left-align">
                  <div className="switch no-margin nucleus-switch-inline2">
                    <label className="no-margin">
                      <input type="checkbox" id="checkRepeat" ref="checkRepeat" disabled={this.state.messageRepeatStyle} onChange={this.handleMessageRepeat} />
                      <span className="lever"></span>
                    </label>
                  </div>

                  <a
                    class={'txt-white nucleus-font-small ' + this.state.stopRecurringMessageStyle + ' ' + this.state.stopRecurringMessageVisibility}
                    onClick={this.handleStopRecurringMessageModal}
                  >
                    Stop
                  </a>
                </div>
              </div>

              <div class={'row ' + this.state.repeatConfigVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Repeat Every</b>
                  </span>
                </div>
                <div className="col s8 no-margin">
                  <input
                    ref="txtRepeatNumber"
                    type="text"
                    className="validate nucleus-textfield textbox2 "
                    defaultValue="1"
                    placeholder="1"
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleTxtRepeatEvery}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <select
                    ref="selectMessageRecurring"
                    className="browser-default nucleus-select selectx2 a-bit"
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleSelectMessageRecurring}
                  >
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                  </select>
                  <br />
                  <span class={'nucleus-form-error center-align ' + this.state.messageRepeatEveryError}>Please enter a number.</span>
                </div>
              </div>

              <div class={'row ' + this.state.repeatWeekVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Repeat On</b>
                  </span>
                </div>
                <div className="col s8 no-margin">
                  <span>
                    Su
                    <input
                      ref="checkSunday"
                      type="checkbox"
                      className="filled-in"
                      id="checkSunday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkSunday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    M
                    <input
                      ref="checkMonday"
                      type="checkbox"
                      className="filled-in"
                      id="checkMonday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkMonday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    T
                    <input
                      ref="checkTuesday"
                      type="checkbox"
                      className="filled-in"
                      id="checkTuesday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkTuesday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    W
                    <input
                      ref="checkWednesday"
                      type="checkbox"
                      className="filled-in"
                      id="checkWednesday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkWednesday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    Th
                    <input
                      ref="checkThursday"
                      type="checkbox"
                      className="filled-in"
                      id="checkThursday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkThursday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    F
                    <input
                      ref="checkFriday"
                      type="checkbox"
                      className="filled-in"
                      id="checkFriday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkFriday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    S
                    <input
                      ref="checkSaturday"
                      type="checkbox"
                      className="filled-in"
                      id="checkSaturday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkSaturday" className="check-day">
                      &nbsp;
                    </label>
                  </span>

                  <br />
                  <span class={'nucleus-form-error center-align ' + this.state.messageRepeatDaysError}>Please select at least one day.</span>
                </div>
              </div>

              <div class={'row no-margin ' + this.state.repeatConfigVisibility}>
                <div className="col s12">
                  <span className="nucleus-labels">
                    <b>Ends:</b>
                  </span>
                </div>
              </div>

              <div class={'row no-margin a-bit-lower ' + this.state.repeatConfigVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Never</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <img src={repeatNeverEndsImg} className="nucleus-icon-active" width="24" onClick={this.handleRepeatNeverEnds} />
                </div>
                <div className="col s2"></div>
              </div>

              <div class={'row no-margin ' + this.state.repeatConfigVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Ends on</b>
                  </span>
                </div>
                <div className="col s1 no-margin">
                  <img src={repeatEndDateImg} className="nucleus-icon-active" width="24" onClick={this.handleRepeatEndDate} />
                </div>
                <div className="col s7 no-margin">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <input 
                                    id="messageEndDateTime" 
                                    ref="messageEndDateTime" 
                                    type="text" 
                                    class={"validate nucleus-date-picker2 " + this.state.messageRepeatEndsDateSection }  
                                    disabled={this.state.messageEditableRecurring} /> */}
                  {this.state.messageRepeatEndsDateSection != ' hide' && (
                    <div style={{ width: 210, display: 'inline-flex' }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                          ref={this.messageEndDateTimeRef}
                          variant="dialog"
                          className="browser-default"
                          value={this.state.messageEndDateTime}
                          onChange={this.onMessageEndDateTimeChange}
                          onError={console.log}
                          onClose={() => {}}
                          //minDate={new Date("2018-01-01T00:00")}
                          format={this.datetimeFormat}
                          disabled={this.state.messageEditableRecurring}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  )}
                  <br />
                  <span class={'nucleus-form-error center-align ' + this.state.messageEndDateError + ' ' + this.state.messageRepeatEndsDateSection}>
                    End Repeat Datetime cannot be earlier than the Delivery datetime .
                  </span>
                </div>
              </div>

              {/*
                        <div class={ "row " + this.state.repeatConfigVisibility }>
                            <div className="col s4">
                                <span className="nucleus-labels"><b>Ends</b></span>
                            </div>
                            <div className="col s6 no-margin">
                                
                                <div className="switch no-margin ">
                                    &nbsp;&nbsp;
                                    Never
                                    <label className="no-margin" >
                                        <input type="checkbox" ref="checkEnds"  disabled={this.state.messageEditable}  onChange={this.handleMessageEnds} />
                                        <span className="lever nucleus-check"></span>
                                    </label>
                                    On
                                </div>
                            </div>
                            <div className="col s2">
                            </div>
                        </div>

                        
                        <div class={ "row " + this.state.messageRepeatEndsDateSection }>
                            <div className="col s4">
                            </div>
                            <div className="col s8 no-margin left-align">
                                &nbsp;&nbsp;&nbsp;
                                
                                <br/>
                                &nbsp;&nbsp;&nbsp;
                                
                            </div>
                        </div>*/}

              <div className="input-field col s12 ">
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a class={'txt-white nucleus-font-modal-btn ' + this.state.editMessageBtnStyle + this.state.messageSaveNewBtn} onClick={this.handleResumeSaveMessage}>
                      Save
                    </a>
                    <a class={'txt-white nucleus-font-modal-btn ' + this.state.editMessageBtnStyle + this.state.messageUpdateBtn} onClick={this.handleResumeUpdateMessage}>
                      Update
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            {/*
                        <div class={" " + this.state.messageModeDelete }>
                            <div className="row">
                                <div className="col s12">
                                    <span className="nucleus-labels"> Are you sure you want to delete this message ? </span>
                                </div>
                            </div>

                            <div className="input-field col s12 modalCallLogContent2">
                                <br/>
                                <div className="row no-margin">
                                    <div className="col s12 center-align no-margin">
                                        <p></p>
                                        <span className=" modalSeparator"> <label> &nbsp;</label></span>
                                        <a class={"txt-white nucleus-font-small " + this.state.deleteMessageBtnStyle } onClick={this.handleSubmitDeleteMessage}>Delete</a>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
          </div>
        </div>

        <div id="modalNotEditableMessage" className="modal modalNucleusMessage modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;Edit Message</h3>
            <p></p>
            <div class={' '}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> The message is going to be sent, cannot be edited. </span>
                </div>
              </div>

              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a class={'txt-white nucleus-font-small nucleus-submit-btn'} onClick={this.handleCloseNotEditableModal}>
                      OK
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalNotDeleteMessage" className="modal modalNucleusMessage modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;Delete Message</h3>
            <p></p>
            <div class={' '}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> The Message is scheduled to be sent soon. It is not possible to delete it. </span>
                </div>
              </div>

              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a class={'txt-white nucleus-font-small nucleus-submit-btn'} onClick={this.handleCloseNotDeleteModal}>
                      OK
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalResumeSaveMessage" className="modal modalNucleusMessage modalResumeSaveMessage">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Message Confirmation</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                You are about to send a message to {this.state.messageDeliverTo + this.state.confirmMessageText} <br />
                {this.state.confirmMessageTextLine2} <br />
                {this.state.confirmMessageTextLine3}
              </div>
            </div>
            {!this.state.savingMessage && (
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelResumeMessageModal}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleSaveMessage}>
                    Confirm
                  </a>
                </div>
              </div>
            )}
            {this.state.savingMessage && (
              <div className="row">
                <div className="col s12 center-align">
                  <Spinner color="#2096F3" size={34} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
                </div>
                <div className="col s12 left-align">
                  <div className="col s12 left-align nucleus-font-medium">{this.state.savingMessageText}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="modalResumeUpdateMessage" className="modal modalNucleusMessage modalResumeUpdateMessage">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Update Message Confirmation</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                You are about to edit the message. {/*this.state.messageDeliverTo + this.state.confirmMessageText*/} <br />
                {/* this.state.confirmMessageTextLine2 */} <br />
                {/* this.state.confirmMessageTextLine3 */}
              </div>
            </div>
            {!this.state.savingMessage && (
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelResumeUpdateMessageModal}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleUpdateMessage}>
                    Confirm
                  </a>
                </div>
              </div>
            )}
            {this.state.savingMessage && (
              <div className="row">
                <div className="col s12 center-align">
                  <Spinner color="#2096F3" size={34} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
                </div>
                <div className="col s12 left-align">
                  <div className="col s12 left-align nucleus-font-medium">{this.state.savingMessageText}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="modalDeletePatientMessage" className="modal modalNucleusMessage dismissible-modal">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Delete Message</h3>
            <br />
            <br />
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                Are you sure you want to delete this message ?
                <br />
              </div>
            </div>
            <div className="row ">
              <div className="col s12 right-align ">
                <p></p>
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>
                  Cancel
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a class={'txt-white nucleus-font-small ' + this.state.deleteMessageBtnStyle} onClick={this.handleSubmitDeletePatientMessage}>
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="modalDeleteMessage" className="modal modalNucleusMessage modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <h3 className="nucleus-page-subtitle"> Delete Message</h3>
            <a class={'nucleus-font nucleus-font-small ' + this.state.messageModalNotDeleteView}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="txt-red">Message has already been sent and cannot be deleted</span>
            </a>
            <br />
            <br />
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                Are you sure you want to delete this message ?
                <br />
              </div>
            </div>
            <div className="row ">
              <div className="col s12 right-align ">
                <p></p>
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDeleteMessage}>
                  Cancel
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a class={'txt-white nucleus-font-small ' + this.state.deleteMessageBtnStyle} onClick={this.handleSubmitDeleteMessage}>
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="modalStopRecurringMessage" className="modal modalMessages modalStopRecurringMessage">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Stop Recurring Message</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                Are you sure you want to stop this message from repeating?
                <br />
              </div>
            </div>
            <div className="row ">
              <div className="col s12 right-align ">
                <p></p>
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelStopRecurringMessageModal}>
                  Cancel
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleStopRecurringMessage}>
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NucleusMessage;
