import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import backendClient from 'utils/BackendClient';
class BulletinApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<-------------------- Get BulletinBoard Buttons by Account --------------------->
  GetAccountBulletinButtons(data, callback) {
    backendClient.get(`/bulletin-board/${data.AccountID}/all-buttons`, callback);
  }

  //<---------------------------Update Media Caption--------------------------->
  UpdateButtonContent(data, callback) {
    let rsp;
    const payload = {
      content: data?.Content,
    };
    httpNodeApi
      .put(`bulletin_board/${data?.ButtonID}`, payload)
      .then(response => {
        const { data } = response;
        rsp = data;
      })
      .catch(error => {
        const { data } = error.response || { error: 'Error updating button content' };
        console.error('Error on PUT bulletin_board/:bulletinId: ', data);
        rsp = data;
      })
      .finally(() => {
        console.log('PUT bulletin_board/:bulletinId: ', rsp);
        callback(rsp);
      });
  }

  UploadImage(data, callback) {
    let rsp;
    const payload = {
      file: data?.File,
      fileName: data?.ImageFileName,
    };
    httpNodeApi
      .post(`account/${data?.AccountID}/bulletin-images`, payload)
      .then(response => {
        const { data } = response;
        rsp = data;
      })
      .catch(error => {
        const { data } = error.response || { error: 'Error uploading image' };
        console.error('Error on POST account/:accountId/bulletin-images: ', data);
        rsp = data;
      })
      .finally(() => {
        console.log('POST account/:accountId/bulletin-images: ', rsp);
        callback(rsp);
      });
  }

  UploadPdf(data, callback) {
    let rsp;
    const payload = {
      file: data?.File,
      fileName: data?.PdfFileName,
    };
    httpNodeApi
      .post(`account/${data?.AccountID}/bulletin-pdfs`, payload)
      .then(response => {
        const { data } = response;
        rsp = data;
      })
      .catch(error => {
        const { data } = error.response || { error: 'Error uploading pdf' };
        console.error('Error on POST account/:accountId/bulletin-pdfs: ', data);
        rsp = data;
      })
      .finally(() => {
        console.log('POST account/:accountId/bulletin-pdfs: ', rsp);
        callback(rsp);
      });
  }
}

export default BulletinApi;
