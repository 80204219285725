//@ts-nocheck
import classNames from 'classnames';
import TelephoneInputUnderline from 'components/UI/Inputs/TelephoneInputUnderlined';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore from '../../stores/CarePatientStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import CompressUtil from '../../utils/CompressUtil';
import Message from '../../utils/Message';

class NewPatient extends React.Component {
  constcontextTypes: {
    router: React.PropTypes.object.isRequired;
  };

  constructor() {
    super();

    validateAccessGranted('/newPatient');

    this.state = {
      clientImageFile: null,
      clientImageFilePreviewURL: '',
      panel: 'Form',
      newPatientFullName: '',
      devicesArray: [],
      btnStyle: 'disabled',
      errorTelephoneVisibility: 'hide',
      errorTelephoneText: 'Telephone already registered ',
      errorDateVisibility: 'hide',
      errorDateText: '',
      errorContactTelephoneVisibility: 'hide',
      errorContactTelephoneText: 'Telephone already registered ',
      newPatientTelephone: null,
    };

    this.submitCreatePatientForm = this.submitCreatePatientForm.bind(this);
    this.onCreatePatientAction = this.onCreatePatientAction.bind(this);
    this.onGetDevicesAction = this.onGetDevicesAction.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.isValidDate = this.isValidDate.bind(this);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  }

  componentDidMount() {
    CarePatientStore.on('onCreatePatientAction', this.onCreatePatientAction);
    CarePatientStore.on('onGetDevicesAction', this.onGetDevicesAction);

    // Removed on Sep 27th, 2017 - CARE 1069
    // CarePatientStore.getCareDevices({
    //     AccountID : AuthStore.getUserAccountID(),
    // });
    $('.tooltipped').tooltip({ delay: 50 });
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onCreatePatientAction', this.onCreatePatientAction);
    CarePatientStore.removeListener('onGetDevicesAction', this.onGetDevicesAction);
    $('.tooltipped').tooltip('remove');
  }

  onGetDevicesAction() {
    console.log('onGetDevicesAction');
    // Removed on Sep 27th, 2017 - CARE 1069
    // if (response.ok){
    //     this.setState({
    //         devicesArray : response.devices
    //     });
    // } else {
    //     Message.show("There was a problem getting the available devices, please try again.");
    // }
  }

  validateForm() {
    if (
      this.refs.txtNewPatientFirstName.value.length > 0 &&
      this.refs.txtNewPatientLastName.value.length > 0
      //&& this.refs.txtNewPatientTelephone.value.length > 0
      //&& this.refs.txtNewPatientAddress.value.length > 0
    ) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }

    if (this.state.newPatientTelephone?.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  }

  onTelephoneInputChange = number => {
    console.log('onTelephoneInputChange', number);
    this.state.newPatientTelephone = number;
    if (number.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  };

  submitCreatePatientForm() {
    if (this.refs.txtNewPatientFirstName.value === '') {
      Message.show('FirstName is required.');
      return;
    }

    if (this.refs.txtNewPatientLastName.value === '') {
      Message.show('LastName is required.');
      return;
    }

    // Telephone and Address Validation Deprecated for CARE-1366
    // if (this.refs.txtNewPatientTelephone.value === "") {
    //     Message.show('Ups! The telephone is not valid.');
    //     return;
    // }
    // if (this.refs.txtNewPatientAddress.value === "") {
    //     Message.show('Ups! The address is not valid.');
    //     return;
    // }

    const dateParam = '';
    let newPatientTelephone = this.state.newPatientTelephone;
    if (newPatientTelephone === '+1') {
      newPatientTelephone = '';
    }
    if (newPatientTelephone.length > 0) {
      newPatientTelephone = newPatientTelephone.replace(/[()-\s]/g, '');
      if (newPatientTelephone.length < 12) {
        this.setState({
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone. It must be at least 10 digits.',
        });
        return;
      }

      const containsLetter = /[a-zA-Z]/.test(newPatientTelephone);
      if (containsLetter) {
        this.setState({
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone.',
        });
        return;
      }
    }

    const newPatientData = {
      UserID: AuthStore.getUserID(),
      AccountID: AuthStore.getUserAccountID(),
      FirstName: this.refs.txtNewPatientFirstName.value,
      LastName: this.refs.txtNewPatientLastName.value,
      Telephone: newPatientTelephone,
      Address: this.refs.txtNewPatientAddress.value,
      DateOfBirth: dateParam,
      EmergencyContactName: '', //this.refs.txtNewPatientContactName.value,           //  Deprecated on Feb 2023
      EmergencyContactTelephone: '', //this.refs.txtNewPatientContactNumber.value     // Deprecated on Feb 2023     CARE-10239 Remove Emergency Contact and Date of Birth from patient
      //ShowWeather : this.refs.checkShowWeather.checked
    };
    this.setState({
      btnStyle: 'disabled',
    });

    CarePatientStore.CreateNewPatient(newPatientData);

    this.setState({
      errorTelephoneVisibility: 'hide',
    });
  }

  onCreatePatientAction(response) {
    if (response.ok) {
      // this.setState({
      //     panel : "Message",
      //     newPatientFullName : this.refs.txtNewPatientFirstName.value + ' ' + this.refs.txtNewPatientLastName.value
      // });
      CarePatientStore.getAllPatientsData({
        UserID: AuthStore.getUserID(),
      });
      console.log('RESPONSE NEW PATIENT', response);
      if (this.state.clientImageFile != null) {
        CarePatientStore.UploadPatientImage({
          PatientID: response.PatientID,
          File: this.state.clientImageFile,
        });
        this.setState({
          clientImageFile: null,
        });
      }

      this.props.history.navigate('/patients');
      setTimeout(function () {
        Message.show(AuthStore.getPatientLabel() + ' created');
      }, 100);
    } else if (response.telephoneRegistered) {
      this.setState({
        errorTelephoneVisibility: '',
        errorTelephoneText: 'Telephone number already registered ',
      });
    } else {
      Message.show('Error creating new ' + AuthStore.getPatientLabel());

      if (response.error) {
        Message.show('Error: ' + response.error);
      }
    }

    this.setState({
      btnStyle: '',
    });
  }

  isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
      return false;
    }
    // Parse the date parts to integers
    const parts = dateString.split('/');
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);
    // Check the ranges of month and year
    if (year < 1900 || year > 3000 || month == 0 || month > 12) {
      return false;
    }
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }
    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  render() {
    const formVisibility = classNames({
      hide: this.state.panel != 'Form',
    });
    const messageVisibility = classNames({
      hide: this.state.panel != 'Message',
    });

    // Removed on Sep 27th, 2017 - CARE 1069
    // if (this.state.devicesArray != null && this.state.devicesArray.length){
    //     this.state.devicesArray.map((device, key) => {
    //         availableDevicesList.push(<option key={device.ID} value={device.ID} tag={device.Mac}>{device.Mac}</option>);
    //     });
    // }

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p id={'patient-page-title'} className="nucleus-page-title">
            New {AuthStore.getPatientLabel()}
          </p>
          <br />
          <div class={'nucleus-patient-form-pending ' + formVisibility}>
            <br />
            <div className="row no-margin">
              <div className="col s2 m2 l2 no-margin">
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                  <style>
                    {`
                      .img-circle {
                          border-radius: 50%;
                      }
                      .img-circle {
                          border-radius: 0;
                      }
                      
                      .ratio {
                          background-position: center center;
                          background-repeat: no-repeat;
                          background-size: cover;
                      
                          height: 0;
                          padding-bottom: 100%;
                          position: relative;
                          width: 100%;
                      }
                      .img-circle {
                          border-radius: 50%;
                      }
                      .img-responsive {
                          display: block;
                          height: auto;
                          max-width: 100%;
                      }
                      .inputfile {
                          width: 0.1px;
                          height: 0.1px;
                          opacity: 0;
                          overflow: hidden;
                          position: absolute;
                          z-index: -1;
                      }
                      .inputfile + label {
                          margin-top:10px;
                          font-size: 1.25em;
                          // font-weight: 700;
                          text-decoration: underline;
                          color: gray;
                          // background-color: black;
                          display: inline-block;
                      }
                      
                      .inputfile:focus + label,
                      .inputfile + label:hover {
                          cursor: pointer;
                          // background-color: red;
                      }
                      .inputfile {
                          cursor: pointer;
                      }
                    `}
                  </style>
                  <div
                    style={{
                      width: '70%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      className="ratio img-responsive img-circle"
                      style={{
                        backgroundImage: this.state.clientImageFilePreviewURL ? `url(${this.state.clientImageFilePreviewURL})` : `url("/img/user_placeholder.png")`,
                        borderWidth: '1px solid lightgray',
                      }}
                    ></div>
                    <div>
                      <input
                        accept="image/*"
                        onChange={e => {
                          const value = e.target.files[0];
                          console.log('value:', value);
                          const reader = new FileReader();
                          const urlImage = URL.createObjectURL(value);
                          reader.onloadend = function () {
                            this.setState({
                              clientImageFile: value,
                              clientImageFilePreviewURL: urlImage,
                            });
                          }.bind(this);
                        }}
                        multiple={false}
                        type="file"
                        name="file"
                        id="file"
                        className="inputfile"
                      />
                      <label htmlFor="file">Upload Photo</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s8 m8 l8">
                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className=" nucleus-labels"> First Name</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input
                      id={'input-first-name'}
                      ref="txtNewPatientFirstName"
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="First Name"
                      onChange={this.validateForm}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className=" nucleus-labels"> Last Name</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input
                      id={'input-last-name'}
                      ref="txtNewPatientLastName"
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="Last Name"
                      onChange={this.validateForm}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Telephone</span>
                  </div>
                  <div className="col s8" style={{ paddingRight: 0 }}>
                    <TelephoneInputUnderline inputId="txtNewPatientTelephone" fieldId="newPhone" value="" onChange={this.onTelephoneInputChange} onBlur={this.validateForm} />
                    <span class={'nucleus-form-error left-align txt-black-forced '}>Click the country code icon for more country codes</span>
                    <br />
                    <span class={'nucleus-form-error center-align ' + this.state.errorTelephoneVisibility}>{this.state.errorTelephoneText}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Address</span>
                  </div>
                  <div className="col s8">
                    <input
                      id="input-address"
                      ref="txtNewPatientAddress"
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="1234 Street Lane, New City, NC 00000"
                      onChange={this.validateForm}
                    />
                  </div>
                </div>
                {/* 
                  // Deprecated on Feb 14, 2023: Patient Date of Birth
                  // Removed Aug 21, 2024
                */}
                {/* 
                  // Removed on Feb 14, 2023 - CARE-10239
                  // Emergency Contact
                  //    txtNewPatientContactName
                  //    txtNewPatientContactNumber
                  */}

                {/*
                  // Removed on Nov 2nd, 2017 - CARE-1343                            
                  // Removed on Sep 27th, 2017 - CARE 1069
                  <div className="row">
                      <div className="col s4 left-align">
                          <span className=" nucleus-labels"> Device</span> 
                      </div>
                      <div className="col s8">
                          <span className=" nucleus-labels"> Select the MAC address of the {AuthStore.getPatientLabel().toLowerCase()}’s device.</span> 
                          <select ref="selectDeviceMacAddress" className="browser-default">
                              <option value="" defaulValue>No Device</option>
                                { availableDevicesList }
                            </select>
                      </div>
                  </div>
                */}
              </div>
            </div>
            <div className="fixed-action-btn btn-add-new">
              <a
                id={'save-patient-btn'}
                className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
                disabled={this.state.btnStyle}
                data-position="left"
                data-tooltip={'Save new ' + AuthStore.getPatientLabel().toLowerCase()}
                onClick={this.submitCreatePatientForm}
              >
                <i className="material-icons">save</i>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>

          <div ref="nucleus-new-patient-confirmation-pending" class={'nucleus-new-patient-form-pending ' + messageVisibility}>
            <br />
            <div className="row">
              <div className="col s12">
                <p className=" nucleus-labels"> Complete! The {AuthStore.getPatientLabel().toLowerCase() + ' ' + this.state.newPatientFullName} has been created. </p>
                <Link to="/patients">
                  <span className="nucleus-link nucleus-font-medium">Go to {AuthStore.getPatientLabelPlural()} List </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => useReactRouterProps(NewPatient, props);
