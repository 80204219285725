import { EventEmitter } from 'events';
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import MessagesApi from '../apis/MessagesApi';
import backendClient from '../utils/BackendClient';
class MessagesStore extends EventEmitter {
  private MessagesApi: MessagesApi;
  private MessagesList: any;
  private MessageCategories: any;
  private MessagesFilterFrom: any;
  private MessagesFilterTo: any;
  private MessagesFilterTimezone: any;
  private MessagesListSize: number;

  constructor() {
    super();

    this.MessagesApi = new MessagesApi(this);

    this.MessagesList = localStorage.MessagesList; //(!(typeof(localStorage.MessagesList) === 'object')) ?  JSON.parse(localStorage.MessagesList) : [];
    this.MessageCategories = localStorage.MessageCategories; // JSON.parse(localStorage.MessageCategories) || [];
    //this.MessagesList = localStorage.MessagesList || [];
    this.MessagesFilterFrom = localStorage.MessagesFilterFrom || '';
    this.MessagesFilterTo = localStorage.MessagesFilterTo || '';
    this.MessagesFilterTimezone = localStorage.MessagesFilterTimezone || '';
    this.MessagesListSize = 0;
    //console.warn(this);
  }

  getMessages() {
    //console.log("TYPE 1: ", typeof(this.MessagesList), this.MessagesList);

    if (typeof this.MessagesList !== 'object') {
      this.MessagesList = []; //JSON.parse(this.MessagesList);
    }
    return this.MessagesList;
  }

  getMessagesSize() {
    return this.MessagesListSize;
  }

  getMessageCategories() {
    //console.log("TYPE 2: ", (typeof(this.MessageCategories)!=="object"));

    if (typeof this.MessageCategories !== 'object') {
      this.MessageCategories = []; //JSON.parse(this.MessageCategories);
    }
    return this.MessageCategories;
  }

  getMessageFilterFrom() {
    return this.MessagesFilterFrom;
  }
  getMessageFilterTo() {
    return this.MessagesFilterTo;
  }
  getMessagesFilterTimezone() {
    return this.MessagesFilterTimezone;
  }

  setMessageFilterFrom(value) {
    this.MessagesFilterFrom = value;
  }
  setMessageFilterTo(value) {
    this.MessagesFilterTo = value;
  }
  setMessagesFilterTimezone(value) {
    this.MessagesFilterTimezone = value;
  }

  // Deprecated: Jan 30th 2019; CARE-4076
  getMessagesForUser({ UserID }) {
    //console.log("getMessagesForUser ");
    this.MessagesApi.GetMessagesForUser({ UserID }, rsp => {
      console.log('getMessagesForUser Response', rsp);
      this.MessagesList = rsp.Messages;
      this.MessagesListSize = rsp.Messages ? rsp.Messages.length : 0;
      this.saveToLocalStorage();
      this.emit('onGetMessagesForUser', { ok: rsp.ok });
    });
  }
  // Deprecated: Jan 30th 2019; CARE-4076
  getMessagesForUserUpdated({ UserID }) {
    //console.log("getMessagesForUserUpdated ");
    this.MessagesApi.GetMessagesForUser({ UserID }, rsp => {
      console.log('getMessagesForUserUpdated Response', rsp);
      this.MessagesList = rsp.Messages;
      this.MessagesListSize = rsp.Messages ? rsp.Messages.length : 0;
      this.saveToLocalStorage();
      this.emit('onGetMessagesForUserUpdated', rsp);
    });
  }

  getMessagesForAccount({ AccountID, FromDateTime, ToDateTime }) {
    //console.log("getMessagesForAccount ");

    this.MessagesApi.GetMessagesForAccount({ AccountID, FromDateTime, ToDateTime }, rsp => {
      this.MessagesList = rsp.Messages;
      this.MessagesListSize = rsp.Messages ? rsp.Messages.length : 0;
      this.saveToLocalStorage();

      this.emit('onGetMessagesForAccount', { ok: rsp.ok });
    });
  }

  getMessagesForAccountUpdated({ AccountID, FromDateTime, ToDateTime }) {
    //console.log("getMessagesForAccountUpdated ");
    this.MessagesApi.GetMessagesForAccount({ AccountID, FromDateTime, ToDateTime }, rsp => {
      //console.log("getMessagesForAccountUpdated Response", rsp);
      this.MessagesList = rsp.Messages;
      this.MessagesListSize = rsp.Messages ? rsp.Messages.length : 0;
      this.saveToLocalStorage();
      this.emit('onGetMessagesForAccountUpdated', rsp);
    });
  }

  refreshMessagesCenter() {
    this.emit('onRefreshMessagesCenter');
  }

  getMessageCategries() {
    this.MessagesApi.GetMessageCategories({}, rsp => {
      console.log('getMessageCategries Response', rsp);
      this.MessageCategories = rsp.Categories;
      this.saveToLocalStorage();

      //this.emit("onGetMessageCategories", rsp);
    });
  }

  refreshMessageCategries() {
    this.MessagesApi.GetMessageCategories({}, rsp => {
      //console.log("getMessageCategries Response", rsp);
      this.MessageCategories = rsp.Categories;
      this.saveToLocalStorage();

      this.emit('onRefreshMessageCategories', rsp);
    });
  }

  getMessageSingleChoiceTemplates() {
    this.MessagesApi.GetMessageTemplates({}, rsp => {
      //console.log("getMessageCategries Response", rsp);
      //this.MessageCategories = rsp.Categories;
      //this.saveToLocalStorage();

      this.emit('onGetMessageTemplates', rsp);
    });
  }

  getMessageBiometricsTemplates({ AccountID }) {
    this.MessagesApi.GetBiometricsMessagesTemplates({ AccountID }, rsp => {
      //console.log("getMessageCategries Response", rsp);
      //this.MessageCategories = rsp.Categories;
      //this.saveToLocalStorage();

      this.emit('onGetBiometricMessageTypes', rsp);
    });
  }

  getAutocompletePatientsList({ UserID }) {
    //console.log("getAutocompletePatientsList ");
    this.MessagesApi.GetAutocompletePatientsList({ UserID }, rsp => {
      this.emit('onGetAutocompletePatientsList', rsp);
    });
  }

  getAutocompletePatientsList2({ UserID }, callback) {
    //console.log("getAutocompletePatientsList ");
    this.MessagesApi.GetAutocompletePatientsList({ UserID }, rsp => {
      callback(rsp);
    });
  }

  // The one for Groups
  getAutocompletePatientsGroupsList({ UserID }) {
    console.log('getAutocompletePatientsGroupsList ');
    backendClient.get(`user/${UserID}/patients_groups_autocomplete_list?name=&limit=500`, nodeResponse => {
      console.log('getAutocompletePatientsGroupsList', nodeResponse);
      if (nodeResponse.ok) {
        const autocompletePatients = {};
        const autocompleteGroups = {};
        const autocompleteData = {};
        if (nodeResponse.autocompleteOptions) {
          for (let i = 0; i < nodeResponse.autocompleteOptions.length; i++) {
            const data = nodeResponse.autocompleteOptions[i];
            const nameKey = data.Name.trim();
            autocompleteData[nameKey] = data.ID;
            if (data.Type == 'patient') {
              autocompletePatients[nameKey] = data.ID;
            }
            if (data.Type == 'group') {
              autocompleteGroups[nameKey] = data.ID;
            }
          }
          this.emit('onGetAutocompletePatientsList', {
            ok: true,
            autocompleteData,
            autocompleteGroups,
            autocompletePatients,
          });
        }
      } else {
        this.emit('onGetAutocompletePatientsList', { ok: false });
      }
    });
  }

  saveNewMessageByUser(params) {
    console.log('saveNewMessageByUser ', params);

    this.MessagesApi.SaveScheduledMessageByUser(params, rsp => {
      this.emit('onSaveNewMessageByUser', rsp);
    });
  }

  saveScheduledMessageByUser(params) {
    console.log('saveScheduledMessageByUser ', params);

    this.MessagesApi.SaveScheduledMessageByUser(params, rsp => {
      this.emit('onSaveScheduledMessageByUser', rsp);
    });
  }

  UploadSignatureMessagePdfFile({ MessageID, PdfFileName, File }) {
    console.log('UploadSignatureMessagePdfFile ', MessageID, PdfFileName, File);
    this.MessagesApi.UploadSignatureMessagePdfFile(
      {
        MessageID,
        PdfFileName,
        File,
      },
      rsp => {
        console.log('UploadSignatureMessagePdfFile rsp', rsp);
        this.emit('onSaveNewMessageFile', rsp);
      },
    );
  }

  DeleteSignatureMessagePdfFile({ PdfFileName, SignaturesBucketName }) {
    console.log('DeleteSignatureMessagePdfFile ', PdfFileName, SignaturesBucketName);
    this.MessagesApi.DeleteSignatureMessagePdfFile(
      {
        PdfFileName,
        SignaturesBucketName,
      },
      rsp => {
        console.log('DeleteSignatureMessagePdfFile rsp', rsp);
        //this.emit("onSaveNewMessageFile", rsp);
      },
    );
  }

  // Used by the MessageCenter
  updateMessageByUser(updateMessageData) {
    //console.log("updateMessageByUser ",AccountID, MessageID ,Message ,SendToAll ,DeliveryTime ,MessageCategoryID, IsPersistent, TtLHours, IsRecurring ,RepeatEvery ,RepeatType ,RepeatOnSunday ,RepeatOnMonday ,RepeatOnTuesday ,RepeatOnWednesday ,RepeatOnThursday ,RepeatOnFriday,RepeatOnSaturday,EndDate ,IsSingleOptionMessage ,IsMultipleOptionMessage ,MessageOptionsTexts ,PatientsIDs, NoResponseTimeout, MessageOptionsTemplateID, BiometricTypeID, MessageOptionsAlerts, IsLinkMessage, LinkMessageUrl, LinkMessageShouldOpen, LinkMessageButton, IsSignatureMessage, SendNow, EditMessage  );
    console.log('updateMessageByUser updateMessageData', updateMessageData);
    backendClient.put(`messages/${updateMessageData.MessageID}/update`, updateMessageData, nodeResponse => {
      console.log('updateMessageByUser put(messages/${MessageID}/update Response', nodeResponse);
      this.emit('onUpdateMessageByUser', nodeResponse);
    });

    // this.MessagesApi.UpdateMessageByUser(
    // 	{AccountID, MessageID ,Message ,SendToAll ,DeliveryTime ,MessageCategoryID, IsPersistent, TtLHours, IsRecurring ,RepeatEvery ,RepeatType ,RepeatOnSunday ,RepeatOnMonday ,RepeatOnTuesday ,RepeatOnWednesday ,RepeatOnThursday ,RepeatOnFriday,RepeatOnSaturday,EndDate ,IsSingleOptionMessage ,IsMultipleOptionMessage ,MessageOptionsTexts ,PatientsIDs, NoResponseTimeout, MessageOptionsTemplateID, BiometricTypeID, MessageOptionsAlerts, IsLinkMessage, LinkMessageUrl, LinkMessageShouldOpen, LinkMessageButton, IsSignatureMessage, SendNow, EditMessage  }
    // 	,(rsp) => {

    // 	this.emit("onUpdateMessageByUser", rsp);
    // });
  }

  // Used by the Patient Profile
  updateScheduledMessage({
    PatientMessageID,
    Message,
    SendToAll,
    DeliveryTime,
    MessageCategoryID,
    IsPersistent,
    TtLHours,
    NoResponseTimeout,
    IsRecurring,
    RepeatEvery,
    RepeatType,
    RepeatOnSunday,
    RepeatOnMonday,
    RepeatOnTuesday,
    RepeatOnWednesday,
    RepeatOnThursday,
    RepeatOnFriday,
    RepeatOnSaturday,
    EndDate,
    IsSingleOptionMessage,
    IsMultipleOptionMessage,
    MessageOptionsTexts,
    PatientsIDs,
    MessageOptionsTemplateID,
    BiometricTypeID,
    MessageOptionsAlerts,
    IsLinkMessage,
    LinkMessageUrl,
    LinkMessageShouldOpen,
    LinkMessageButton,
    IsSignatureMessage,
    SendNow,
  }) {
    console.log(
      'updateScheduledMessage ',
      PatientMessageID,
      Message,
      SendToAll,
      DeliveryTime,
      MessageCategoryID,
      IsPersistent,
      TtLHours,
      NoResponseTimeout,
      IsRecurring,
      RepeatEvery,
      RepeatType,
      RepeatOnSunday,
      RepeatOnMonday,
      RepeatOnTuesday,
      RepeatOnWednesday,
      RepeatOnThursday,
      RepeatOnFriday,
      RepeatOnSaturday,
      EndDate,
      IsSingleOptionMessage,
      IsMultipleOptionMessage,
      MessageOptionsTexts,
      PatientsIDs,
      MessageOptionsTemplateID,
      BiometricTypeID,
      MessageOptionsAlerts,
      IsLinkMessage,
      LinkMessageUrl,
      LinkMessageShouldOpen,
      LinkMessageButton,
      IsSignatureMessage,
      SendNow,
    );

    this.MessagesApi.UpdateScheduledMessage(
      {
        PatientMessageID,
        Message,
        SendToAll,
        DeliveryTime,
        MessageCategoryID,
        IsPersistent,
        TtLHours,
        NoResponseTimeout,
        IsRecurring,
        RepeatEvery,
        RepeatType,
        RepeatOnSunday,
        RepeatOnMonday,
        RepeatOnTuesday,
        RepeatOnWednesday,
        RepeatOnThursday,
        RepeatOnFriday,
        RepeatOnSaturday,
        EndDate,
        IsSingleOptionMessage,
        IsMultipleOptionMessage,
        MessageOptionsTexts,
        PatientsIDs,
        MessageOptionsTemplateID,
        BiometricTypeID,
        MessageOptionsAlerts,
        IsLinkMessage,
        LinkMessageUrl,
        LinkMessageShouldOpen,
        LinkMessageButton,
        IsSignatureMessage,
        SendNow,
      },
      rsp => {
        this.emit('onUpdateScheduledMessage', rsp);
      },
    );
  }

  getMessageDetails({ MessageID }) {
    console.log('getMessageDetails ', MessageID);
    backendClient.get(`messages/${MessageID}/data`, nodeResponse => {
      this.emit('onGetMessageDetails', nodeResponse);
    });
  }

  getMessageDetailsForDelete({ MessageID }) {
    console.log('getMessageDetailsForDelete ', MessageID);

    this.MessagesApi.GetMessageDetailsForDelete({ MessageID }, rsp => {
      //console.log("getMessageDetails Response", rsp);
      this.emit('onGetMessageDetailsForDelete', rsp);
    });
  }

  getPatientMessageDetails({ PatientID, PatientMessageID }) {
    //console.log("getPatientMessageDetails ");

    this.MessagesApi.GetPatientMessageDetails({ PatientID, PatientMessageID }, rsp => {
      //console.log("getMessageDetails Response", rsp);
      this.emit('onGetPatientMessageDetails', rsp);
    });
  }

  openNotEditableMessage() {
    this.emit('onOpenNotEditableMessage');
  }

  handleDeleteMessage({ PatientMessageID }) {
    this.emit('onHandleDeleteMessage', { PatientMessageID: PatientMessageID });
  }

  openNotDeleteMessage() {
    this.emit('onHandleNotDeleteMessage');
  }

  getMessageResponses({ MessageID, MessageBody, FromDateTime, ToDateTime }) {
    //console.log("getMessageResponses ");
    this.emit('onGetMessageReport');
    this.MessagesApi.GetMessageReport({ MessageID, FromDateTime, ToDateTime }, rsp => {
      //console.log("GetMessageReport Response", rsp);
      rsp['messageBody'] = MessageBody;
      rsp['messageFrom'] = FromDateTime;
      rsp['messageTo'] = ToDateTime;
      this.emit('onGotMessageReport', rsp);
    });
  }

  getMessageReport({ MessageID, FromDateTime, ToDateTime }) {
    //console.log("getMessageReport ");

    this.MessagesApi.GetMessageReport({ MessageID, FromDateTime, ToDateTime }, rsp => {
      //console.log("GetMessageReport Response", rsp);
      this.emit('onGetMessageReportUpdated', rsp);
    });
  }

  stopRecurringMessage({ MessageID, stop }) {
    //console.log("stopRecurringMessage ");

    this.MessagesApi.StopRecurringMessage({ MessageID, stop }, rsp => {
      //console.log("StopRecurringMessage Response", rsp);
      this.emit('onStopRecurringMessage', rsp);
    });
  }

  deleteMessage({ MessageID }) {
    //console.log("deleteMessage ");

    this.MessagesApi.DeleteMessage({ MessageID }, rsp => {
      //console.log("DeleteMessage Response", rsp);
      this.emit('onDeleteMessage', rsp);
    });
  }

  deletePatientMessage({ PatientMessageID }) {
    //console.log("deletePatientMessage ");

    this.MessagesApi.DeletePatientMessage({ PatientMessageID }, rsp => {
      //console.log("DeleteMessage Response", rsp);
      this.emit('onDeletePatientMessage', rsp);
    });
  }

  // setPollingQueueNumber(polling){
  // 	this.PollingQueueNumber = polling;
  // }

  // broadcastStopNotification(){
  // 	this.emit("onQueueItemClicked");
  // }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.MessagesList = this.MessagesList; // JSON.stringify(this.MessagesList);
    localStorage.MessageCategories = this.MessageCategories; //JSON.stringify(this.MessageCategories);

    localStorage.MessagesFilterFrom = this.MessagesFilterFrom;
    localStorage.MessagesFilterTo = this.MessagesFilterTo;
    localStorage.MessagesFilterTimezone = this.MessagesFilterTimezone;
  }
}

const messagesStore = new MessagesStore();

export default messagesStore;
