//@ts-nocheck
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { AuthStore } from 'stores';
import Config from '../../../Config';

import Message from '../../../utils/Message';

import nucleusNetwork, { ControlSecret } from '../../../utils/NucleusNetwork';

class PatientProfileMoveFamilyModal extends React.Component<{
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
  patientID: string;
  patientName: string;
}> {
  constructor(props) {
    super(props);
    this.state = {
      accountID: AuthStore.getUserAccountID(),
      patientID: this.props.patientID,

      selectedPatientsIDs: null,
      selectedPatientName: '',
      autocompletePatientsList: {},
      selectPatientsEnabled: 'disabled',
      selectPatientsError: ' hide',

      existingFamilyMembers: '',
      modalOpen: false,
      modalBtnStyle: 'nucleus-submit-btn',
      modalBtnsStyle: 'nucleus-submit-btn',
      loadingRequests: false,
    };

    this.onAutocompletePatientsListDone = this.onAutocompletePatientsListDone.bind(this);
    this.getFamilyMemberForPatient = this.getFamilyMemberForPatient.bind(this);
    this.CleanAndMoveFamily = this.CleanAndMoveFamily.bind(this);
    this.KeepAndMoveFamily = this.KeepAndMoveFamily.bind(this);
    this.closeSecondModal = this.closeSecondModal.bind(this);
    this.onMoveFamilyMembers = this.onMoveFamilyMembers.bind(this);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
  }

  componentDidMount() {
    console.log('PatientProfileMoveFamilyModal componentDidMount');
    //CarePatientStore.on("onPatientGetRequestLogs", this.onPatientGetRequestLogsAction);

    // let postParams = JSON.stringify({
    //     UserID : AuthStore.getUserID()
    // });
    // console.log("PatientProfileMoveFamilyModal postParams", postParams);
    const UserID = AuthStore.getUserID();
    httpApi
      .get(`/user/${UserID}/patients_autocomplete_list?name=&limit=100`)
      .then(({ data }) => {
        this.onAutocompletePatientsListDone(data, this.props.patientID);
      })
      .catch(error => {
        console.error('PatientProfileMoveFamilyModal error: (result)', error);
        Message.show('Error getting ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' move information');
      });

    $(document).ready(() => {
      $('.chips').material_chip();

      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        //secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.autocompletePatientsList,
          limit: Infinity,
        },
        selectable: false,
      });

      $('.chips').click(() => {
        this.setState({
          selectPatientsError: ' hide',
        });
      });

      // Event for when a Patient Chip is Selected
      $('.chips').on('chip.add', () => {
        //console.log("Selected >"+chip.tag+"<");
        //console.log("Patients available: ", this.state.uploadPatientsList);

        const existingPatients = [];
        const existingData = $('.chips-initial').material_chip('data');
        let patientExist = false;

        let count = 0;
        for (const element of existingData) {
          patientExist = this.state.uploadPatientsList[element.tag];

          if (patientExist) {
            count++;
            existingPatients.push(element);
          }
        }

        console.log('There is ' + count + ' patientIds Selected');

        const autocompleteData = count >= 1 ? [] : this.state.autocompletePatientsList;

        $('.chips-initial').material_chip({
          data: existingPatients,
          placeholder: 'Type a name',
          //secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: autocompleteData,
            limit: Infinity,
          },
        });
        //$('#txtFamilyPatients input:first').focus();
      });
    });

    $('.chips').on('chip.delete', () => {
      const existingPatients = [];
      const existingData = $('.chips-initial').material_chip('data');
      let patientExist = false;

      let count = 0;
      for (let i = 0; i < existingData.length; i++) {
        patientExist = this.state.uploadPatientsList[existingData[i].tag];
        if (patientExist) {
          count++;
          existingPatients.push(existingData[i]);
        }
      }

      console.log('Now There is ' + count + ' patientIds Selected');
      // if (count < 1){
      //     console.log("Enable txtFamilyPatients");
      //     this.setState({
      //         selectPatientsEnabled : '',
      //     });
      //     $( "#txtFamilyPatients :input" ).prop( "disabled", false );
      //     $( "#txtFamilyPatients" ).find( "i" ).css( "visibility", "visible" );
      // }
      const autocompleteData = count >= 1 ? [] : this.state.autocompletePatientsList;

      $('.chips-initial').material_chip({
        data: existingPatients,
        placeholder: 'Type a name',
        //secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: autocompleteData,
          limit: Infinity,
        },
      });
    });

    $('.chips').on('chip.select', (e, chip) => {
      console.log("hIrt'", e, chip);
      return false;
    });

    this.setState({
      loadingRequests: false,
    });
  }

  componentWillUnmount() {
    console.log('PatientProfileMoveFamilyModal componentWillUnmount');
    //CarePatientStore.removeListener("onPatientGetRequestLogs", this.onPatientGetRequestLogsAction);
  }

  componentWillReceiveProps(newProps) {
    if (this.state.modalOpen != newProps.open) {
      console.log('PatientProfileMoveFamilyModal componentWillReceiveProps', newProps);
      this.setState({
        modalOpen: newProps.open,
      });

      if (newProps.open) {
        this.setState({
          selectedPatientsIDs: [],
          selectedPatientName: '',
          modalBtnStyle: 'nucleus-submit-btn',
          modalBtnsStyle: 'nucleus-submit-btn',
          loadingRequests: false,
        });

        $('.chips-initial').material_chip({
          data: [],
          placeholder: 'Type a name',
          //secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: this.state.autocompletePatientsList,
            limit: Infinity,
          },
        });

        $('#modalMoveFamily').modal('open');
      } else {
        $('#modalMoveFamily').modal('close');
      }
    }
  }

  onAutocompletePatientsListDone(response, patientID) {
    console.log('onAutocompletePatientsListDone', response, patientID);

    if (response.ok) {
      const autocompletePatients = {};
      const autocompletePatientIDs = {};
      let patientNameKey = '';

      response.autocompleteOptions.forEach(function (patient) {
        // key: the name of the object key
        // index: the ordinal position of the key within the object

        patientNameKey = patient.Name;
        //console.log("Having >"+patientNameKey+"<");
        if (patient.ID != patientID) {
          autocompletePatientIDs[patientNameKey] = patient.ID;
          //We initialize with the patient name as the key and null value
          autocompletePatients[patientNameKey] = null;
        }
      });
      //console.log("autocompletePatientIDs", autocompletePatientIDs);
      //console.log("autocompletePatients", autocompletePatients);
      this.setState({
        uploadPatientsList: autocompletePatientIDs,
        autocompletePatientsList: autocompletePatients,
      });
      // Chips Docs - https://archives.materializecss.com/0.100.2/chips.html
      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        //secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: autocompletePatients,
          limit: Infinity,
        },
      });
    }
  }

  // pad2(number) {
  //     return (number < 10 ? '0' : '') + number;
  // }

  onSubmit = () => {
    console.log('onSubmit');

    console.log('this.state.selectedPatientsIDs', this.state.selectedPatientsIDs);
    const selectedPatients = $('.chips-initial').material_chip('data');

    if (selectedPatients.length != 1) {
      this.setState({
        selectPatientsError: '',
      });
      console.log('CHSM 1');
      return;
    }

    let patientsIDsArray = null;
    let patientName = '';
    if (this.state.selectedPatientsIDs != null) {
      console.log(selectedPatients);
      patientsIDsArray = '';
      $.each(selectedPatients, (key, value) => {
        //console.log("Seleccionado", value);
        //console.log("SeleccionadoDE", this.state.uploadPatientsList);
        let patientID = null;
        patientID = this.state.uploadPatientsList[value.tag];
        //patientsIDsArray.push(patientID);
        if (patientsIDsArray.length > 0) {
          patientsIDsArray = patientsIDsArray + ',';
        }
        patientsIDsArray = patientsIDsArray + patientID;
        patientName = value.tag;
        //console.log("The PatientID es: ", patientID);
      });
      console.log('Les patients IDS sont', patientsIDsArray);
      this.setState({
        selectedPatientsIDs: patientsIDsArray,
        selectedPatientName: patientName,
        modalBtnStyle: 'nucleus-submit-btn-disabled',
        modalBtnsStyle: 'nucleus-submit-btn-disabled',
        loadingRequests: true,
      });

      this.getFamilyMemberForPatient(patientsIDsArray);
    } else {
      Message.show('Error on Submit');
    }
  };

  getFamilyMemberForPatient(patientID) {
    console.log('getFamilyMemberForPatient');
    const headers = Object.assign(
      {},
      {
        'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
        'X-EntityType': 'Control',
        'X-Secret': ControlSecret,
      },
    );
    $.ajax({
      url: Config.domain + 'FamilyMember.asmx/List',
      type: 'POST',
      data: {
        PatientID: patientID,
      },
      dataType: 'json',
      headers: headers,
      //contentType: 'application/json; charset=utf-8',
      success: result => {
        console.log('getFamilyMemberForPatient success: (result)', result);

        if (result.ok) {
          if (result.FamilyMembers && result.FamilyMembers.length != 0) {
            let familyMembersString = '';
            result.FamilyMembers.forEach(element => {
              if (element.FirstName == '' && element.LastName == '') {
                familyMembersString += 'Pending Family Member\n';
              } else {
                familyMembersString += element.FirstName + ' ' + element.LastName + '\n';
              }
            });
            this.setState({
              modalBtnStyle: 'nucleus-submit-btn',
              modalBtnsStyle: 'nucleus-submit-btn',
              loadingRequests: false,
              existingFamilyMembers: familyMembersString,
            });
            $('#modalMoveFamilyMembers').modal('open');
          } else {
            this.onMoveFamilyMembers(false);
          }
        } else {
          Message.show('Error getting ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' information');
        }
      },
      error: result => {
        console.log('getFamilyMemberForPatient error: (result)', result);
        Message.show('Error getting ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' information');
      },
    });
  }

  closeSecondModal() {
    $('#modalMoveFamilyMembers').modal('close');
    this.setState({
      modalBtnStyle: 'nucleus-submit-btn',
      modalBtnsStyle: 'nucleus-submit-btn',
      loadingRequests: false,
      existingFamilyMembers: '',
    });
  }

  CleanAndMoveFamily() {
    console.log('CleanAndMoveFamily');
    console.log('Current', this.props.patientID);
    console.log('New', this.state.selectedPatientsIDs);

    this.onMoveFamilyMembers(true);
  }

  KeepAndMoveFamily() {
    console.log('KeepAndMoveFamily');
    console.log('Current', this.props.patientID);
    console.log('New', this.state.selectedPatientsIDs);

    this.onMoveFamilyMembers(false);
  }

  onMoveFamilyMembers(resetFamilyMembers) {
    console.log('onMoveFamilyMembers', resetFamilyMembers);

    const headers = Object.assign(
      {},
      {
        'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
        'X-EntityType': 'Control',
        'X-Secret': ControlSecret,
      },
    );

    this.setState({
      modalBtnStyle: 'nucleus-submit-btn-disabled',
      modalBtnsStyle: 'nucleus-submit-btn-disabled',
      loadingRequests: true,
    });

    $.ajax({
      url: Config.domain + 'FamilyMember.asmx/MoveFromPatientToPatient',
      type: 'POST',
      data: {
        CurrentPatientID: this.props.patientID,
        NewPatientID: this.state.selectedPatientsIDs,
        OverwriteData: resetFamilyMembers,
      },
      dataType: 'json',
      headers,
      //contentType: 'application/json; charset=utf-8',
      success: result => {
        console.log('getFamilyMemberForPatient success: (result)', result);

        if (result.ok) {
          this.setState({
            modalBtnStyle: 'nucleus-submit-btn',
            modalBtnsStyle: 'nucleus-submit-btn',
            loadingRequests: false,
          });
          $('#modalMoveFamilyMembers').modal('close');
          Message.show('Family Members moved to ' + this.state.selectedPatientName);
          this.props.closeModal();
          this.props.refresh();
        } else {
          Message.show('Error moving Family Members');
        }
      },
      error: result => {
        console.log('getFamilyMemberForPatient error: (result)', result);
        Message.show('Error moving Family Members');
      },
    });
  }

  render() {
    return (
      <div className="">
        <div id="modalMoveFamily" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.props.closeModal} />
            <h3 className="nucleus-page-subtitle">Move All Family Members to {AuthStore.getPatientLabel()}</h3>
            <br />
            <br />
            <div class={' '}>
              <div className="row bottom-separated">
                <span className="nucleus-labels">{' Family Members being move from ' + this.props.patientName}</span>
              </div>
              <div className="row bottom-separated">
                <span className="nucleus-labels">{' Family Members being move to: '}</span>
              </div>
              <div className="row bottom-separated">
                <span className="nucleus-labels"> {'Search for ' + AuthStore.getPatientLabel()} </span>
              </div>

              <span className="nucleus-font nucleus-font-14">
                Start typing the Patient’s name and we will display all options. Simply select from the list and you will see the Patient's name show in the section below.
              </span>
              <p></p>

              <div ref="txtFamilyPatients" id="txtFamilyPatients" className="chips chips-initial no-margin nucleus-chips" disabled={this.state.selectPatientsEnabled}></div>

              <span class={'nucleus-form-error center-align ' + this.state.selectPatientsError}>Please select only one {AuthStore.getPatientLabel()}.</span>
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a class={'txt-white nucleus-font-modal-btn ' + this.state.modalBtnStyle} onClick={this.onSubmit.bind(this)}>
                    Submit
                  </a>
                  {this.state.loadingRequests && (
                    <div class={'row center-align '} style={{ marginTop: 25 }}>
                      <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingRequests} style={{ display: 'inline-flex' }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalMoveFamilyMembers" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.closeSecondModal} />
            <h3 className="nucleus-page-subtitle">{' There are existing family members for ' + this.state.selectedPatientName}</h3>
            <br />
            <br />
            <div class={' '}>
              <span className="nucleus-font nucleus-font-14">
                You can keep the existing family members and still add the new family members or you can remove the existing ones and move the new ones.
              </span>
              <br />
              <br />
              <span className="nucleus-font nucleus-font-14">Existing Family Members:</span>
              <div className="row bottom-separated">
                <div className="input-field" key={this.state.existingFamilyMembers}>
                  <textarea
                    ref="textExistingFamilyMembers"
                    className="nucleus-textarea-big nucleus-font-medium"
                    disabled={'disabled'}
                    //onChange={this.validateEditNoteText}
                    defaultValue={this.state.existingFamilyMembers}
                  ></textarea>
                  <br />
                </div>
              </div>
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a class={'txt-white nucleus-font-modal-btn ' + this.state.modalBtnsStyle} onClick={this.CleanAndMoveFamily.bind(this)}>
                    Delete
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a class={'txt-white nucleus-font-modal-btn ' + this.state.modalBtnsStyle} onClick={this.KeepAndMoveFamily.bind(this)}>
                    Keep
                  </a>
                  {this.state.loadingRequests && (
                    <div class={'row center-align '} style={{ marginTop: 25 }}>
                      <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingRequests} style={{ display: 'inline-flex' }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientProfileMoveFamilyModal;
