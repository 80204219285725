import backendClient from 'utils/BackendClient';
import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class PicturesApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<---------------------------- Get Pictures by Account ------------------------->
  GetPicturesByAccount(data, callback) {
    backendClient.get(`/accounts/${data.AccountID}/memorybox?page=${data.page}&limit=${data.limit}`, callback);
  }

  GetPicturePatients(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/GetPatientsForPicture',
      data: data,
      callback: callback,
    });
  }

  AssignPicturePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/AttachPictureToPatient',
      data: data,
      callback: callback,
    });
  }

  UnAssignPicturePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/RemovePictureForPatient',
      data: data,
      callback: callback,
    });
  }

  SetPictureForAllPatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UpdateMediaForAllPatients',
      data: data,
      callback: callback,
    });
  }

  UpdateImagePatients(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UpdatePictureForPatients',
      data: data,
      callback: callback,
    });
  }

  GetAutocompletePatientsList({ UserID }: { UserID: string }, callback) {
    backendClient.get(`/user/${UserID}/patients_autocomplete_list`, callback);
  }

  UploadMedia(data, callback) {
    //try{
    NucleusNetwork.requestWithFile({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UploadMedia',
      data: data,
      callback: callback,
    });
    // } catch(ex){
    // 	callback({ok:false, error : ex});
    // }
  }

  //<----------------------------------Update Media Caption--------------------------->
  UpdateMediaCaption(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UpdateMediaCaption',
      data: data,
      callback: callback,
    });
  }

  DeleteMedia(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/DeleteMedia',
      data: data,
      callback: callback,
    });
  }
}

export default PicturesApi;
