//@ts-nocheck
import React from 'react';

import MessagesStore from '../../stores/MessagesStore';

//import ReactTooltip from 'react-tooltip';

class MessageItemRow2 extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     RequestID : this.props.RequestID,
    //     patientID : this.props.PatientID,
    //     patientName : '',
    //     patientTelephone: '',
    //     patientAddress: '',
    //     cancelIconVisibility : ' nucleus-link'
    //     //patientName:this.props.FirstName + ' ' + this.props.LastName
    // };

    this.handleEditMessage = this.handleEditMessage.bind(this);
    this.handleDeleteMessage = this.handleDeleteMessage.bind(this);
    this.handleGetMessageResponses = this.handleGetMessageResponses.bind(this);
  }

  componentDidMount() {
    //Register Listener
    // console.log("QUEUE ITEM ROW: ", this.state.patientID);
    // let patientInfo = CarePatientStore.getPatientInfo(this.props.PatientID);
    // //console.log("ITEM QUEUE: ", patientInfo);
    // if (patientInfo){
    //     this.setState({
    //         patientName : patientInfo.Name,
    //         patientTelephone: patientInfo.Telephone,
    //         patientAddress: patientInfo.Address
    //     });
    // }
    //console.log(this.state.patientID);
  }

  componentWillUnmount() {
    //Remove Listener
  }

  handleEditMessage(e) {
    e.stopPropagation();
    MessagesStore.getMessageDetails({
      MessageID: this.props.messageID,
    });
  }

  handleDeleteMessage(e) {
    e.stopPropagation();
    MessagesStore.getMessageDetailsForDelete({
      MessageID: this.props.messageID,
    });
  }

  handleGetMessageResponses() {
    console.log('handleGetMessageResponses');

    let toDate = new Date();
    let fromDate = new Date();

    try {
      fromDate = new Date(MessagesStore.getMessageFilterFrom());
      toDate = new Date(MessagesStore.getMessageFilterTo());
    } catch (error) {
      console.warn('Error handleGetMessageResponses:', error);

      toDate = new Date();
      fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 1, 0, 0, 0);
      toDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 0);

      // let fromDate = new Date(this.refs.messageReportFromDateTime.value);
      // let toDate = new Date(this.refs.messageReportToDateTime.value);

      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(9);

      toDate.setHours(23);
      toDate.setMinutes(59);
      toDate.setSeconds(0);

      //fromDate.setHours(fromDate.getHours() + (this.props.userOffset * -1));
      //toDate.setHours(toDate.getHours() + (this.props.userOffset * -1));

      //console.log("Sending ", this.props.userOffset);
    }

    console.log('1', fromDate);
    console.log('2', toDate);

    MessagesStore.getMessageResponses({
      MessageID: this.props.messageID,
      MessageBody: this.props.messageText,
      FromDateTime: fromDate.toLocaleDateString('en-US') + ' ' + fromDate.toLocaleTimeString('en-US'),
      ToDateTime: toDate.toLocaleDateString('en-US') + ' ' + toDate.toLocaleTimeString('en-US'),
    });
  }

  render() {
    //let requestDateString = new Date(this.props.RequestTime).toLocaleDateString() + " " + new Date(this.props.RequestTime).toLocaleTimeString();

    //let messageLastSent = new Date(this.props.messageCreated).toLocaleDateString();
    let messageLastSent = '';
    const lastSentDate = new Date(this.props.messageLastSent);
    if (lastSentDate.getFullYear() != 2099) {
      //lastSentDate.setHours(lastSentDate.getHours() + this.props.userOffset);

      ////Deprecated: Oct, 14th 2019
      ////messageLastSent = lastSentDate.toLocaleDateString() + " " + lastSentDate.toLocaleTimeString().replace(/:\d{2}\s/,' ') + " " + this.props.messageLSTimezone;
      messageLastSent = this.props.messageLastSentString;
    }
    //let today = new Date();
    let messageNextDelivery = '';
    const nextDeliveryDate = new Date(this.props.messageNextDelivery);
    if (nextDeliveryDate.getFullYear() != 2099) {
      //nextDeliveryDate.setHours(nextDeliveryDate.getHours() + this.props.userOffset);

      ////Deprecated: Oct, 14th 2019
      ////messageNextDelivery = nextDeliveryDate.toLocaleDateString() + " " + nextDeliveryDate.toLocaleTimeString().replace(/:\d{2}\s/,' ') + " " + this.props.messageNDTimezone;
      messageNextDelivery = this.props.messageNextDeliveryString;

      // if (messageLastSent == ""){
      //     messageLastSent = messageNextDelivery;
      //     messageNextDelivery = "";
      // }
    }

    let shortMessage = this.props.messageText;
    if (shortMessage != null && shortMessage.length > 37) {
      shortMessage = shortMessage.substring(0, 37) + '...';
    }

    let shortSender = this.props.messageSender;
    if (shortSender != null && shortSender.length > 17) {
      shortSender = shortSender.substring(0, 17) + '...';
    }
    /*
        let isRecurringActive = "No";
        if (this.props.messageIsRecurring && !this.props.messageIsRecurringStopped ){
            if (this.props.messageEndDate == ""){
                isRecurringActive = "Yes";
            } else {
                let endRepeatDate = new Date(this.props.messageEndDate);
                //console.log("TODAY ", this.props.messageToday);
                //console.log("END REPEAT ", endRepeatDate);
                if (this.props.messageToday <= endRepeatDate){
                    isRecurringActive = "Yes";
                }
            }
        }
        */

    return (
      <tr onClick={this.handleGetMessageResponses}>
        <td className="nucleus-row" >
          {' '}
          <span>{this.props.messageReccuring}</span>
        </td>
        <td className="nucleus-row" >
          {' '}
          <span>{messageLastSent}</span>
        </td>
        <td className="nucleus-row" >
          {' '}
          <span>{messageNextDelivery}</span>
        </td>
        <td className="nucleus-row" >
          <span>{shortSender}</span>
        </td>
        <td className="center-align nucleus-row" >
          <span>{this.props.messageRecipients}</span>
        </td>
        <td className="nucleus-row">
          {/*data-tip={this.props.messageText}*/}
          <span>{shortMessage}</span>
        </td>

        <td className="icons-line">
          <span class={'nucleus-table-icon nucleus-link '} onClick={this.handleEditMessage}>
            <i className="Tiny material-icons">edit</i>
          </span>
          &nbsp;&nbsp;&nbsp;
          <span class={'nucleus-table-icon nucleus-link '} onClick={this.handleDeleteMessage}>
            {/* <i className="Tiny material-icons">not_interested</i> */}
            <i className="Tiny material-icons">delete</i>
          </span>
          &nbsp;&nbsp;&nbsp;
          <span className="nucleus-table-icon nucleus-link " onClick={this.handleGetMessageResponses}>
            <i className="Tiny material-icons">keyboard_arrow_right</i>
          </span>
        </td>
      </tr>
    );
  }
}

export default MessageItemRow2;
