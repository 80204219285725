import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import backendClient from 'utils/BackendClient';
import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class MessagesApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<-------------------------Get Messages for User--------------------------->
  GetMessagesForUser(data, callback) {
    backendClient.get(`/users/${data.UserID}/messages?FromDateTime=${data.FromDateTime}&ToDateTime=${data.ToDateTime}`, callback);
  }

  GetMessagesForAccount(data, callback) {
    backendClient.get(`/accounts/${data.AccountID}/messages?FromDateTime=${data.FromDateTime}&ToDateTime=${data.ToDateTime}`, callback);
  }

  //<-------------------------Get Message Categories--------------------------->
  GetMessageCategories(data, callback) {
    backendClient.get('/message-categories', callback);
  }

  GetMessageTemplates(data, callback) {
    backendClient.get('/message-option-templates', callback);
  }

  GetBiometricsMessagesTemplates({ AccountID }: { AccountID: string }, callback) {
    backendClient.get(`/accounts/${AccountID}/biometric-message-types`, callback);
  }

  //<-------------------------Get Message Categories--------------------------->
  GetAutocompletePatientsList({ UserID }: { UserID: string }, callback) {
    backendClient.get(`/user/${UserID}/patients_autocomplete_list`, callback);
  }

  SaveScheduledMessageByUser(data, callback) {
    console.log('SaveScheduledMessageByUser', data);

    // NucleusNetwork.request({
    // 	context : this.context,
    // 	type : "POST",
    // 	url: Config.domain + "CareMessage.asmx/AddScheduledMessageByUser",
    // 	data : data,
    // 	callback : callback,
    // });
    let rsp;
    httpNodeApi
      .post('messages/new', data)
      .then(response => {
        console.log('messages/new response', response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on messages/new' };
        console.log('Error messages/new error', e);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('messages/new finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  UploadSignatureMessagePdfFile(data, callback) {
    const body = new FormData();
    body.append('MessageID', data.MessageID);
    body.append('PdfFileName', data.PdfFileName);
    body.append('File', data.File);

    NucleusNetwork.requestWithFile({
      context: this.context,
      type: 'POST',
      url: Config.domain + '/CareMessage.asmx/UploadSignatureMessagePdfFile',
      data: body,
      callback: callback,
    });
  }

  DeleteSignatureMessagePdfFile(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/DeleteSignatureMessagePdfFile',
      data: data,
      callback: callback,
    });
  }

  GetMessageDetailsForDelete(data, callback) {
    backendClient.get(`/messages/${data.MessageID}/data?forDelete=true`, callback);
  }

  GetPatientMessageDetails(data, callback) {
    backendClient.get(`patients/${data.PatientID}/messages/${data.PatientMessageID}/details`, callback);
  }

  GetMessageReport(data, callback) {
    backendClient.get(`messages/${data.MessageID}/delivery-report?fromDateTime=${data.FromDateTime}&toDateTime=${data.ToDateTime}`, callback);
  }

  UpdateScheduledMessage(data, callback) {
    backendClient.put(`messages/${data.MessageID}/update`, data, callback);
  }

  StopRecurringMessage(data, callback) {
    backendClient.put(`messages/${data.MessageID}/stop-recurring`, data, callback);
  }

  DeleteMessage(data, callback) {
    backendClient.delete(`messages/${data.MessageID}`, callback);
  }

  DeletePatientMessage(data, callback) {
    backendClient.delete(`messages/patient-messages/${data.PatientMessageID}`, callback);
  }
}

export default MessagesApi;
